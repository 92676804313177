import React, { useState } from 'react';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { Box, Button, LinearProgress, Typography } from '@mui/material';
import axios from 'axios';
import CancelIcon from '@mui/icons-material/Cancel';
import { getToken, useHandleToken } from '../../../Helper/GetToken';
import { fireStorage } from '../../../config/firebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	maxHeight: '90vh',
	overflowY: 'auto',
	bgcolor: 'background.paper',
	border: '2px solid #0000001f',
	borderRadius: '8px',
	boxShadow: 24,
	p: 4,
	color: 'black'
};

export interface CompanyType {
	id?: number;
	name?: string;
	logoUrl?: string;
	contact?: {
		id?: number;
		email?: string;
		first_name?: string;
		last_name?: string;
	};
}

interface EditCompanyProps {
	setEditOpen: (editOpen: boolean) => void;
	setIsRefresh: (isRefresh: boolean) => void;
	selectedCompany: CompanyType | undefined;
	isRefresh: boolean;
}
type AddCompanyState = {
	name: string;
	contactEmail: string;
	contactFirstName: string;
	contactLastName: string;
	logoUrl: string;
};
export const EditCompany: React.FC<EditCompanyProps> = ({ setEditOpen, setIsRefresh, selectedCompany, isRefresh }) => {
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const [isDisabled, setIsDisabled] = useState(false);
	const [editcompany, setEditcompany] = useState<AddCompanyState>({
		name: selectedCompany?.name || '',
		contactEmail: selectedCompany?.contact?.email || '',
		contactFirstName: selectedCompany?.contact?.first_name || '',
		contactLastName: selectedCompany?.contact?.last_name || '',
		logoUrl: selectedCompany?.logoUrl || ''
	});
	const [logo, setLogo] = useState<File | string | null>(selectedCompany?.logoUrl || null);
	const [loadingForLogo, setLoadingForLogo] = useState(false);
	const { unhandleToken } = useHandleToken();

	const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEditcompany({ ...editcompany, [e.target.name]: e.target.value });
	};

	const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files && e.target.files[0];
		if (file) {
			const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml', 'image/gif'];
			if (!validTypes.includes(file.type)) {
				return;
			}

			const maxSize = 5 * 1024 * 1024;
			if (file.size > maxSize) {
				return;
			}

			setLogo(file);
		}
	};

	const handleVideoDelete = async () => {
		setLogo(null);
	};

	const submitHandler = async (e: { preventDefault: () => void }) => {
		e.preventDefault();
		setIsDisabled(true);

		let logoUrl: string | null = null;

		if (typeof logo === 'string') {
			logoUrl = logo;
		} else if (logo && typeof logo !== 'string') {
			setLoadingForLogo(true);
			const fileName = `${new Date().getTime()}-${selectedCompany?.name}-logo`;
			const storageRef = await ref(fireStorage, `company_logos/${fileName}`);
			const snapshot = await uploadBytes(storageRef, logo);
			const downloadURL = await getDownloadURL(snapshot.ref);
			logoUrl = downloadURL;
			setLoadingForLogo(false);
		}

		try {
			await axios
				.put(
					`${baseUrl}/company/${selectedCompany?.id}`,
					{ ...editcompany, logoUrl },
					{
						headers: {
							Authorization: token
						}
					}
				)
				.then(res => {
					setEditOpen(false);
					setIsRefresh(!isRefresh);
				})
				.catch(e => {
					console.log(e);
					setEditOpen(false);
					unhandleToken(e);
				});
		} catch (error) {
			console.log(error);
		} finally {
			setIsDisabled(false);
		}
	};
	return (
		<>
			<Box sx={style}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
					<Typography variant="h6">Update Company</Typography>
					<Button sx={{ minWidth: 'auto', padding: 0 }} onClick={() => setEditOpen(false)}>
						<HighlightOffOutlinedIcon />
					</Button>
				</Box>
				<Box>
					<div className="formContainer">
						<form onSubmit={submitHandler}>
							<div className="formBox">
								<label htmlFor="companyName">Company Name</label>
								<input
									type="text"
									name="name"
									id="companyName"
									aria-label="name"
									onChange={inputHandler}
									maxLength={120}
									value={editcompany.name}
									required
									pattern="^[a-zA-Z1-9].*"
									placeholder="Company Name"
								/>
							</div>
							<div className="formBox">
								<label htmlFor="contactFirstName">Contact First Name</label>
								<input
									type="text"
									name="contactFirstName"
									id="contactFirstName"
									value={editcompany.contactFirstName}
									aria-label="contactFirstName"
									onChange={inputHandler}
									maxLength={120}
									pattern="^[a-zA-Z1-9].*"
									required
									placeholder="Contact Person First Name"
								/>
							</div>
							<div className="formBox">
								<label htmlFor="contactLastName">Contact Last Name</label>
								<input
									type="text"
									name="contactLastName"
									id="contactLastName"
									aria-label="contactLastName"
									onChange={inputHandler}
									value={editcompany.contactLastName}
									maxLength={120}
									pattern="^[a-zA-Z1-9].*"
									required
									placeholder="Contact Person Last Name"
								/>
							</div>
							<div className="formBox">
								<label htmlFor="companyemail">Company Email</label>
								<input
									type="email"
									name="contactEmail"
									id="companyemail"
									value={editcompany.contactEmail}
									aria-label="companyemail"
									onChange={inputHandler}
									maxLength={120}
									required
									pattern="[\-a-zA-Z0-9~!$%^&amp;*_=+\}\{'?]+(\.[\-a-zA-Z0-9~!$%^&amp;*_=+\}\{'?]+)*@[a-zA-Z0-9_][\-a-zA-Z0-9_]*(\.[\-a-zA-Z0-9_]+)*\.[cC][oO][mM](:[0-9]{1,5})?"
									placeholder="Contact Person Email"
								/>
							</div>
							<div className="formBox">
								<label style={{ marginBottom: '6px' }}>Company Logo</label>
								{loadingForLogo ? (
									<LinearProgress />
								) : logo ? (
									<Box display={'flex'} width={'100%'} gap={1}>
										<img
											src={typeof logo === 'object' ? URL.createObjectURL(logo) : logo}
											alt="Uploaded Logo"
											style={{ maxWidth: '90%', height: 'auto', border: '1px solid #ccc', borderRadius: '4px' }}
										/>
										<CancelIcon onClick={handleVideoDelete} sx={{ color: 'red', cursor: 'pointer' }} />
									</Box>
								) : (
									<input
										aria-label="uploadFile"
										accept=".jpeg, .jpg, .png, .svg, .gif"
										id="contained-button-file"
										type="file"
										onChange={handleFileChange}
									/>
								)}
							</div>
							<Box>
								<Button disabled={isDisabled} type="submit">
									Update
								</Button>
							</Box>
						</form>
					</div>
				</Box>
			</Box>
		</>
	);
};
