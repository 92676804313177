import React, { useContext, useEffect, useState } from 'react';
import UploadUI from './UploadInfo';
import Summary from './Summary';
import { useLocation, useNavigate } from 'react-router-dom';
import UploadLanding from './Landing';
import axiosInstance from '../../Helper/axiosInstance';
import { ChatContext } from '../../Contexts';
import { Alert, Snackbar } from '@mui/material';
import { extractAudioFromVideo } from '../../Helper/AudioExtractor';

const AnalyzeMeetings = () => {
	const [file, setFile] = useState<File | null>(null);
	const [step, setStep] = useState('landing');
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const tab = searchParams.get('tab');
	const meetingId = searchParams.get('meeting');
	const [loading, setLoading] = useState(false);
	const [categoryConv, setCategoryConv] = useState([]);
	const [summaryResponse, setSummaryResponse] = useState<any>();

	const { meetings, setMeetings } = useContext(ChatContext);

	const [openAlert, setOpenAlert] = React.useState<
		{ open: boolean; type?: 'error' | 'success' | 'warning' | undefined; message?: string } | undefined
	>();

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenAlert({ open: false, message: '', type: openAlert?.type });
	};

	useEffect(() => {
		if (meetingId && meetings?.length && meetings?.find(item => item?.id === meetingId)) {
			setSummaryResponse(meetings?.find(item => item?.id === meetingId));
			navigate(`/analyze-meetings?tab=summary&meeting=${meetingId}`);
		}
	}, [meetingId, meetings]);

	useEffect(() => {
		if (tab) {
			setStep(tab);
		} else {
			setStep('landing');
		}
	}, [tab]);
	useEffect(() => {
		getScenarios();
	}, []);

	const sendMediaToServer = async (mediaBlob: File) => {
		const formData = new FormData();
		formData.append('audio', mediaBlob, 'audio.wav');
		try {
			const { data } = await axiosInstance.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/speechToText`, formData);

			if (data) {
				return data;
			}

			return false;
		} catch (error) {
			console.error('Error uploading file:', error);
		}
	};

	const getSummaryFromText = async (transcription: string) => {
		try {
			const { data } = await axiosInstance.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/openai/summaryFromText`, {
				transcription
			});
			if (data?.success) {
				return data;
			}

			return false;
		} catch (error) {
			console.error('Error uploading file:', error);
		}
	};

	const handleUploadStart = async () => {
		let transcription = '';

		if (file) {
			setLoading(true);

			try {
				if (file.type.includes('audio') || file.type.includes('video')) {
					let mediaFile: File | null = file;

					// Extract audio if it's a video
					if (file.type.includes('video')) {
						mediaFile = await extractAudioFromVideo(file);
						if (!mediaFile) {
							throw new Error('Failed to extract audio from video');
						}
					}

					// Check file size limit
					if (mediaFile.size > 24 * 1024 * 1024) {
						setOpenAlert({
							open: true,
							type: 'error',
							message: 'The media file exceeds the 24MB limit after compress. Please upload a smaller file.'
						});
						return;
					}

					// Send the audio file to the backend
					const data = await sendMediaToServer(mediaFile);
					if (!data) {
						throw new Error('Error while converting text from media');
					}
					transcription = data.transcription;
				} else {
					transcription = await file.text();
				}

				// Process transcription to get a summary
				const response = await getSummaryFromText(transcription);
				if (response?.data) {
					setSummaryResponse(response.data);
					setMeetings([response.data, ...meetings]);
					navigate(`/analyze-meetings?tab=summary&meeting=${response.data.id}`);
					setStep('summary');
					setFile(null);
				} else {
					throw new Error('Error while generating summary');
				}
			} catch (error: any) {
				console.log(error);
				setOpenAlert({
					open: true,
					type: 'error',
					message: error.message || 'An unexpected error occurred'
				});
			} finally {
				setLoading(false);
			}
		}
	};

	const getScenarios = async () => {
		await axiosInstance
			.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/scenario/userscenerio`, {})
			.then(res => {
				if (res.data) {
					setCategoryConv(res.data);
				}
			})
			.catch(e => {
				console.log(e);
			});
	};

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={openAlert?.open}
				autoHideDuration={6000}
				onClose={handleClose}
			>
				<Alert onClose={handleClose} severity={openAlert?.type} variant="filled" sx={{ width: '100%' }}>
					{openAlert?.message}
				</Alert>
			</Snackbar>
			<div>
				{step === 'landing' && <UploadLanding />}

				{step === 'upload' && (
					<UploadUI
						setFile={setFile}
						file={file}
						handleUploadStart={handleUploadStart}
						setStep={setStep}
						loading={loading}
					/>
				)}

				{step === 'summary' && (
					<Summary
						summaryResponse={summaryResponse}
						setSummaryResponse={setSummaryResponse}
						setStep={setStep}
						categoryConv={categoryConv}
					/>
				)}
			</div>
		</>
	);
};

export default AnalyzeMeetings;
