import React from 'react';
import { Box, Typography } from '@mui/material';
import icon from '../../Assets/Images/profilePictures/meetAnalyse.svg';
interface Transcript {
	name: string;
	message: string;
}

interface ChatBoxProps {
	transcriptData: Transcript[];
}

const TranscriptChat: React.FC<ChatBoxProps> = ({ transcriptData }) => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '16px',
				padding: '16px',
				width: 'auto',
				margin: '0 auto'
			}}
		>
			{Array.isArray(transcriptData) &&
				transcriptData.map((items, index) => {
					const firstSpeaker = transcriptData[0]?.name;
					const alignment = items.name === firstSpeaker ? 'left' : 'right';

					return (
						<Box
							key={index}
							sx={{
								display: 'flex',
								justifyContent: alignment === 'left' ? 'flex-start' : 'flex-end'
							}}
						>
							<Box
								sx={{
									maxWidth: '70%',
									display: 'flex',
									flexDirection: 'column'
								}}
							>
								{/* Participant Name */}
								<Box
									sx={{
										display: 'flex',
										gap: '5px',
										verticalAlign: 'center',
										marginBottom: '5px',
										flexDirection: alignment === 'left' ? '' : 'row-reverse'
									}}
								>
									<img src={icon} alt="" />
									<Typography
										variant="subtitle2"
										sx={{
											fontWeight: '400',
											// marginBottom: '4px',
											textAlign: alignment === 'left' ? 'left' : 'right',
											fontFamily: 'Outfit',
											fontSize: '16px'
										}}
									>
										{items.name}
									</Typography>
								</Box>
								{/* Message Box */}
								<Box
									sx={{
										padding: '16px',
										borderRadius: '8px',
										fontWeight: '500',
										color: '#1a1a1a',
										border: '1px solid rgba(217, 226, 233, 1)',
										backgroundColor: 'transparent',
										textAlign: 'left'
									}}
								>
									<Typography variant="body2" sx={{ fontFamily: 'Outfit', fontSize: '16px' }}>
										{items.message}
									</Typography>
								</Box>
							</Box>
						</Box>
					);
				})}
		</Box>
	);
};

export default TranscriptChat;
