import React, { useEffect, useRef, useState } from 'react';
import { getToken, useHandleToken } from '../../../Helper/GetToken';
import axios from 'axios';
import {
	Box,
	Button,
	CircularProgress,
	Modal,
	SvgIcon,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CreateCategory from '../Simulation/CreateCategory';
import EditCategory from '../Simulation/EditCategory';
import { getIcon } from '../../../utils/svgIcons';
interface MemberProps {
	imageData: ImageData[];
	parent_id: number;
	setShowMember: (showMember: boolean) => void;
	avatars: Avatar[];
}

interface ImageData {
	id: string;
	name: string;
	usage: string;
	title: string;
}

interface Avatar {
	avatar_id: string;
	avatar_name: string;
	gender: string;
	preview_image_url: string;
	preview_video_url: string;
}

export type CategoryType = {
	scenario_name: string;
	level: number | null;
	parent_id: any;
	category: string;
	picture: { id: string; name: string; title: string };
	description: string;
	scenario_id: number;
	role_name: string;
	video_url?: any;
	enable_voice_mode?: boolean;
	voice_modal?: any;
	badge?: { id: string; name: string; title: string };
	avatar_id?: string;
	summary: string | null;
	enable_avatar_mode?: boolean;
};

const MemberTable: React.FC<MemberProps> = ({ imageData, parent_id, setShowMember, avatars }) => {
	const [tableData, setTableData] = useState([]);
	const [categoryData, setCategoryData] = useState<CategoryType>({
		scenario_name: '',
		level: null,
		parent_id: parent_id,
		category: '',
		picture: { id: '', name: '', title: '' },
		description: '',
		scenario_id: 0,
		role_name: '',
		video_url: null,
		voice_modal: null,
		badge: { id: '', name: '', title: '' },
		avatar_id: '',
		summary: ''
	});
	const [open, setOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);
	const [isRefresh, setIsRefresh] = useState(true);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(false);
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const { unhandleToken } = useHandleToken();
	const [search, setSearch] = useState('');
	const controller = useRef<AbortController | null>(null);
	useEffect(() => {
		if (isRefresh || limit || page) {
			getScenarios();
		}
	}, [isRefresh, limit, page]);
	const getScenarios = async (search?: string) => {
		if (controller.current) {
			controller.current.abort();
		}

		// Create a new AbortController instance
		controller.current = new AbortController();
		const { signal } = controller.current;
		setLoading(true);
		await axios
			.get(`${baseUrl}/api/scenario/scenarios`, {
				headers: {
					Authorization: token
				},
				params: { id: parent_id, page, limit, pagination: true, search },
				signal
			})
			.then(res => {
				if (res.data) {
					setTableData(res.data.scenarios);
					setIsRefresh(false);
					setTotal(res.data.pagination.total);
				}
			})
			.catch(e => {
				console.log(e);
				unhandleToken(e);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	const deleteHandler = async (id: number) => {
		await axios
			.delete(`${baseUrl}/api/scenario/deleteScenario/${id}`, {
				headers: {
					Authorization: token
				}
			})
			.then(() => {
				setIsRefresh(!isRefresh);
			})
			.catch(e => {
				console.log(e);
				unhandleToken(e);
			});
	};
	const editHandler = (data: CategoryType) => {
		setCategoryData({
			scenario_name: data?.scenario_name || '',
			parent_id: data?.parent_id ?? null,
			category: data?.category || '',
			picture: data?.picture || { id: '', name: '', data: '' },
			description: data?.description || '',
			scenario_id: data?.scenario_id || 0,
			role_name: data?.role_name || '',
			video_url: data?.video_url ?? null,
			enable_voice_mode: data?.enable_voice_mode ?? false,
			voice_modal: data?.voice_modal ?? null,
			badge: data?.badge || { id: '', name: '', title: '' },
			avatar_id: data?.avatar_id || '',
			summary: data?.summary || null,
			enable_avatar_mode: data?.enable_avatar_mode ?? false,
			level: data?.level || null
		});
		setEditOpen(true);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage + 1);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLimit(parseInt(event.target.value, 10));
		setPage(1);
	};
	const inputHandler = (e: { target: { value: string } }) => {
		setSearch(e.target.value);
		if (page !== 1) {
			setPage(1);
		}
		getScenarios(e.target.value);
	};
	return (
		<>
			<Box sx={{ padding: '0 10px' }}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
					<Button startIcon={<ArrowBackIosIcon />} onClick={() => setShowMember(false)} color="secondary">
						Back
					</Button>
					<Box>
						<Typography variant="h6">Simulation Person</Typography>
					</Box>
					<Button startIcon={<AddIcon />} onClick={() => setOpen(!open)}>
						Add
					</Button>
				</Box>
				<Box>
					<Box
						display="flex"
						alignItems="center"
						gap={2}
						sx={{
							backgroundColor: 'background.paper',
							padding: 2,
							borderRadius: 1,
							boxShadow: 1,
							flexWrap: 'wrap' // Ensures responsiveness on smaller screens
						}}
					>
						<TextField
							name="search"
							placeholder="Search"
							value={search}
							onChange={inputHandler}
							size="small"
							variant="outlined"
							sx={{
								flex: '1 1 auto', // Allows flexibility
								maxWidth: '300px', // Reduced width
								width: '100%', // Fills available space
								borderRadius: '8px'
							}}
						/>
					</Box>
					{loading ? (
						<CircularProgress />
					) : (
						<TableContainer sx={{ borderRadius: '8px' }}>
							<Table>
								<TableHead
									sx={{
										background: '#CFE9FD',
										border: '1px solid rgba(224, 224, 224, 1)'
									}}
								>
									<TableRow sx={{ textWrap: 'nowrap' }}>
										<TableCell align="left">Scenario Id</TableCell>
										<TableCell align="left">Scenario Name</TableCell>
										<TableCell align="left">Category</TableCell>
										<TableCell align="left">Summary</TableCell>
										<TableCell align="left">Description</TableCell>
										<TableCell align="left">Role</TableCell>
										<TableCell align="left">Profile</TableCell>
										<TableCell align="left">Edit</TableCell>
										<TableCell align="left">Delete</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{Array.isArray(tableData) &&
										tableData.length > 0 &&
										tableData.map((items: CategoryType, index: number) => {
											return (
												<>
													<TableRow sx={{ border: '1px solid rgba(224, 224, 224, 1)' }} key={index}>
														<TableCell align="left">{items.scenario_id}</TableCell>
														<TableCell align="left">{items.scenario_name}</TableCell>
														<TableCell align="left">{items.category}</TableCell>
														<TableCell align="left">{items.summary}</TableCell>
														<TableCell align="left">{items.description}</TableCell>
														<TableCell align="left">{items?.role_name || '-'}</TableCell>
														<TableCell align="left">
															{items?.picture.name &&
																(() => {
																	const icon = getIcon(items?.picture.name);
																	return icon ? (
																		<SvgIcon
																			component={icon}
																			viewBox="0 0 384 512"
																			sx={{ fontSize: '2rem', width: '1.1em' }}
																		/>
																	) : null;
																})()}{' '}
														</TableCell>
														<TableCell align="left">
															<Button
																variant="outlined"
																color="secondary"
																size="small"
																startIcon={<EditIcon />}
																onClick={() => editHandler(items)}
															>
																Edit
															</Button>
														</TableCell>
														<TableCell align="left">
															<Button
																variant="outlined"
																size="small"
																startIcon={<DeleteIcon />}
																onClick={() => deleteHandler(items.scenario_id)}
															>
																Delete
															</Button>
														</TableCell>
													</TableRow>
												</>
											);
										})}
								</TableBody>
							</Table>
							<TablePagination
								component="div"
								count={total}
								page={page - 1}
								onPageChange={handleChangePage}
								rowsPerPage={limit}
								onRowsPerPageChange={handleChangeRowsPerPage}
								rowsPerPageOptions={[5, 10, 20]}
							/>
						</TableContainer>
					)}
				</Box>
			</Box>
			<Modal
				open={open}
				// onClose={()=>setOpen(!open)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<CreateCategory
					setOpen={setOpen}
					imageData={imageData}
					setIsRefresh={setIsRefresh}
					parent_id={parent_id}
					avatars={avatars}
				/>
			</Modal>
			<Modal
				open={editOpen}
				// onClose={()=>setOpen(!open)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<EditCategory
					setEditOpen={setEditOpen}
					imageData={imageData}
					setIsRefresh={setIsRefresh}
					categoryData={categoryData}
					avatars={avatars}
				/>
			</Modal>
		</>
	);
};

export default MemberTable;
