import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Snackbar,
	TextField,
	Typography
} from '@mui/material';
import { getToken } from '../../../Helper/GetToken';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '90%',
	bgcolor: 'background.paper',
	border: '2px solid #0000001f',
	borderRadius: '8px',
	boxShadow: 24,
	p: 4,
	color: 'black',
	overflowY: 'auto',
	maxHeight: '95vh',
	minHeight: '95vh'
};

interface SubgroupsProps {
	setSubgroupsOpen: (open: boolean) => void;
	selectedCohort: any;
}

const Subgroups: React.FC<SubgroupsProps> = ({ setSubgroupsOpen, selectedCohort }) => {
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();

	const [open, setOpen] = useState(false);
	const [name, setName] = useState('');
	const [users, setUsers] = useState<any[]>([]);
	const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
	const [groupWithUsers, setGroupWithUsers] = useState<any>({});
	const [selectedGroupForAction, setSelectedGroupForAction] = useState<any>(null);
	const [columns, setColumns] = useState<any>({});

	const [openAlert, setOpenAlert] = React.useState<{
		open: boolean;
		type?: 'success' | 'error' | 'info' | 'warning' | undefined;
		message?: string;
	}>();

	const fetchSubgroups = () => {
		axios
			.get(`${baseUrl}/cohort-subgroup/${selectedCohort?.id}/users`, {
				headers: {
					Authorization: token
				}
			})
			.then(response => {
				const data = response?.data;
				const obj: any = {};
				const assignedUsers: any = [];

				data?.map((item: any) => {
					obj[item.name] = {
						id: item?.id,
						name: item.name,
						items:
							item?.members?.map((item: any) => {
								return { id: item?.id?.toString(), content: `${item?.first_name} ${item?.last_name}` };
							}) || []
					};
					assignedUsers.push(...item.members);
				});

				setSelectedUsers(assignedUsers);
				setGroupWithUsers(obj);
			})
			.catch(error => {
				console.error(error);
			});
	};

	const fetchUsers = () => {
		axios
			.get(`${baseUrl}/api/admin/get-users/cohort/${selectedCohort?.id}`, {
				headers: {
					Authorization: token
				}
			})
			.then(response => {
				setUsers(response.data.data);
			})
			.catch(error => {
				console.error(error);
			});
	};

	useEffect(() => {
		fetchSubgroups();
		fetchUsers();
	}, []);

	const handleAddSubgroup = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setSelectedGroupForAction(null);
		setName('');
		setOpen(false);
	};

	const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenAlert({ open: false, message: '', type: openAlert?.type });
	};

	const handleSave = () => {
		axios
			.post(
				selectedGroupForAction
					? `${baseUrl}/cohort-subgroup/${selectedCohort?.id}/rename-subgroup/${selectedGroupForAction?.id}`
					: `${baseUrl}/cohort-subgroup/${selectedCohort?.id}/create-subgroup`,
				{ name },
				{
					headers: {
						Authorization: token
					}
				}
			)
			.then(response => {
				setName('');
				setSelectedGroupForAction(null);
				setOpenAlert({ open: true, message: 'Subgroup saved successfully', type: 'success' });
				fetchSubgroups();
				setOpen(false);
			})
			.catch(error => {
				console.error(error);
				setOpenAlert({ open: true, message: 'Something went wrong!', type: 'error' });
			});
	};

	const handleDelete = (column: any) => {
		axios
			.delete(`${baseUrl}/cohort-subgroup/${selectedCohort?.id}/delete-subgroup/${column?.id}`, {
				headers: {
					Authorization: token
				}
			})
			.then(response => {
				if (response?.data?.message === 'Subgroup deleted successfully') {
					setOpenAlert({ open: true, message: response?.data?.message, type: 'success' });
				} else {
					setOpenAlert({ open: true, message: response?.data?.message, type: 'error' });
				}

				setName('');
				setSelectedGroupForAction(null);
				fetchSubgroups();
				setOpen(false);
			})
			.catch(error => {
				console.error(error);
				setOpenAlert({ open: true, message: error?.response?.data?.message, type: 'error' });
			});
	};

	const handleAddUsersSave = async (unassigned: any, groupId: any, userId: any) => {
		axios
			.post(
				`${baseUrl}/cohort-subgroup/${selectedCohort?.id}/update-subgroup-users`,
				{ unassigned, subgroupId: groupId, userId, cohortId: selectedCohort?.id },
				{
					headers: {
						Authorization: token
					}
				}
			)
			.then(response => {
				setSelectedUsers([]);
			})
			.catch(error => {
				console.error(error);
			});
	};

	useEffect(() => {
		setColumns({
			'Unassigned Users': {
				name: 'Unassigned Users',
				items:
					users
						?.filter(item => !selectedUsers?.map(item => item?.id)?.includes(item?.id))
						?.map(item => {
							return { id: item?.id?.toString(), content: `${item?.first_name} ${item?.last_name}` };
						}) || []
			},
			...groupWithUsers
		});
	}, [users, groupWithUsers]);

	const [draggingItem, setDraggingItem] = useState<any>(null);

	const handleDragStart = (event: any, item: any, sourceColumn: string) => {
		setDraggingItem({ item, sourceColumn });
	};

	const handleDragOver = (event: any) => {
		event.preventDefault();
	};

	const handleDrop = async (event: any, targetColumn: string, column: any) => {
		if (!draggingItem) return;

		const { item, sourceColumn } = draggingItem;

		if (sourceColumn !== targetColumn) {
			const sourceItems = [...columns[sourceColumn].items];
			const targetItems = [...columns[targetColumn].items];

			const updatedSourceItems = sourceItems.filter(task => task.id !== item.id);

			targetItems.push(item);

			setColumns({
				...columns,
				[sourceColumn]: {
					...columns[sourceColumn],
					items: updatedSourceItems
				},
				[targetColumn]: {
					...columns[targetColumn],
					items: targetItems
				}
			});

			await handleAddUsersSave(targetColumn === 'Unassigned Users', +column?.id, +draggingItem?.item?.id);

			setDraggingItem(null);
		}
	};

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={openAlert?.open}
				autoHideDuration={6000}
				onClose={handleCloseAlert}
			>
				<Alert onClose={handleCloseAlert} severity={openAlert?.type} variant="standard" sx={{ width: '100%' }}>
					{openAlert?.message}
				</Alert>
			</Snackbar>
			<Box sx={style}>
				<Box sx={{ width: '100%' }}>
					<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
						<Typography variant="h6">Sub groups</Typography>
						<Button sx={{ minWidth: 'auto', padding: 0 }} onClick={() => setSubgroupsOpen(false)}>
							<HighlightOffOutlinedIcon />
						</Button>
					</Box>
					<Dialog open={open} onClose={handleClose}>
						<DialogTitle>{selectedGroupForAction ? 'Edit' : 'Add'} Subgroup</DialogTitle>
						<DialogContent>
							<TextField
								sx={{ mt: 2 }}
								label="Name"
								value={name}
								onChange={event => setName(event.target.value)}
								fullWidth
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose}>Cancel</Button>
							<Button onClick={handleSave}>Save</Button>
						</DialogActions>
					</Dialog>
					<div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
						<Button variant="contained" onClick={handleAddSubgroup}>
							Add Subgroup
						</Button>
					</div>
					<div style={{ display: 'flex', justifyContent: 'center', width: '100%', gap: 20, marginTop: '10px' }}>
						<div style={{ height: '100%', width: '30%' }}>
							{Object.entries(columns)
								.filter(([columnId, column]: any) => columnId === 'Unassigned Users')
								.map(([columnId, column]: any, index) => (
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center'
										}}
										key={columnId}
									>
										<p style={{ margin: '10px 0' }}>{column.name}</p>
										<div
											onDragOver={handleDragOver}
											onDrop={event => handleDrop(event, columnId, column)}
											style={{
												border: '2px dashed lightgray',
												padding: 4,
												width: '100%',
												minHeight: 70
											}}
										>
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'start',
													gap: 4,
													alignItems: 'center'
												}}
											>
												{column.items.map((item: any) => (
													<div
														key={item.id}
														draggable
														onDragStart={event => handleDragStart(event, item, columnId)}
														style={{
															width: '100%',
															textAlign: 'center',
															userSelect: 'none',
															padding: 16,
															minHeight: '50px',
															backgroundColor: '#CFE9FD',
															color: 'black',
															cursor: 'grab'
														}}
													>
														{item.content}
													</div>
												))}
											</div>
										</div>
									</div>
								))}
						</div>
						<div style={{ height: '100%', width: '70%' }}>
							{Object.entries(columns)
								.filter(([columnId, column]: any) => columnId !== 'Unassigned Users')
								.map(([columnId, column]: any, index) => (
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'start'
										}}
										key={columnId}
									>
										<div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
											<p style={{ margin: '10px 0' }}>{column.name}</p>
											<div
												style={{ display: 'flex', justifyContent: 'space-between', gap: '8px', alignItems: 'center' }}
											>
												<Button
													variant="outlined"
													sx={{ height: '25px' }}
													color="primary"
													onClick={() => {
														setName(column.name);
														setSelectedGroupForAction(column);
														handleAddSubgroup();
													}}
												>
													Edit
												</Button>
												<Button
													variant="outlined"
													sx={{ height: '25px' }}
													color="error"
													onClick={() => handleDelete(column)}
												>
													Delete
												</Button>
											</div>
										</div>
										<div
											onDragOver={handleDragOver}
											onDrop={event => handleDrop(event, columnId, column)}
											style={{
												border: '2px dashed lightgray',
												padding: 4,
												width: '100%',
												minHeight: 70
											}}
										>
											<div
												style={{
													display: 'flex',
													flexWrap: 'wrap',
													justifyContent: 'start',
													gap: '10px',
													alignItems: 'center'
												}}
											>
												{column.items.map((item: any) => (
													<div
														key={item.id}
														draggable
														onDragStart={event => handleDragStart(event, item, columnId)}
														style={{
															userSelect: 'none',
															padding: 16,
															minHeight: '50px',
															backgroundColor: '#CFE9FD',
															color: 'black',
															cursor: 'grab'
														}}
													>
														{item.content}
													</div>
												))}
											</div>
										</div>
									</div>
								))}
						</div>
					</div>
				</Box>
			</Box>
		</>
	);
};

export default Subgroups;
