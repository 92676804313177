import React from 'react';
import { List, ListItem, Box, Typography } from '@mui/material';
import { ReactComponent as ShowCommentForm } from '../../../../Assets/Images/ShowCommentForm.svg';
import { ReactComponent as Dots } from '../../../../Assets/Images/Dots.svg';
import { IChat } from '../../../../types';

interface MessageListProps {
	messages: IChat[];
	selectedMessageId: string | null;
	onSelectMessage: (messageId?: string) => void;
}

const MessageList: React.FC<MessageListProps> = ({ messages, selectedMessageId, onSelectMessage }) => {
	return (
		<List>
			{messages.map(message => (
				<ListItem
					key={message.messageId}
					disablePadding
					sx={{
						display: 'flex',
						justifyContent: message.messageType === 'coach' ? 'flex-start' : 'flex-end',
						mb: 1
					}}
				>
					<Box
						sx={{
							display: 'flex',
							gap: '15px',
							alignItems: 'flex-start',
							maxWidth: '47%',
							border:
								selectedMessageId === message.messageId ? '1px solid #1c75ba;' : '1px solid rgba(217, 226, 233, 1)',
							borderRadius: '16px',
							padding: '16px',
							cursor: 'pointer'
						}}
						onClick={() => onSelectMessage(message?.messageId)}
					>
						<Typography
							sx={{
								fontSize: '15px',
								color: 'rgba(19, 52, 102, 1)'
							}}
						>
							{message.message}
						</Typography>
						<Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', paddingTop: '3px' }}>
							<Dots className="commentIcon" color="rgba(19, 52, 102, 1)" />
							<Box
								className={`comment-wrapper ${message.messageType === 'coach' ? 'active' : ''}`}
								sx={{ padding: '0 5px' }}
							>
								<ShowCommentForm className="commentIcon" />
							</Box>
						</Box>
					</Box>
				</ListItem>
			))}
		</List>
	);
};

export default MessageList;
