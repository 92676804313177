import React, { useEffect, useState } from 'react';
import {
	Box,
	Tab,
	Tabs,
	Button,
	Modal,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
	ListItem,
	IconButton,
	ListItemAvatar,
	Avatar,
	ListItemText,
	List,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	CircularProgress,
	RadioGroup,
	Radio,
	FormControlLabel,
	LinearProgress,
	tabClasses,
	Snackbar,
	Alert
} from '@mui/material';
import axios from 'axios';
import { getToken, useHandleToken } from '../../Helper/GetToken';
import DeleteIcon from '@mui/icons-material/Delete';
import getUser from '../../Helper/getUser';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { fireStorage } from '../../config/firebase';
import EditIcon from '@mui/icons-material/Edit';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
	Bar,
	BarChart,
	CartesianGrid,
	Legend,
	Rectangle,
	ResponsiveContainer,
	XAxis,
	YAxis,
	Tooltip as RechartTooltip,
	Cell
} from 'recharts';
import { styled } from '@mui/material/styles';
import UserWithBadge from './UserWithBadge';

const TabItem = styled(Tab)(({ theme }) => ({
	position: 'relative',
	borderRadius: '8px',
	textAlign: 'center',
	transition: 'all .5s',
	padding: '10px 15px',
	color: '#000',
	height: 'auto',
	margin: '10px 0',
	float: 'none',
	fontSize: '12px',
	fontWeight: '700',
	[theme.breakpoints.up('md')]: {
		minWidth: 120
	},
	[`&.${tabClasses.selected}, &:hover`]: {
		color: '#000000',
		borderColor: '#1C75BA',
		backgroundColor: '#E0F1FA',
		boxShadow: '0 7px 10px -5px rgba(76, 175, 80, 0.4)'
	}
}));

const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
interface Scenario {
	scenario_id: number;
	scenario_name: string;
	role_name: string | null;
	video_url: string | null;
	category: string;
	description: string;
	parent_id: number | null;
	voice_modal: string | null;
}

interface User {
	id: number;
	firebase_id: string;
	account_status: string;
	email: string;
	first_name: string;
	last_name: string;
	created_at: string;
	deleted_at: string | null;
}

interface Cohort {
	id: number;
	name: string;
	start_date: string | null;
	end_date: string | null;
	created_at: string;
	deleted_at: string | null;
	deleted_by: number | null;
	created_by: number;
}

interface StatisticsData {
	id: number;
	time_spended: number;
	average_session_score: number;
	scenario: Scenario;
	cohort: Cohort;
	highest_score: number;
}

interface Statistics {
	id: number;
	name: string;
	start_date: string;
	sessions: number;
	total_time: number;
	coach_total_time: number;
	average_score: number;
	communication: number | null;
	coaching: number | null;
	total_simulation: number;
	statisticsdata: StatisticsData[];
	user: User;
	Strengths: string;
	Weaknesses: string;
}

const Community = () => {
	const user = getUser();

	const [selectedCohort, setSelectedCohort] = useState<any>();
	const [cohorts, setCohorts] = useState([]);
	const [open, setOpen] = useState(false);
	const [documentName, setDocumentName] = useState('');
	const [description, setDescription] = useState('');
	const [documentUrl, setDocumentUrl] = useState('');
	const [documentUrlError, setDocumentUrlError] = useState('');
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
	const [isEmbedVideo, setIsEmbedVideo] = useState(false);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [documentToAction, setDocumentToAction] = useState<any>(null);
	const [isLoading, setIsLoading] = useState(false);

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const [isDocument, setIsDocument] = useState<any>(false);
	const [document, setDocument] = useState<File | null>(null);

	const [statisticData, setStatisticData] = useState<Statistics[]>([]);
	const [selectedModule, setSelectedModule] = useState<any>();

	const [graphData, setGraphData] = useState<any>();
	const token = getToken();
	const { unhandleToken } = useHandleToken();
	const [enableScoreboard, setEnableScoreboard] = useState(false);
	const [badgeData, setBadgeData] = useState([]);
	useEffect(() => {
		getStatistic();
		getUserWithBages();
	}, []);

	const [openAlert, setOpenAlert] = React.useState<{
		open: boolean;
		type?: 'success' | 'error' | 'warning' | undefined;
		message?: string;
	}>();

	const getStatistic = async () => {
		if (user.id) {
			try {
				const res = await axios.get(`${baseUrl}/getstatistics/${user.id}`, {
					headers: {
						Authorization: token
					}
				});
				if (res.data) {
					setStatisticData(res.data);
				}
			} catch (e) {
				console.log(e);
				unhandleToken(e);
			}
		}
	};
	const getUserWithBages = async () => {
		try {
			await axios
				.get(`${baseUrl}/level/allCohortUser`, {
					headers: {
						Authorization: token
					}
				})
				.then(res => {
					setBadgeData(res?.data);
				})
				.catch(e => {
					console.log(e);
					unhandleToken(e);
				});
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		if (statisticData?.length && selectedCohort) {
			const combination: any = {};
			statisticData?.map((item, index) => {
				if (item?.statisticsdata?.find(i => i?.cohort?.id === selectedCohort?.id)) {
					const scenarioData: {
						[key: string]: {
							time_spended: number | string;
							average_session_score: number | string;
							highest_score: number | string;
						};
					} = {};

					item.statisticsdata.forEach(statData => {
						const scenario = statData?.scenario?.scenario_name;
						if (selectedCohort?.scenario?.map((item: any) => item?.scenario_name)?.includes(scenario)) {
							scenarioData[scenario] = {
								time_spended: statData?.time_spended?.toFixed(2),
								average_session_score:
									statData?.average_session_score !== 0
										? ((100 * ((statData?.average_session_score as number) + 1)) / 4)?.toFixed(2)
										: '0.00',
								highest_score:
									statData?.highest_score !== 0
										? ((100 * ((statData?.highest_score as number) + 1)) / 4)?.toFixed(2)
										: '0.00'
							};
						}
					});
					combination[item?.name] = scenarioData;
				}
			});

			if (Object.keys(combination)?.length) {
				const createArray = (category: string) => {
					return Object.keys(combination).map(name => ({
						name: name,
						'Best Score': combination[name][category]?.highest_score
					}));
				};

				const finalObject: any = {};
				selectedCohort?.scenario
					?.map((item: any) => item?.scenario_name)
					?.map((item: string) => {
						finalObject[item] = createArray(item);
					});

				setGraphData(finalObject);
			}
		}
	}, [statisticData, selectedCohort]);

	const handleChange = (event: React.SyntheticEvent, newValue: any) => {
		setSelectedCohort(newValue);
		setSelectedModule(newValue?.scenario[0]?.scenario_name);
		setEnableScoreboard(newValue?.enableScoreboard);
	};

	const handleChangeEnableScoreboard = async (check: boolean) => {
		try {
			await axios
				.patch(
					`${baseUrl}/api/admin/cohort/enable-scoreboard/${selectedCohort.id}`,
					{
						enableScoreboard: check
					},
					{
						headers: {
							Authorization: token
						}
					}
				)
				.then(() => {
					setEnableScoreboard(check);
					getCohorts(false);
				})
				.catch(e => {
					console.log(e);
					unhandleToken(e);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleCalendarInvite = async () => {
		try {
			await axios
				.get(`${baseUrl}/trigger-calender-event/${selectedCohort.id}`, {
					headers: {
						Authorization: token
					}
				})
				.then((res: any) => {
					setOpenAlert({
						open: true,
						message: 'Calender event triggered successfully!',
						type: 'success'
					});
				})
				.catch(e => {
					console.log(e);
					unhandleToken(e);
				});
		} catch (err) {
			console.log(err);
		}
	};

	const handleOpen = () => setOpen(true);
	const handleClose = () => {
		setOpen(false);
		setDocumentName('');
		setDocumentUrl('');
		setDescription('');
		setDocumentUrlError('');
		setIsSubmitDisabled(true);
		setIsLoading(false);
		setDocumentToAction(null);
	};

	const validateDocumentUrl = (url: string) => {
		const urlPattern = /^(https?:\/\/)/i;
		if (!urlPattern.test(url)) {
			setDocumentUrlError('Please enter a valid URL that starts with http or https.');
			setIsSubmitDisabled(true);
		} else {
			setDocumentUrlError('');
			setIsSubmitDisabled(false);
		}
	};

	const handleDocumentUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const url = e.target.value;
		setDocumentUrl(url);
		validateDocumentUrl(url);
	};

	const handleDocumentSubmit = async () => {
		setIsLoading(true);

		let docUrl = documentUrl;

		if (isDocument === 'true') {
			if (document) {
				const fileName = `${new Date().getTime()}-${document?.name}`;
				const storageRef = await ref(fireStorage, `community_documents/${fileName}`);

				const snapshot = await uploadBytes(storageRef, document);
				const downloadURL = await getDownloadURL(snapshot.ref);
				docUrl = downloadURL;
			} else {
				setIsLoading(false);
				return;
			}
		}

		let payload: any = {
			name: documentName,
			document_url: docUrl,
			description: description,
			cohortId: selectedCohort?.id,
			userId: user?.id,
			embedVideo: isEmbedVideo
		};

		if (documentToAction) {
			payload = {
				...payload,
				id: documentToAction.id
			};
		}

		const token = getToken();
		try {
			await axios.post(`${baseUrl}/community/save-document`, payload, {
				headers: {
					Authorization: token
				}
			});
			getCohorts();
			handleClose();
			setIsLoading(false);
		} catch (e) {
			setIsLoading(false);
			console.log(e);
			unhandleToken(e);
		}
	};

	const handleDeleteClick = (document: any) => {
		setDocumentToAction(document);
		setDialogOpen(true);
	};

	const handleDeleteConfirm = async () => {
		setIsLoading(true);

		if (documentToAction) {
			const token = getToken();
			try {
				await axios.delete(`${baseUrl}/community/delete-document/${documentToAction.id}`, {
					headers: {
						Authorization: token
					}
				});
				getCohorts();
				setDialogOpen(false);
				setDocumentToAction(null);
				setIsLoading(false);
			} catch (e) {
				console.log(e);
				setIsLoading(false);
				unhandleToken(e);
			}
		}
	};

	const handleDialogClose = () => {
		setDialogOpen(false);
		setDocumentToAction(null);
	};

	const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files && e.target.files[0];
		if (file) {
			setDocument(file);
			setIsSubmitDisabled(false);
		}
	};

	const getCohorts = async (loading = true) => {
		if (loading) setIsLoading(true);

		const token = getToken();
		try {
			const response = await axios.get(`${baseUrl}/community/get-user-cohorts`, {
				headers: {
					Authorization: token
				}
			});

			if (response.data?.length > 0) {
				if (!selectedCohort) {
					setSelectedCohort(response.data[0]);
					setEnableScoreboard(response.data[0]?.enableScoreboard);
					setSelectedModule(response.data[0]?.scenario[0]?.scenario_name);
				} else {
					const cohort = response.data.find((cohort: any) => cohort.id === selectedCohort.id);
					if (cohort) {
						setSelectedCohort(cohort);
						setEnableScoreboard(cohort?.enableScoreboard);
					} else {
						setSelectedCohort(response.data[0]);
						setEnableScoreboard(response.data[0]?.enableScoreboard);
					}
				}
				setCohorts(response.data);
			}
			if (loading) setIsLoading(false);
		} catch (e) {
			console.log(e);
			if (loading) setIsLoading(false);
			unhandleToken(e);
		}
	};

	useEffect(() => {
		getCohorts();
	}, []);

	const bestScorers = () => {
		const bestScorers = graphData[selectedModule]
			?.filter(
				(item: any) =>
					item['Best Score'] ===
					graphData[selectedModule]?.reduce(
						(maxUser: any, user: any) => (+user['Best Score'] > (+maxUser['Best Score'] || 0) ? user : maxUser),
						{}
					)['Best Score']
			)
			?.map((item: any) => item['name']);

		// if (bestScorers.length > 1) {
		//   return bestScorers
		//           ?.filter((item: any) => item !== user?.name)
		//           ?.slice(0, 1)
		// }
		return bestScorers;
	};

	const handleClosePopup = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenAlert({ open: false, message: '', type: openAlert?.type });
	};

	return (
		<div>
			{openAlert && openAlert.open && (
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					open={openAlert?.open}
					autoHideDuration={5000}
					onClose={handleClosePopup}>
					<Alert
						onClose={handleClosePopup}
						severity={openAlert?.type}
						variant="filled"
						sx={{
							width: '20%',
							position: 'fixed',
							top: 0,
							right: 0,
							margin: '10px',
							zIndex: 9999
						}}>
						{openAlert?.message}
					</Alert>
				</Snackbar>
			)}
			{isLoading ? (
				<LinearProgress />
			) : (
				<Box sx={{ width: '100%', px: 2, bgcolor: 'background.paper' }}>
					<Tabs
						value={selectedCohort}
						onChange={handleChange}
						indicatorColor="primary"
						textColor="primary"
						variant={isSmallScreen ? 'fullWidth' : 'standard'}>
						{cohorts?.map((cohort: any, index) => (
							<Tab
								key={index}
								label={cohort?.name?.toString()?.toUpperCase()}
								value={cohort}
								sx={{ textTransform: 'none' }}
							/>
						))}
					</Tabs>

					{graphData && selectedModule && (
						<>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									gap: '16px',
									userSelect: 'none'
								}}>
								<div></div>
								{(selectedCohort?.enableScoreboard || user?.role === 'coach' || user?.role === 'admin') && (
									<Typography sx={{ my: 2, textAlign: 'center' }} variant="h6">
										Cohort Scoreboard
									</Typography>
								)}
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										gap: '8px',
										marginRight: '30px',
										cursor: 'pointer'
									}}
									onClick={() => handleChangeEnableScoreboard(!enableScoreboard)}>
									{(user?.role === 'coach' || user?.role === 'admin') && (
										<>
											{enableScoreboard ? <CheckBoxIcon /> : <CropSquareIcon />}
											<p>Enable Scoreboard</p>
										</>
									)}
								</div>
							</div>
							{(selectedCohort?.enableScoreboard || user?.role === 'coach' || user?.role === 'admin') && (
								<div
									style={{
										display: 'flex',
										width: '100%',
										marginTop: '8px',
										opacity: selectedCohort?.enableScoreboard ? 1 : 0.5
									}}>
									<div style={{ height: '500px', overflow: 'auto', padding: '10px' }}>
										<Tabs
											orientation="vertical"
											value={selectedModule}
											onChange={(e, value) => setSelectedModule(value)}
											indicatorColor="primary"
											textColor="primary"
											// sx={{
											// 	[`& .${tabsClasses.indicator}`]: {
											// 		display: 'none'
											// 	}
											// }}
											variant={isSmallScreen ? 'fullWidth' : 'standard'}>
											{selectedCohort?.scenario
												?.map((item: any) => item?.scenario_name)
												?.map((item: any, i: number) => (
													<TabItem
														sx={{
															ml: '8px',
															mr: '12px',
															height: '32px',
															border: '1px solid var(--Stroke, #DDE3ED)',
															textTransform: 'none',
															color: '#000',
															fontSize: '12px',
															fontWeight: '500',
															minHeight: '35px',
															padding: '5px 14px',
															minWidth: 'auto !important'
														}}
														key={i}
														label={item?.toString()?.toUpperCase()}
														value={item}
													/>
												))}
										</Tabs>
									</div>

									<ResponsiveContainer width="100%" height={500}>
										<BarChart
											data={graphData[selectedModule]}
											margin={{
												top: 5,
												right: 30,
												left: 20,
												bottom: 5
											}}>
											<XAxis
												dataKey="name"
												tickFormatter={name => {
													if (
														user?.role === 'admin' ||
														user?.role === 'coach' ||
														name === user?.name ||
														bestScorers()?.includes(name)
													) {
														return name;
													}
													return '';
												}}
											/>
											<YAxis
												// dataKey="Best Score"
												domain={[
													0,
													(dataMax: any) => {
														const array = graphData[selectedModule]?.map((item: any) => +item['Best Score']);
														if (!array) return 0;
														if (Math.ceil(Math.max(...array)) > 100) return Math.ceil(Math.max(...array));
														return 100;
													}
												]}
											/>
											<RechartTooltip
												content={({ payload }) => {
													if (!payload || !payload.length) return null;

													const currentUser = payload[0]?.payload?.name;
													const shouldDisplayTooltip =
														user?.role === 'admin' ||
														user?.role === 'coach' ||
														currentUser === user?.name ||
														bestScorers()?.includes(currentUser);

													if (!shouldDisplayTooltip) return null;

													return (
														<div className="custom-tooltip">
															<p className="label">{`${currentUser}`}</p>
															<p style={{ color: '#1976D2' }}>{`Best Score: ${payload[0].value}`}</p>
														</div>
													);
												}}
											/>
											<Legend />
											<CartesianGrid strokeDasharray="3 3" />
											{/* <Bar
												dataKey="Best Score"
												fill="#1976D2"
												// background={{ fill: '#eee' }}
												activeBar={<Rectangle fill="#1976D2" stroke="#BCE0FC" />}
											/> */}
											<Bar dataKey="Best Score" fill="#1976D2">
												{graphData[selectedModule]?.map((entry: any) => {
													return (
														<Cell
															key={entry.name}
															fill={entry.name === user?.name ? '#006101' : '#1976D2'} // Set the color based on user name
														/>
													);
												})}
											</Bar>
										</BarChart>
									</ResponsiveContainer>
								</div>
							)}
						</>
					)}

					{user?.role === 'coach' && (
						<div style={{ display: 'flex', justifyContent: 'end', width: '100%', marginBottom: '16px' }}>
							<Button variant="contained" color="primary" onClick={handleCalendarInvite}>
								Send Calendar Invite
							</Button>
						</div>
					)}

					{Array.isArray(badgeData) && badgeData.length ? (
						<UserWithBadge badgeData={badgeData} selectedCohort={selectedCohort} />
					) : (
						''
					)}
					<Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
						<Typography variant="h6">Documents</Typography>
						{(user?.role === 'coach' || user?.role === 'admin') && (
							<Button variant="contained" color="primary" onClick={handleOpen}>
								Add Document
							</Button>
						)}
					</Box>
				</Box>
			)}

			{isLoading ? (
				<Box
					sx={{
						width: '100%',
						px: 2,
						bgcolor: 'background.paper',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: 'calc(100vh - 24rem)'
					}}>
					{/* <CircularProgress /> */}
				</Box>
			) : (
				<Box sx={{ width: '100%', px: 2, bgcolor: 'background.paper' }}>
					<List>
						{selectedCohort?.community?.length ? (
							selectedCohort?.community?.map((document: any, index: number) => (
								<Box
									key={index}
									sx={{
										'&:hover': {
											backgroundColor: 'rgba(0, 0, 0, 0.04)'
											// color: theme.palette.primary.main
										},
										cursor: 'pointer'
									}}>
									<ListItem
										sx={{ display: 'flex', alignItems: 'baseline' }}
										secondaryAction={
											(user?.role === 'coach' || user?.role === 'admin') && (
												<>
													<IconButton
														sx={{ mr: 1 }}
														color="primary"
														onClick={() => {
															setDocumentToAction(document);
															setDocumentName(document?.name);
															setDocumentUrl(document?.document_url);
															setDescription(document?.description);
															setIsDocument(false);
															setIsSubmitDisabled(false);
															setIsEmbedVideo(document?.embedVideo);
															handleOpen();
														}}
														edge="end"
														aria-label="edit">
														<EditIcon />
													</IconButton>
													<IconButton
														color="error"
														onClick={() => handleDeleteClick(document)}
														edge="end"
														aria-label="delete">
														<DeleteIcon />
													</IconButton>
												</>
											)
										}>
										<ListItemAvatar sx={{ color: '#1976D2' }}>{index + 1}.</ListItemAvatar>
										<ListItemText sx={{ marginRight: '50px' }}>
											<Typography
												onClick={() => {
													window.open(document?.document_url, '_blank');
												}}
												sx={{ color: '#207FE2' }}
												variant="subtitle1">
												{document?.name}
											</Typography>
											<Typography variant="caption">{document?.description}</Typography>
											{document?.embedVideo && (
												<div style={{ display: 'flex', marginTop: '10px' }}>
													<video controls style={{ maxWidth: '50%' }} src={document?.document_url}>
														Your browser does not support to load the video.
													</video>
												</div>
											)}
										</ListItemText>
									</ListItem>
								</Box>
							))
						) : (
							<Typography
								sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}
								variant="subtitle1">
								No Documents Found
							</Typography>
						)}
					</List>
				</Box>
			)}

			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="add-document-modal"
				aria-describedby="add-document-form">
				<Box sx={{ ...modalStyle }}>
					<Typography variant="h6" component="h2">
						{documentToAction ? 'Edit Document' : 'Add New Document'}
					</Typography>
					<TextField
						margin="normal"
						fullWidth
						label="Document Name"
						value={documentName}
						onChange={e => setDocumentName(e.target.value)}
					/>
					<RadioGroup
						row
						value={isDocument}
						onChange={e => {
							setIsDocument(e.target.value);
							setIsSubmitDisabled(false);
							setDocument(null);
						}}>
						<FormControlLabel value="true" control={<Radio />} label="Upload Document" />
						<FormControlLabel value="false" control={<Radio />} label="Enter URL" />
					</RadioGroup>

					{isDocument === 'true' ? (
						<TextField required fullWidth type="file" onChange={handleFileChange} sx={{ mt: 2, pb: '20px' }} />
					) : (
						<TextField
							margin="normal"
							fullWidth
							label="Document URL"
							value={documentUrl}
							onChange={handleDocumentUrlChange}
							error={!!documentUrlError}
							helperText={documentUrlError}
						/>
					)}

					<label className="signUp-checkbox">
						<input type="checkbox" checked={isEmbedVideo} onChange={e => setIsEmbedVideo(e.target.checked)} />
						<span style={{ marginLeft: '8px' }}>Embed Video</span>
					</label>

					<textarea
						name="description"
						className="formDescription"
						placeholder="Add document description (optional)"
						maxLength={1000}
						onChange={e => setDescription(e.target.value)}
						rows={5}
						required
						value={description || ''}></textarea>
					<div style={{ marginTop: '16px', width: '100%', display: 'flex', gap: '8px', justifyContent: 'end' }}>
						<Button
							disabled={isLoading}
							onClick={() => {
								handleClose();
							}}
							color="primary">
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={handleDocumentSubmit}
							disabled={
								isSubmitDisabled ||
								documentName === '' ||
								(isDocument === 'true' && !document) ||
								(isDocument === 'false' && documentUrl === '')
							}>
							{isLoading ? <CircularProgress size={24} color="inherit" /> : documentToAction ? 'Update' : 'Create'}
						</Button>
					</div>
				</Box>
			</Modal>

			<Dialog
				open={dialogOpen}
				onClose={handleDialogClose}
				aria-labelledby="delete-confirmation-dialog"
				aria-describedby="delete-confirmation-dialog-description">
				<DialogTitle id="delete-confirmation-dialog">Delete Document</DialogTitle>
				<DialogContent>
					<DialogContentText id="delete-confirmation-dialog-description">
						Are you sure you want to delete this document?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button disabled={isLoading} onClick={handleDialogClose} color="primary">
						Cancel
					</Button>
					<Button onClick={handleDeleteConfirm} color="error" variant="contained">
						{isLoading ? <CircularProgress size={24} color="inherit" /> : 'Delete'}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

const modalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	borderRadius: 4,
	boxShadow: 24,
	p: 4
};

export default Community;
