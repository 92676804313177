import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Box, Button } from '@mui/material';
import { IChat } from '../../../../types';
import MessageList from './MessageList';

interface ISelectedConversation {
	conversationId: string;
	messages: IChat[];
	summaryTitle: string;
	description: string;
}

interface ISelectStartingPointWindowProps {
	open: boolean;
	closeWindow: () => void;
	selectedConversation: ISelectedConversation | null;
}

const SelectStartingPointWindow: React.FC<ISelectStartingPointWindowProps> = ({
	open,
	closeWindow,
	selectedConversation
}) => {
	const [selectedMessageId, setSelectedMessageId] = useState<string | null>(null);

	const handleSelectMessage = (messageId?: string) => {
		if (messageId) {
			if (messageId === selectedMessageId) {
				setSelectedMessageId(null);
			} else {
				setSelectedMessageId(messageId);
			}
		}
	};

	return (
		<Dialog open={open} onClose={closeWindow} maxWidth="md" fullWidth aria-labelledby="dialog-title">
			<DialogTitle id="dialog-title" sx={{ display: 'flex', flexDirection: 'column' }}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '42px', mt: '24px' }}>
					<Typography
						sx={{
							fontFamily: 'IBM Plex Sans',
							fontSize: '24px',
							fontWeight: 500,
							lineHeight: '18px'
						}}
					>
						Selected Previous Conversation
					</Typography>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						gap: '15px'
					}}
				>
					<Typography sx={defaultTextStyle}>{selectedConversation?.summaryTitle}</Typography>
				</Box>
			</DialogTitle>
			<DialogContent dividers>
				{selectedConversation ? (
					<MessageList
						messages={selectedConversation.messages}
						selectedMessageId={selectedMessageId}
						onSelectMessage={handleSelectMessage}
					/>
				) : (
					<Typography variant="body2">No conversation selected.</Typography>
				)}
			</DialogContent>
			<DialogTitle>
				<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '26px', margin: '10px 0' }}>
					<Button
						variant="outlined"
						sx={{
							border: '1px solid rgba(28, 117, 186, 1)',
							padding: '16px',
							borderRadius: '8px',
							color: 'rgba(28, 117, 186, 1)',
							fontFamily: 'Outfit',
							fontSize: '18px',
							fontWeight: 400,
							lineHeight: '18px',
							whiteSpace: 'nowrap',
							flexShrink: 0,
							textTransform: 'none',
							width: '220px'
						}}
						onClick={closeWindow}
					>
						Confirm
					</Button>
				</Box>
			</DialogTitle>
		</Dialog>
	);
};

const defaultTextStyle = {
	fontFamily: 'IBM Plex Sans',
	fontSize: '20px',
	fontWeight: 400,
	lineHeight: '18px'
};

export default SelectStartingPointWindow;
