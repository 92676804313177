import React, { useContext } from 'react';
import { ChatContext } from '../../Contexts';
import { useSelector } from 'react-redux';
import smallLogo from '../../Assets/Images/smallLogo.png';
import './AvatarWindow.css';
import { Button, CircularProgress } from '@mui/material';

interface AvatarWindow {
	onClick: () => void;
	conversationType?: string;
}

const AvatarWindow: React.FC<AvatarWindow> = ({ onClick, conversationType = 'simulation' }) => {
	const { videoElement, coachResponce } = useContext(ChatContext);
	const simulation = useSelector((state: any) => state?.selectedSimulation?.selectedSimulation);

	const roleName = conversationType === 'simulation' ? simulation?.role_name || '' : 'Coach';
	const [firstPart = '', secondPart = ''] = roleName?.split('-') || [];

	return (
		<>
			<div className="avatar-window">
				<div className="video-container">
					<video ref={videoElement} autoPlay className="video-element" />
				</div>
				<div onClick={onClick} className="close-button">
					<span>&#10005;</span>
				</div>
				<div className="text-section">
					<div className="flex-container">
						<div className="text-container">
							<p className="text-title">{firstPart}</p>
							<p className="text-subtitle">{secondPart}</p>
						</div>
						<div className="logo-container">
							{simulation?.company && simulation.company?.logoUrl ? (
								<img src={simulation.company.logoUrl} alt="Uploaded Logo" />
							) : (
								<>
									<img src={smallLogo} alt="Uploaded Logo" />
									<p className="text-title">SIMSOLA</p>
								</>
							)}
						</div>
					</div>
					<div className="text-description-container">
						<p className="text-description">{coachResponce}</p>
					</div>
					<div className="button-container">
						<div onClick={onClick} className="next-button">
							<button>Leave</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AvatarWindow;
