import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

const LoaderBackdrop = styled(Backdrop)(({ theme }) => ({
	zIndex: theme.zIndex.drawer + 1,
	color: '#fff',
	backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adds blur effect
	backdropFilter: 'blur(5px)' // Adjust the blur amount
}));

const Loader = () => {
	return (
		<LoaderBackdrop open>
			<CircularProgress color="inherit" />
		</LoaderBackdrop>
	);
};

export default Loader;
