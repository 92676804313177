import { Box, Typography, Grid, Chip, Tooltip } from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { getToken, useHandleToken } from '../../../Helper/GetToken';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ChatContext } from '../../../Contexts';
import { setConversationId, setRole, setSimulationStep } from '../../../Helper/Redux/Action/userAction';
import { getConversationsOfUser } from '../../../Helper';
import getUser from '../../../Helper/getUser';
import moment from 'moment';
// import refresh from '../../../Assets/Images/refresh.svg';
export const getColor = (value: number) => {
	if (value >= 60) {
		return 'green';
	} else if (value > 30) {
		return 'orange';
	} else {
		return 'red';
	}
};

interface IProps {
	conversationCategoryCode: number;
}

const MeetingCardForProgress = ({ conversationCategoryCode }: IProps) => {
	const [allData, setAllData] = useState([]);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user = getUser();
	const [members, setMembers] = useState<any[]>([]);
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const selectedUserId = useSelector((state: any) => state.selectedUser.userId);
	const { setSelectedConversationId, setConversations, setMessages } = useContext(ChatContext);
	const { unhandleToken } = useHandleToken();
	useEffect(() => {
		callAll();
	}, [conversationCategoryCode]);
	useEffect(() => {
		getScenarios();
	}, []);

	const callAll = async () => {
		await axios
			.get(`${baseUrl}/allmeetings/${selectedUserId}/${conversationCategoryCode}`, {
				headers: {
					Authorization: token
				}
			})
			.then(res => {
				if (res?.data?.success) {
					setAllData(res.data.result);
				}
			})
			.catch(e => {
				console.log(e.status);
				unhandleToken(e);
			});
	};

	const getScenarios = async () => {
		await axios
			.get(`${baseUrl}/api/scenario/allScenarios`, {
				headers: {
					Authorization: token
				}
			})
			.then(res => {
				if (res.data) {
					setMembers(res.data);
				}
			})
			.catch(e => {
				console.log(e);
				unhandleToken(e);
			});
	};

	const selectSessionHandler = async (item: any) => {
		setSelectedConversationId(item.conversationId);
		dispatch(setConversationId(item.conversationId));
		const validSessionNames = ['simulationPrepare', 'simulation', 'debrief', 'completeSimulation'];
		if (item.sessionName === 'chat') {
			navigate('/session', { state: { notDefault: true } });
			return;
		} else if (validSessionNames.includes(item.sessionName)) {
			let userId;
			selectedUserId ? (userId = selectedUserId) : (userId = user?.id);

			const conversations = await getConversationsOfUser(
				item.sessionName === 'completeSimulation' ? 'simulation' : item.sessionName,
				userId
			);
			if (!Array.isArray(conversations) || !conversations.length) {
				console.error('No conversations found or an error occurred');
				return;
			}
			const filteredConversation = conversations.find(c => c.conversationId === item.conversationId);

			if (!filteredConversation) {
				console.error('No matching conversation found');
				return;
			}

			const role = members.find((m: any) => m.scenario_id === filteredConversation.scenarioId);

			if (!role) {
				console.error('Role not found in member list');
				return;
			}
			dispatch(setRole(role));
			if (item.sessionName === 'completeSimulation') {
				dispatch(setSimulationStep(3));
				navigate(`/simulations?simulationId=${item.conversationId}`, {
					state: { notDefault: true }
				});
				return;
			} else {
				setConversations(conversations);
				setMessages(filteredConversation.messages);
				setSelectedConversationId(filteredConversation.conversationId);
				// Determine simulation step based on session type
				const step =
					item.sessionName === 'simulationPrepare'
						? 1
						: item.sessionName === 'simulation'
							? // replace with 2
								2
							: item.sessionName === 'debrief'
								? 3
								: 0;
				dispatch(setSimulationStep(step));
				navigate(`/simulations`, {
					state: { notDefault: true }
				});
			}
		} else {
			console.error('Invalid session name');
		}
	};

	const sessionCounts: any = {};
	const excludedKeys = new Set([
		'sessionName',
		'conversation',
		'time',
		'conversationId',
		'argumentation',
		'perspective',
		'archived',
		'deleted_at',
		'senarioId',
		'title',
		'meetingId',
		'attendee'
	]);

	return (
		<Box>
			{Array.isArray(allData) &&
				allData.length > 0 &&
				allData.map((items: any, index: number) => {
					return (
						<>
							<Box sx={{ margin: '10px' }} key={index}>
								<Typography sx={{ margin: '20px 0' }}>{items?.month}</Typography>

								<Grid container spacing={2}>
									{Array.isArray(items.data) &&
										items.data.length > 0 &&
										items.data.map((item: any, indexs: number) => {
											if (((user.role === 'coach' || user.role === 'admin') && item?.archived) || !item?.archived) {
												const sessionName = members?.find(
													(i: any) => i?.scenario_id === item?.senarioId
												)?.scenario_name;

												// Count occurrences of each sessionName
												if (sessionCounts[sessionName]) {
													sessionCounts[sessionName] += 1;
												} else {
													sessionCounts[sessionName] = 1;
												}

												// Calculate the sum and average of valid entries
												const validEntries = Object.entries(item).filter(([key]) => !excludedKeys.has(key));
												const sum = validEntries.reduce(
													(acc, [, value]) => acc + (100 * ((value as number) + 1)) / 4,
													0
												);
												const average = validEntries.length > 0 ? sum / validEntries.length : 0;

												return (
													<Grid key={indexs} item xs={12} md={6} lg={4}>
														{/* on hover of this below box */}
														<Box
															className="boxHoverParent"
															sx={{
																cursor: 'pointer',
																position: 'relative'
															}}
															onClick={() => selectSessionHandler(item)}
														>
															<Tooltip arrow title={item?.title} placement="top">
																<Box
																	sx={{
																		position: 'absolute',
																		left: -5,
																		top: -10,
																		height: '15px',
																		width: '15px',
																		display: 'flex',
																		justifyContent: 'center',
																		alignItems: 'center',
																		zIndex: 998,
																		background: 'gray',
																		color: '#fff',
																		borderRadius: '50%',
																		padding: '10px'
																	}}
																>
																	?
																</Box>
															</Tooltip>
															{item?.archived && (
																<Tooltip arrow title={'Deleted by user'} placement="top">
																	<Box
																		sx={{
																			position: 'absolute',
																			right: -5,
																			top: -5,
																			height: '15px',
																			width: '15px',
																			display: 'flex',
																			justifyContent: 'center',
																			alignItems: 'center',
																			zIndex: 998,
																			background: '#ff0000',
																			borderRadius: '50%',
																			color: '#fff',
																			padding: '10px'
																		}}
																	>
																		!
																	</Box>
																</Tooltip>
															)}
															<Box
																sx={{
																	padding: '25px',
																	borderRadius: '12px',
																	border: item?.archived ? '1px solid #ff0000' : '1px solid #E5EAF2',
																	background: '#FAFBFD',
																	display: 'flex',
																	justifyContent: 'space-between'
																}}
															>
																<div
																	style={{
																		display: 'flex',
																		justifyContent: 'space-between',
																		alignItems: 'end'
																	}}
																>
																	<div>
																		<Typography
																			sx={{
																				fontSize: '20px',
																				fontWeight: '500',
																				color: 'var(--Text-text-primary, #161616)',
																				fontFamily: 'IBM Plex Sans'
																			}}
																		>
																			{`${members?.find((i: any) => i?.scenario_id === item?.senarioId)?.scenario_name} ${sessionCounts[sessionName]}` ||
																				`${'Unknown'} ${sessionCounts[sessionName]}`}
																		</Typography>
																		<Typography
																			sx={{
																				fontSize: '16px',
																				fontWeight: '500',
																				color: '#7D89A1',
																				fontFamily: 'IBM Plex Sans'
																			}}
																		>
																			{moment(item?.time).format('MMM DD')}
																		</Typography>
																	</div>
																</div>

																{/* Display average here */}
																<Box sx={{ marginTop: '10px' }}>
																	{/* <Typography sx={{ fontWeight: 'bold' }}>Average</Typography> */}
																	<Typography
																		sx={{
																			fontWeight: '500',
																			color: 'black',
																			fontSize: '18px',
																			fontFamily: 'IBM Plex Sans'
																		}}
																	>
																		{average.toFixed(2)} {/* Display the average with 2 decimal places */}
																	</Typography>
																</Box>
															</Box>

															<Box
																className="boxShowChild"
																sx={{
																	display: 'none',
																	position: 'absolute',
																	width: '100%',
																	backgroundColor: 'white',
																	top: '0px',
																	left: 0,
																	padding: '5px 10px',
																	borderRadius: '12px',
																	border: item?.archived ? '1px solid #ff0000' : '1px solid #E5EAF2',
																	minHeight: '110px'
																}}
															>
																{Object.entries(item).map(([key, value]) => {
																	if (!excludedKeys.has(key)) {
																		return (
																			<Box
																				key={key}
																				sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
																			>
																				<Typography>{key}</Typography>
																				<Typography sx={{ fontWeight: 'bold' }}>
																					{(100 * ((value as number) + 1)) / 4}
																				</Typography>
																			</Box>
																		);
																	}
																	return null;
																})}
															</Box>
														</Box>
													</Grid>
												);
											}
										})}
								</Grid>
							</Box>
						</>
					);
				})}
		</Box>
	);
};

export default MeetingCardForProgress;
