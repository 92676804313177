/* eslint-disable @typescript-eslint/ban-types */
import React, { useContext, useEffect, useRef } from 'react';
import ChatMessage from './ChatMessage';
import { ChannelE, ChatContext } from '../../Contexts';
import { IChat } from '../../types';
import { useChannel } from '../../hooks/useChannel';
import { textToSpeech } from '../../Helper/TextToSpeech';
import { useDispatch, useSelector } from 'react-redux';
import useBackToDefault from '../../Helper/helperFunction';
import { getConversationsOfUser, getFeedbacksOfChat, getRecommendationsOfChat } from '../../Helper';
import getUser from '../../Helper/getUser';
import { useLocation } from 'react-router-dom';
import { clearConversationId } from '../../Helper/Redux/Action/userAction';
import TypingIndicator from '../Loader/TypingLoader/TypingDots';

const ChatBox: React.FC = () => {
	const backtoDefault = useBackToDefault();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const voiceStatus = useSelector((state: any) => state.voice.voiceStatus);
	const conversationId = useSelector((state: any) => state.conversationId.conversationId);
	const selectedUserId = useSelector((state: any) => state.selectedUser.userId);
	const user = getUser();
	const { sendMessage } = useChannel(ChannelE.clientMessage);

	useEffect(() => {
		getConversations();
	}, []);

	const getConversations = async () => {
		let userId;
		selectedUserId ? (userId = selectedUserId) : (userId = user?.id);
		const conversations = await getConversationsOfUser('chat', userId);

		const conversation = conversations?.find(conversation => conversation.conversationId === conversationId);
		const lastClientMessage = conversation?.messages.reverse().find(message => message.messageType === 'client');

		setMessages((conversation?.messages.reverse() as IChat[]) || []);
		if (lastClientMessage?.messageId) {
			const listFeedbacks = await getFeedbacksOfChat('feedback', userId, conversationId, lastClientMessage?.messageId);
			const listRecommendations = await getRecommendationsOfChat(
				'recommendation',
				userId,
				conversationId,
				lastClientMessage?.messageId
			);

			listFeedbacks && setFeedbackContent(listFeedbacks);
			listRecommendations && setRecommendations(listRecommendations);
		}
	};

	const {
		messages,
		recommendations,
		selectedConversationId,
		setMessages,
		setChatLoading,
		chatLoading,
		setConversations,
		conversations,
		coachSays,
		audioQueue,
		isPlaying,
		setFeedbackContent,
		setRecommendations,
		setChatProcessing,
		setCoachSays,
		setSimulationFeedbackRequire,
		setSelectedConversationId,
		setAudioQueue,
		setPlaying
	} = useContext(ChatContext);
	const { state } = useLocation();
	const messagRef = useRef<HTMLDivElement>(null);
	const dispatch = useDispatch();
	useChannel(ChannelE.coachMessage, eventMessage => {
		const { message, conversationId, allMessages, messageCompleted, audioChunk } = eventMessage;

		if (conversationId === selectedConversationId) {
			if (message?.messageType === 'coach' && allMessages) {
				// Duplication checking
				let duplicateLine = false;
				if (message?.messageType === 'coach' && audioChunk && voiceStatus) {
					for (const item of audioQueue) {
						if (audioChunk == item) {
							duplicateLine = true;
						}
					}
					if (!duplicateLine) {
						textToSpeech(audioChunk, audioQueue, setAudioQueue, 'onyx');
					}
				}
				setChatLoading(false);
				setMessages(allMessages);
				const responseConversations = conversations.map(conversation =>
					conversation.conversationId === conversationId
						? {
								...conversation,
								messages: allMessages
							}
						: conversation
				);
				setConversations(responseConversations);
				if (messageCompleted) {
					setChatLoading(false);
				}
			} else {
				setMessages((prevMessages = []) => {
					if (prevMessages[prevMessages.length - 1]?.messageId == message?.messageId) {
						return prevMessages;
					}
					return [...prevMessages, message];
				});
			}
			if (message?.messageType === 'coach' && messageCompleted) {
				setChatProcessing(false);
			}
		}
	});

	// Update every time isPlaying or AudioQueue is changed
	useEffect(() => {
		if (audioQueue.length > 0) {
			setCoachSays(true);
		} else {
			setCoachSays(false);
		}
		if (audioQueue[0] instanceof Audio && !isPlaying) {
			setPlaying(true);
			const audio = audioQueue[0];
			const playAudio = async () => {
				try {
					await audio.play();
				} catch {
					// The audio is not ready to be played, try again later
					setTimeout(playAudio, 100);
				}
			};
			playAudio();
			audio.addEventListener('ended', async () => {
				setAudioQueue(prevQueue => prevQueue.slice(1));
				setPlaying(false);
			});
		}
	}, [audioQueue, isPlaying]);
	useChannel(ChannelE.feedbackChannel, async eventMessage => {
		const { feedbackChat, conversationId } = eventMessage;
		if (feedbackChat && selectedConversationId === conversationId) {
			setFeedbackContent(feedbackChat);
		}
	});

	useChannel(ChannelE.recommendationChannel, async eventMessage => {
		const { recommendationChat, conversationId } = eventMessage;
		if (recommendationChat && selectedConversationId === conversationId) {
			setRecommendations(recommendationChat);
		}
	});

	// Handle the scrolling of the page for new added messages
	useEffect(() => {
		if (messagRef.current) {
			messagRef.current.scrollTop = messagRef.current.scrollHeight;
		}
	}, [messages, recommendations]);
	useEffect(() => {
		if (state?.notDefault) {
			return;
		}
		backtoDefault();
		dispatch(clearConversationId());
	}, []);

	return (
		<div
			className={`${recommendations && recommendations?.length > 0 ? 'message-wrapper' : 'message-wrapper-without-rec'} ${user?.role === 'coach' && 'fullHeight'}`}
			ref={messagRef}
		>
			{messages?.map((chat: IChat, index: number) => (
				<ChatMessage key={index} message={chat} index={index} sendMessage={sendMessage} />
			))}
			{chatLoading && (
				<>
					<TypingIndicator />
				</>
			)}
		</div>
	);
};

export default ChatBox;
