import React, { useEffect, useState } from 'react';
import {
	Box,
	SvgIcon,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from '@mui/material';
import axios from 'axios';
import { getToken, useHandleToken } from '../../../Helper/GetToken';
import { AssignedSubCategory } from './AssignedSubCategory';
import { getIcon } from '../../../utils/svgIcons';
const AssignedCategory = () => {
	const [tableData, setTableData] = useState([]);
	const [showMember, setShowMember] = useState(false);
	const [parent_id, setParent_id] = useState(0);
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const { unhandleToken } = useHandleToken();
	useEffect(() => {
		getAssigned();
	}, []);

	const getAssigned = async () => {
		await axios
			.get(`${baseUrl}/api/scenario/cohortscenerio`, {
				headers: {
					Authorization: token
				}
			})
			.then(res => {
				if (res.data) {
					setTableData(res.data);
				}
			})
			.catch(e => {
				console.log(e);
				unhandleToken(e);
			});
	};
	const memberHandler = (id: number) => {
		setParent_id(id);
		setShowMember(true);
	};
	return (
		<>
			{showMember ? (
				<AssignedSubCategory parent_id={parent_id} setShowMember={setShowMember} />
			) : tableData.length ? (
				<Box sx={{ padding: '0 10px' }}>
					<Box sx={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
						<Box>
							<Typography variant="h6">Scenario Category</Typography>
						</Box>
					</Box>
					<Box>
						<TableContainer sx={{ borderRadius: '8px' }}>
							<Table>
								<TableHead
									sx={{
										background: '#CFE9FD',
										border: '1px solid rgba(224, 224, 224, 1)'
									}}>
									<TableRow sx={{ textWrap: 'nowrap' }}>
										<TableCell align="left">Scenario Id</TableCell>
										<TableCell align="left">Scenario Name</TableCell>
										<TableCell align="left">Category</TableCell>
										<TableCell align="left">Description</TableCell>
										<TableCell align="left">Role</TableCell>
										<TableCell align="left">Profile</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{Array.isArray(tableData) &&
										tableData.length > 0 &&
										tableData.map((items: any, index: number) => {
											const icon = getIcon(items?.picture?.name);
											return (
												<>
													<TableRow sx={{ border: '1px solid rgba(224, 224, 224, 1)', cursor: 'pointer' }} key={index}>
														<TableCell align="left" onClick={() => memberHandler(items.scenario_id)}>
															{items.scenario_id}
														</TableCell>
														<TableCell align="left" onClick={() => memberHandler(items.scenario_id)}>
															{items.scenario_name}
														</TableCell>
														<TableCell align="left" onClick={() => memberHandler(items.scenario_id)}>
															{items.category}
														</TableCell>
														<TableCell align="left" onClick={() => memberHandler(items.scenario_id)}>
															{items.description}
														</TableCell>
														<TableCell align="left" onClick={() => memberHandler(items.scenario_id)}>
															{items?.role_name || '-'}
														</TableCell>
														<TableCell align="left" onClick={() => memberHandler(items.scenario_id)}>
															{icon ? (
																<SvgIcon
																	component={icon}
																	viewBox="0 0 384 512"
																	transform="translate(-8, -2)"
																	sx={{ fontSize: '2rem', width: '1.1em' }}
																/>
															) : null}
														</TableCell>
													</TableRow>
												</>
											);
										})}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</Box>
			) : (
				<Box sx={{ padding: '10px' }}>No Scenario found.</Box>
			)}
		</>
	);
};

export default AssignedCategory;
