import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';

export const ScenarioList = ({ folderData = [], search = '', setSelectedScenario, total }: any) => {
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage + 1);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLimit(parseInt(event.target.value, 10));
		setPage(1);
	};

	useEffect(() => {
		if (search?.length) setPage(1);
	}, [search]);

	return (
		<TableContainer sx={{ borderRadius: '8px' }}>
			<Table>
				<TableHead
					sx={{
						background: '#CFE9FD',
						border: '1px solid rgba(224, 224, 224, 1)'
					}}
				>
					<TableRow>
						<TableCell align="left">Scenario Name</TableCell>
						<TableCell align="right"></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{folderData?.length > 0 &&
						folderData.filter((item: any) => item?.name.toLowerCase().includes(search?.toLowerCase()?.trim())).length >
							0 &&
						folderData
							.filter((item: any) => item?.name.toLowerCase().includes(search?.toLowerCase()?.trim()))
							.slice((page - 1) * limit, page * limit)
							.map((item: any, cohortIndex: number) => (
								<TableRow sx={{ border: '1px solid rgba(224, 224, 224, 1)' }} key={`${item?.name}-${cohortIndex}`}>
									<TableCell align="left">{item?.name}</TableCell>
									<TableCell
										sx={{ cursor: 'pointer', color: '#278BD8' }}
										align="left"
										onClick={() => {
											setSelectedScenario(item);
										}}
									>
										View
									</TableCell>
								</TableRow>
							))}
				</TableBody>
			</Table>
			<TablePagination
				component="div"
				count={total}
				page={page - 1}
				onPageChange={handleChangePage}
				rowsPerPage={limit}
				onRowsPerPageChange={handleChangeRowsPerPage}
				rowsPerPageOptions={[5, 10, 20]}
			/>
		</TableContainer>
	);
};
