import React, { useEffect, useRef, useState } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TablePagination,
	Paper,
	CircularProgress,
	Box,
	Tab,
	Tabs,
	TextField
} from '@mui/material';
import axios from 'axios';
import { getToken, useHandleToken } from '../../../Helper/GetToken';

interface DataRow {
	fileDate: string;
	score: number;
	scenario: { scenario_name: string };
}

interface ScenarioData {
	scenario_id: number;
	scenario_name: string;
}

const ConversationEvaluation = () => {
	const [data, setData] = useState<DataRow[]>([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(false);
	const [allScenario, setAllScenario] = useState<ScenarioData[]>([]);
	const [selectedScenarioId, setSelectedScenarioId] = useState<number | undefined>(undefined);
	const [search, setSearch] = useState('');
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const { unhandleToken } = useHandleToken();
	const controller = useRef<AbortController | null>(null);
	// Fetch scenarios once when the component mounts
	useEffect(() => {
		const fetchScenarios = async () => {
			try {
				const response = await axios.get(`${baseUrl}/api/scenario/scenarios`, {
					headers: { Authorization: token }
				});
				setAllScenario(response.data.scenarios);
				// setSelectedScenarioId(response.data[0]?.scenario_id); // Set default scenario ID
			} catch (error) {
				console.error('Error fetching scenarios:', error);
				unhandleToken(error);
			}
		};

		fetchScenarios();
	}, []);

	// Fetch evaluation data based on selectedScenarioId, page, and rowsPerPage
	useEffect(() => {
		fetchData(search);
	}, [selectedScenarioId, page, rowsPerPage]);

	const fetchData = async (search?: string) => {
		if (controller.current) {
			controller.current.abort();
		}

		// Create a new AbortController instance
		controller.current = new AbortController();
		const { signal } = controller.current;
		setLoading(search ? false : true);
		try {
			const response = await axios.get(`${baseUrl}/api/admin/getEvaluationReport`, {
				params: {
					page: page + 1, // API expects 1-based index for pagination
					limit: rowsPerPage,
					search: search?.trim(),
					scenario_id: selectedScenarioId // Send selected scenario ID for filtering
				},
				headers: { Authorization: token },
				signal
			});

			setData(response.data.data);
			setTotal(response.data.pagination.total);
		} catch (error) {
			console.error('Error fetching data:', error);
			unhandleToken(error);
		} finally {
			setLoading(false);
		}
	};
	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0); // Reset page to 0 when rows per page is changed
	};

	const handleTabChange = (event: React.SyntheticEvent, newScenarioId: number) => {
		setSelectedScenarioId(newScenarioId);
		setPage(0);
	};
	const inputHandler = (e: { target: { value: string } }) => {
		setSearch(e.target.value);
		if (page !== 0) {
			setPage(0);
		}
		fetchData(e.target.value);
	};
	return (
		<Box
			sx={{
				width: '100%',
				padding: '24px',
				display: 'flex',
				flexDirection: 'column',
				gap: '10px',
				fontFamily: 'IBM Plex Sans'
			}}
		>
			{loading ? (
				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
					<CircularProgress />
				</Box>
			) : (
				<>
					<Paper sx={{ width: '100%', padding: 2 }}>
						<Tabs
							value={selectedScenarioId}
							onChange={handleTabChange}
							variant="scrollable"
							scrollButtons="auto"
							aria-label="Scenario Tabs"
							textColor="primary"
							indicatorColor="primary"
						>
							{allScenario?.map(item => (
								<Tab
									key={item.scenario_id}
									label={item.scenario_name}
									value={item.scenario_id}
									sx={{
										textTransform: 'none',
										fontWeight: selectedScenarioId === item.scenario_id ? 'bold' : 'normal',
										color: selectedScenarioId === item.scenario_id ? '#1976d2' : 'inherit'
									}}
								/>
							))}
						</Tabs>
					</Paper>
					<Box
						display="flex"
						alignItems="center"
						gap={2}
						sx={{
							backgroundColor: 'background.paper',
							padding: 2,
							borderRadius: 1,
							boxShadow: 1,
							flexWrap: 'wrap' // Ensures responsiveness on smaller screens
						}}
					>
						<TextField
							name="search"
							placeholder="Search by scenario"
							value={search}
							onChange={inputHandler}
							size="small"
							variant="outlined"
							sx={{
								flex: '1 1 auto', // Allows flexibility
								maxWidth: '300px', // Reduced width
								width: '100%', // Fills available space
								borderRadius: '8px'
							}}
						/>
					</Box>
					<TableContainer component={Paper} sx={{ borderRadius: '8px' }}>
						<Table sx={{ minWidth: 650 }} aria-label="data table">
							<TableHead
								sx={{
									background: '#CFE9FD',
									border: '1px solid rgba(224, 224, 224, 1)'
								}}
							>
								<TableRow>
									<TableCell>S.No</TableCell>
									<TableCell>Date</TableCell>
									<TableCell>Score</TableCell>
									<TableCell>Scenario</TableCell> {/* New Column */}
								</TableRow>
							</TableHead>
							<TableBody>
								{data.map((row, index) => (
									<TableRow key={index}>
										<TableCell align="left">{page * rowsPerPage + index + 1}</TableCell>
										<TableCell align="left">{new Date(row.fileDate).toLocaleDateString()}</TableCell>
										<TableCell align="left">{row.score}</TableCell>
										<TableCell align="left">{row?.scenario?.scenario_name}</TableCell> {/* Display Scenario Name */}
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={total}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</>
			)}
		</Box>
	);
};

export default ConversationEvaluation;
