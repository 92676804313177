import React, { useState, useEffect, useRef, useContext } from 'react';
import StreamingAvatar, { AvatarQuality, StreamingEvents } from '@heygen/streaming-avatar';
import { ChatContext } from '../../Contexts';
import { IValueHandler } from '../../types/valueHandler';
import VideocamIcon from '@mui/icons-material/Videocam';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { getToken } from '../../Helper/GetToken';
import { useSelector } from 'react-redux';

interface AvatarProps {
	prompt?: string;
	onTimerUpdate: (newDuration: number) => void;
	valueHandler: (data: IValueHandler) => void;
	createNewConversation?: () => Promise<boolean>;
	setIsMessageAddedTofalse?: (userMessage: string, assistantMessage: string, isTheLastMessage: boolean) => void;
	setOpenAlert: (data: OpenAlertData) => void;
}

interface OpenAlertData {
	open: boolean;
	type?: 'success' | 'error' | 'warning';
	message?: string;
}

const Avatar: React.FC<AvatarProps> = ({
	prompt,
	onTimerUpdate,
	valueHandler,
	createNewConversation,
	setIsMessageAddedTofalse,
	setOpenAlert
}) => {
	const [avatar, setAvatar] = useState<StreamingAvatar | null>(null);
	// const videoElement = useRef<HTMLVideoElement>(null);
	const [isRecording, setIsRecording] = useState<boolean>();
	const [newFormattedTime, setNewFormattedTime] = useState<string>('00:00');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { connection, changeConnection, setMessageType, setIsMessageAddedToDb, videoElement, setCoachResponce } =
		useContext(ChatContext);
	const { avatar_id } = useSelector((state: any) => state?.selectedSimulation?.selectedSimulation);

	const sessionStartTime = useRef<number | null>(null);
	const timerInterval = useRef<NodeJS.Timeout | null>(null);
	const userMessageRef = useRef<string>('');
	const coachMessageRef = useRef<string>('');

	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const authToken = getToken();

	// Helper function to fetch access token
	const fetchAccessToken = async (): Promise<string> => {
		try {
			const response = await axios.get(`${baseUrl}/avatar/get-token`, {
				headers: {
					Authorization: authToken
				}
			});
			const { token } = response.data;
			return token;
		} catch (error) {
			console.error('Error fetching access token:', error);
			throw error;
		}
	};

	// Start avatar session
	const startAvatarSession = async () => {
		try {
			setIsLoading(true);
			setMessageType('audio');
			let result = false;
			if (createNewConversation) {
				result = await createNewConversation();
			}

			if (result) {
				const token = await fetchAccessToken();
				const avatarInstance = new StreamingAvatar({ token });
				setAvatar(avatarInstance);
				changeConnection({ isConnected: true, mode: 'video', status: 'started' });

				const sessionData = await avatarInstance.createStartAvatar({
					quality: AvatarQuality.High,
					avatarName: avatar_id ? avatar_id : 'Shawn_Therapist_public',
					...(prompt ? { knowledgeBase: prompt } : { knowledgeId: '4bbd96a4a1f343ef8afb9974d727bccf' })
				});

				// Set up event listeners
				avatarInstance.on(StreamingEvents.STREAM_READY, handleStreamReady);
				avatarInstance.on(StreamingEvents.AVATAR_TALKING_MESSAGE, handleMessage);
				avatarInstance.on(StreamingEvents.USER_TALKING_MESSAGE, handleMessage);
				avatarInstance.on(StreamingEvents.STREAM_DISCONNECTED, handleStreamDisconnected);
				avatarInstance.on(StreamingEvents.USER_START, handleUserStartTalking);

				changeConnection({ isConnected: true, mode: 'video', status: 'connected' });
				await avatarInstance.startVoiceChat({ useSilencePrompt: false });
				setIsLoading(false);
				sessionStartTime.current = Date.now();
				startTimer();
				setIsRecording(true);
			}
		} catch (error) {
			setIsLoading(false);
			changeConnection({ isConnected: false, status: 'closed' });
			setIsRecording(false);
			console.error('Error starting avatar session:', error);
			setOpenAlert({
				open: true,
				message: 'Error starting avatar session',
				type: 'error'
			});
		}
	};

	// Stop avatar session
	const stopAvatarSession = async () => {
		try {
			if (avatar) {
				if (setIsMessageAddedTofalse) {
					setIsMessageAddedTofalse(userMessageRef.current, coachMessageRef.current, true);
					setIsMessageAddedToDb(false);
					userMessageRef.current = '';
					coachMessageRef.current = '';
					setCoachResponce('');
				}
				setIsLoading(false);
				changeConnection({ isConnected: false, status: 'closed' });
				setIsRecording(false);
				setNewFormattedTime('00:00');
				sessionStartTime.current = null;
				stopTimer();
				await avatar.stopAvatar();
				setAvatar(null);
				setMessageType('text');
			}
		} catch (error) {
			setIsLoading(false);
			setMessageType('text');
			changeConnection({ isConnected: false, status: 'closed' });
			setIsRecording(false);
			setNewFormattedTime('00:00');
			console.error('Error stopping avatar session:', error);
		}
	};

	const startTimer = () => {
		stopTimer();

		if (sessionStartTime.current) {
			const elapsedTime = Date.now() - sessionStartTime.current;
			updateTimer(elapsedTime);
		}

		timerInterval.current = setInterval(() => {
			if (sessionStartTime.current) {
				const elapsedTime = Date.now() - sessionStartTime.current;
				updateTimer(elapsedTime);
			}
		}, 1000);
	};

	const stopTimer = () => {
		if (timerInterval.current) {
			clearInterval(timerInterval.current);
			timerInterval.current = null;
		}
	};

	const updateTimer = (ms: number) => {
		const totalSeconds = Math.floor(ms / 1000);
		setNewFormattedTime(formatTime(ms));
		onTimerUpdate(totalSeconds); // Notify parent component with updated duration
	};

	const formatTime = (ms: number): string => {
		const totalSeconds = Math.floor(ms / 1000);
		const minutes = Math.floor(totalSeconds / 60);
		const seconds = totalSeconds % 60;
		return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
	};

	// Handle when avatar stream is ready
	const handleStreamReady = (event: any) => {
		try {
			if (event.detail && videoElement && videoElement.current) {
				const stream = event.detail;
				videoElement.current.srcObject = stream;

				const audioTracks = stream.getAudioTracks();
				if (audioTracks.length > 0) {
					console.log('Audio track found:', audioTracks);
				} else {
					console.warn('No audio track found in the stream');
				}

				videoElement.current.muted = false;
				videoElement.current.onloadedmetadata = () => {
					videoElement.current
						?.play()
						.then(() => console.log('Video is playing'))
						.catch(error => console.error('Error playing video:', error));
				};
			} else {
				console.error('Stream is not available');
			}
		} catch (error) {
			setIsLoading(false);
			setMessageType('text');
			console.error('Handle stream error:', error);
		}
	};

	const handleMessage = (event: any) => {
		if (event.type === 'avatar_talking_message') {
			coachMessageRef.current += event.detail.message;
			setCoachResponce((prevMessage: string) => (prevMessage += event.detail.message));
		} else {
			coachMessageRef.current = '';
			userMessageRef.current = event.detail.message;
		}
		valueHandler({
			message: event.detail.message,
			role: event.type === 'avatar_talking_message' ? 'coach' : 'client',
			mode: 'video'
		});
	};

	const handleUserStartTalking = (event: any) => {
		if (setIsMessageAddedTofalse) {
			setIsMessageAddedTofalse(userMessageRef.current, coachMessageRef.current, false);
			userMessageRef.current = '';
			coachMessageRef.current = '';
			setCoachResponce('');
		}
	};

	// Handle stream disconnection
	const handleStreamDisconnected = () => {
		try {
			if (videoElement && videoElement.current) {
				videoElement.current.srcObject = null;
			}
			setMessageType('text');
			changeConnection({ isConnected: false, status: 'closed' });
			stopTimer();
			setCoachResponce('');
		} catch (error) {
			setMessageType('text');
			changeConnection({ isConnected: false, status: 'closed' });
			console.error('Error stopping avatar session:', error);
		}
	};

	useEffect(() => {
		return () => {
			// Cleanup avatar on component unmount
			if (avatar) {
				avatar.stopAvatar();
				onTimerUpdate(0);
			}
			stopTimer();
		};
	}, [avatar]);

	return (
		<>
			<div>
				{isRecording ? <p className="micTitle-primary">{newFormattedTime}</p> : ''}
				<button
					className="btn circle-btn"
					disabled={connection.mode === 'audio' && connection.isConnected}
					onClick={!connection.isConnected ? startAvatarSession : stopAvatarSession}>
					<div className={`${isRecording ? 'micImage micAnimation' : 'micImage'}`}>
						{isLoading ? (
							<CircularProgress color="inherit" />
						) : (
							<VideocamIcon
								fontSize="large"
								sx={{ color: 'white' }}
								className={isRecording ? 'recordingAnimation' : ''}
							/>
						)}
					</div>
				</button>
			</div>
		</>
	);
};

export default Avatar;
