import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
	Box,
	Typography,
	Button,
	Card,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	CircularProgress,
	List,
	ListItem,
	ListItemText,
	Avatar,
	Divider,
	IconButton,
	Grid,
	LinearProgress,
	Slider,
	Snackbar,
	Alert,
	RadioGroup,
	FormControlLabel,
	Radio
} from '@mui/material';
import axios from 'axios';
import { getToken, useHandleToken } from '../../../Helper/GetToken';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import getUser from '../../../Helper/getUser';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplyIcon from '@mui/icons-material/Reply';
import MediaCarousel from '../../../Helper/MediaCarousel';
import CancelIcon from '@mui/icons-material/Cancel';
import DescriptionIcon from '@mui/icons-material/Description';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { fireStorage } from '../../../config/firebase';
import { getFileNameFromUrl } from '../../../Helper/encodeURI';
import AttachmentIcon from '@mui/icons-material/Attachment';
import TemplateEditor from '../Cohort/TemplateEditor';
import ReplyComponent from './ReplyComponent';

const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
const token = getToken();

interface DiscussionDetailsProps {
	id?: any;
}

const DiscussionDetails: React.FC<DiscussionDetailsProps> = ({ id }) => {
	const [discussion, setDiscussion] = useState<any>(null);
	const [open, setOpen] = useState(false);
	const [reply, setReply] = useState('');
	const [loading, setLoading] = useState(false);
	const [loadingMain, setLoadingMain] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [parentReplyId, setParentReplyId] = useState<string | null>(null); // State to track parent reply ID
	const user = getUser();
	const { unhandleToken } = useHandleToken();
	const [mediaOpen, setMediaOpen] = useState(false);
	const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
	const [replyMediaOpen, setReplyMediaOpen] = useState(false);
	const [videos, setVideos] = useState<(File | string)[]>([]);
	const [pictures, setPictures] = useState<(File | string)[]>([]);
	const [loadingForMedia, setLoadingForMedia] = useState(false);
	const [replychildMediaOpen, setReplychildMediaOpen] = useState(false);
	const [openAlert, setOpenAlert] = React.useState<{
		open: boolean;
		type?: 'error' | 'success' | 'warning' | undefined;
		message?: string;
	}>({
		open: false
	});

	const [notificationPreference, setNotificationPreference] = useState('immediately');

	const getDiscussions = async () => {
		try {
			setLoadingMain(true);
			const res = await axios.get(`${baseUrl}/api/admin/get-discussion-with-replies/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			});
			if (res.data.success) {
				setDiscussion(res?.data?.discussion);
				if (res?.data?.discussion?.notificationPreference) {
					setNotificationPreference(res?.data?.discussion?.notificationPreference);
				}
			}
			setLoadingMain(false);
		} catch (err) {
			console.error('Failed to fetch discussions:', err);
			setLoadingMain(false);
			unhandleToken(err);
		}
	};

	const updateNotificationPreference = async (preference: string) => {
		try {
			setNotificationPreference(preference);
			setLoadingMain(true);
			const res = await axios.put(
				`${baseUrl}/api/admin/update-discussion-notification-preference/${id}`,
				{ preference },
				{
					headers: {
						Authorization: `Bearer ${token}`
					}
				}
			);
			if (res.data.success) {
				setNotificationPreference(preference);
				// setDiscussion(res?.data?.discussion);
				setOpenAlert({
					open: true,
					type: 'success',
					message: 'Notification preference updated successfully for this Discussion.'
				});
			}
			setLoadingMain(false);
		} catch (err) {
			console.error('Failed to fetch discussions:', err);
			setLoadingMain(false);
			unhandleToken(err);
		}
	};

	useEffect(() => {
		if (id) {
			getDiscussions();
		}
	}, [id]);

	const handleReplyClick = (parentReplyId: string | null = null) => {
		setParentReplyId(parentReplyId); // Set parent reply ID
		setReply('');
		setPictures([]);
		setVideos([]);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setReply('');
		setError(null); // Reset error on close
		setParentReplyId(null); // Reset parent reply ID on close
	};

	const handleSubmit = async () => {
		setLoadingMain(true);
		const uploadFiles = async (files: any[], folder: string) => {
			return Promise.all(
				files.map(async file => {
					if (typeof file !== 'string') {
						const fileName = `${Date.now()}-${file?.name}`;
						const storageRef = ref(fireStorage, `${folder}/${fileName}`);
						const snapshot = await uploadBytes(storageRef, file);
						return getDownloadURL(snapshot.ref);
					}
					return file; // If already a URL, return as is
				})
			);
		};

		try {
			setLoadingForMedia(true);
			// Upload media files
			const [uploadedVideos, uploadedPictures] = await Promise.all([
				uploadFiles(videos, 'reply_videos'),
				uploadFiles(pictures, 'reply_picture')
			]);
			const payload = {
				discussion_id: discussion.id,
				reply,
				parent_reply_id: parentReplyId, // Include parent reply ID if replying to another reply
				media: [
					...uploadedVideos.map((url: any) => ({ type: 'video', url })),
					...uploadedPictures.map((url: any) => ({ type: 'picture', url }))
				]
			};

			setError(null);
			const res = await axios.post(`${baseUrl}/api/admin/discussion/reply`, payload, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			});

			if (res.data.success) {
				await getDiscussions();
				setReply('');
				setOpen(false);
			}
		} catch (e) {
			console.error('Error submitting reply:', e);
			setError('Failed to submit reply. Please try again.');
			unhandleToken(e);
		} finally {
			setLoadingMain(false);
			setLoadingForMedia(false);
		}
	};

	const handleDeleteReply = async (id: string) => {
		try {
			const res = await axios.delete(`${baseUrl}/api/admin/delete-discussion-reply/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			});
			if (res.data.success) {
				await getDiscussions();
			}
		} catch (err) {
			console.error('Failed to delete discussion:', err);
			unhandleToken(err);
		}
	};
	const handleSliderChange = (event: any, newValue: number | number[]) => {
		setCurrentMediaIndex(newValue as number);
	};
	const handleMediaOpen = () => {
		setMediaOpen(true);
	};

	const handleMediaClose = () => {
		setMediaOpen(false);
	};

	const handleReplyMediaOpen = () => {
		setReplyMediaOpen(true);
	};

	const handleReplyMediaClose = () => {
		setReplyMediaOpen(false);
	};

	const handleVideoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (!files) return;

		const allowedFormats = ['video/mp4', 'video/webm', 'video/ogg', 'video/mov', 'video/avi', 'video/mkv'];
		const newVideos = Array.from(files);

		// Filter valid video files
		const validVideos = newVideos.filter(file => allowedFormats.includes(file.type));

		if (validVideos.length === 0) {
			setOpenAlert({
				open: true,
				type: 'error',
				message: 'Invalid file format'
			});
			e.target.value = ''; // Reset input field
			return;
		}

		setVideos(prevVideos => [...prevVideos, ...validVideos]);
		e.target.value = '';
	};

	const handleVideoDelete = (index: number) => {
		setVideos(prevVideos => prevVideos.filter((_, i) => i !== index));
	};

	const handlePictureChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (!files) return;

		const allowedFormats = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/jpg'];
		const newPictures = Array.from(files);

		// Filter valid image files
		const validPictures = newPictures.filter(file => allowedFormats.includes(file.type));

		if (validPictures.length === 0) {
			setOpenAlert({
				open: true,
				type: 'error',
				message: 'Invalid file format'
			});
			e.target.value = ''; // Reset input field
			return;
		}

		setPictures(prevPictures => [...prevPictures, ...validPictures]);

		// Reset input field after setting the file
		e.target.value = '';
	};
	const handlePictureDelete = (index: number) => {
		setPictures(prevVideos => prevVideos.filter((_, i) => i !== index));
	};

	const handleAlertClose = (event?: SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenAlert({ open: false, message: '', type: openAlert?.type });
	};
	return loadingMain ? (
		<LinearProgress />
	) : (
		<Box sx={{ padding: 2 }}>
			<Link
				style={{
					color: '#1F72C5',
					textDecoration: 'none'
				}}
				to="/discussions"
			>
				<Typography
					sx={{
						fontSize: '16pt',
						color: '#1F72C5',
						textAlign: 'left'
					}}
				>
					<ArrowBackIcon sx={{ mr: 2 }} />
					Back to discussion dashboard
				</Typography>
			</Link>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={openAlert?.open}
				autoHideDuration={6000}
				onClose={handleAlertClose}
			>
				<Alert onClose={handleAlertClose} severity={openAlert?.type} variant="filled" sx={{ width: '100%' }}>
					{openAlert?.message}
				</Alert>
			</Snackbar>
			{discussion && (
				<Card sx={{ margin: 'auto', border: '2px solid #65A3E2', borderRadius: 2, mt: 2 }}>
					<CardContent>
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
							<Typography variant="subtitle2" color="text.secondary">
								Available from {moment(discussion.from).format('DD MMM, YY hh:mm A')} until{' '}
								{moment(discussion.until).format('DD MMM, YY hh:mm A')}
							</Typography>
							<Typography variant="subtitle2" color="text.secondary">
								Opened By{' '}
								{discussion?.openedBy?.firebase_id === user?.id
									? 'You'
									: `${discussion?.openedBy?.first_name} ${discussion?.openedBy?.last_name}`}
							</Typography>
						</div>
						<Typography variant="h5" sx={{ mt: 1, textTransform: 'capitalize' }}>
							{discussion.title || 'Module Title'}
						</Typography>
						{/* //media */}
						<Box
							sx={{
								display: 'flex',
								gap: '20px',
								flexDirection: 'row',
								justifyContent: 'start',
								alignItems: 'center',
								overflow: 'hidden',
								borderRadius: '8px',
								marginTop: '20px'
								// backgroundColor: '#f5f5f5'
							}}
						>
							{discussion.media?.length > 0 &&
								discussion.media.map(
									(item: { type: string; url: string | undefined }, index: React.Key | null | undefined) => (
										<Box
											key={index}
											sx={{
												width: '250px',
												height: '350px', // Slightly taller for better visibility
												minWidth: '250px',
												minHeight: '300px',

												// display: 'flex',
												// justifyContent: 'center',
												// alignItems: 'center',
												// overflow: 'hidden',
												borderRadius: '8px'
												// backgroundColor: '#fff'
											}}
										>
											{item.type === 'picture' ? (
												<img
													src={item.url}
													alt={`Media ${index}`}
													style={{
														width: '100%',
														height: '100%',
														objectFit: 'contain',
														aspectRatio: '3 / 4', // Ensures natural aspect ratio
														borderRadius: '4px'
													}}
												/>
											) : (
												<video
													src={item.url}
													controls
													style={{
														width: '100%',
														height: 'auto', // Dynamically adjust height
														aspectRatio: '3 / 4', // Maintain tall aspect ratio
														objectFit: 'contain', // Ensures full video visibility
														borderRadius: '4px'
													}}
												/>
											)}
										</Box>
									)
								)}
						</Box>

						{/* ///// */}
						<Typography
							variant="body2"
							sx={{ mt: 1, width: '100%', overflow: 'auto' }}
							dangerouslySetInnerHTML={{ __html: discussion.description || 'Description goes here' }}
						/>

						{discussion?.selectedSubGroup?.name && user?.role !== 'client' && (
							<Typography variant="body2" sx={{ mt: 1, width: '100%', overflow: 'auto' }}>
								Discussion for Group <b>{discussion?.selectedSubGroup?.name || ''}</b>
							</Typography>
						)}

						{/* Attachment button */}
						{/* {discussion.media?.length > 0 && (
							<Box>

								<IconButton
									color="primary"
									onClick={handleMediaOpen}
									sx={{
										textTransform: 'none', // Not applicable for icons but kept for consistency
										padding: '4px', // Minimal padding for the icon button
										borderRadius: '4px', // Slightly rounded corners
										fontWeight: '500', // Not directly applicable to icons
										fontSize: '0.875rem', // Font size applicable to any accompanying label
										'&:hover': {
											backgroundColor: 'rgba(0, 0, 255, 0.1)' // Subtle hover effect
										}
									}}
								>
									<AttachmentIcon fontSize="medium" />
								</IconButton>


								<MediaCarousel open={mediaOpen} media={discussion.media} onClose={handleMediaClose} />
							</Box>
						)} */}
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
							<Button
								disabled={discussion?.isActive === false}
								variant="contained"
								sx={{ mt: 2, textTransform: 'none' }}
								color="primary"
								onClick={() => handleReplyClick()}
							>
								Reply
							</Button>
							<Typography variant="body2" sx={{ mt: 2, textAlign: 'right', width: '20%' }}>
								{discussion?.totalReplies || 0} Replies
							</Typography>
						</div>
						{discussion?.selectedCohort?.discussion_notification_settings_enabled && user?.role !== 'admin' && (
							<>
								<Typography sx={{ mt: 4 }}>Notification Frequency For this discussion</Typography>
								<RadioGroup
									row
									value={notificationPreference}
									onChange={e => {
										updateNotificationPreference(e.target.value);
									}}
								>
									<FormControlLabel value="immediately" control={<Radio />} label="Immediately" />
									<FormControlLabel value="daily" control={<Radio />} label="Daily" />
									<FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
									<FormControlLabel value="off" control={<Radio />} label="Off" />
								</RadioGroup>
							</>
						)}
					</CardContent>
				</Card>
			)}

			{/* Replies Section */}
			{discussion?.replies?.length > 0 && (
				<div>
					<List>
						{discussion.replies.map((reply: any, index: number) => (
							<ReplyComponent
								key={index}
								reply={reply}
								user={user}
								discussion={discussion}
								handleReplyClick={handleReplyClick}
								handleDeleteReply={handleDeleteReply}
							/>
						))}
					</List>
				</div>
			)}

			{/* Reply Dialog */}
			<Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
				<DialogTitle>Submit your Reply</DialogTitle>
				<DialogContent>
					{parentReplyId ? (
						<TextField
							autoFocus
							margin="dense"
							id="reply"
							label="Your Reply"
							type="text"
							fullWidth
							multiline
							rows={4}
							variant="filled"
							value={reply}
							onChange={e => setReply(e.target.value)}
						/>
					) : (
						<TemplateEditor style={{ height: '300px' }} value={reply} setValue={setReply} />
					)}

					{error && (
						<Typography color="error" sx={{ mt: 1 }}>
							{error}
						</Typography>
					)}
					{/* media picture */}
					<Box>
						<Typography variant="body1" sx={{ fontWeight: 600, marginY: 1, marginTop: 6 }}>
							Pictures
						</Typography>
						{loadingForMedia ? (
							<LinearProgress />
						) : (
							<>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'row',
										gap: '10px',
										flexWrap: 'wrap',
										width: '100%'
									}}
								>
									{pictures.map((picture, index) => (
										<Box key={index} display={'flex'} gap={1}>
											{(typeof picture === 'string' && picture?.includes('reply_picture')) ||
											(typeof picture !== 'string' && picture?.type?.includes('image')) ? (
												<img
													alt="Uploaded Preview"
													style={{ maxWidth: '300px', maxHeight: '300px', objectFit: 'cover' }}
													src={typeof picture === 'string' ? picture : URL.createObjectURL(picture)}
												/>
											) : (
												<div
													onClick={() => {
														if (typeof picture === 'string') {
															window.open(picture, '_blank');
														}
													}}
													style={{
														display: 'flex',
														alignItems: 'center',
														gap: 1,
														cursor: typeof picture === 'string' ? 'pointer' : 'auto'
													}}
												>
													<DescriptionIcon color="primary" fontSize="large" />
													<p>
														{typeof picture !== 'string' ? picture?.name && picture?.name : getFileNameFromUrl(picture)}
													</p>
												</div>
											)}
											<CancelIcon onClick={() => handlePictureDelete(index)} sx={{ color: 'red', cursor: 'pointer' }} />
										</Box>
									))}
								</Box>
								<input
									style={{
										margin: '16px 0'
									}}
									accept="image/*"
									id="contained-button-files"
									type="file"
									multiple
									aria-label="mediaPicture"
									onChange={handlePictureChange}
								/>
							</>
						)}
					</Box>

					{/* //Reply media video */}
					<Box>
						<Typography variant="body1" sx={{ fontWeight: 600, marginY: 1, marginTop: 2 }}>
							Video
						</Typography>
						{loadingForMedia ? (
							<LinearProgress />
						) : (
							<>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'row',
										gap: '10px',
										flexWrap: 'wrap',
										width: '100%'
									}}
								>
									{videos.map((video, index) => (
										<Box key={index} display={'flex'} gap={1}>
											{(typeof video === 'string' && video?.includes('reply_videos')) ||
											(typeof video !== 'string' && video?.type?.includes('video')) ? (
												<video
													controls
													style={{ maxWidth: '300px' }}
													src={typeof video === 'string' ? video : URL.createObjectURL(video)}
												>
													Your browser does not support to load the video.
												</video>
											) : (
												<div
													onClick={() => {
														if (typeof video === 'string') {
															window.open(video, '_blank');
														}
													}}
													style={{
														display: 'flex',
														alignItems: 'center',
														gap: 1,
														cursor: typeof video === 'string' ? 'pointer' : 'auto'
													}}
												>
													<DescriptionIcon color="primary" fontSize="large" />
													<p>{typeof video !== 'string' ? video?.name && video?.name : getFileNameFromUrl(video)}</p>
												</div>
											)}
											<CancelIcon onClick={() => handleVideoDelete(index)} sx={{ color: 'red', cursor: 'pointer' }} />
										</Box>
									))}
								</Box>
								<input
									style={{
										margin: '16px 0'
									}}
									accept="video/*"
									id="contained-button-file"
									type="file"
									// value={videoFile}
									multiple
									aria-label="mediaVideo"
									onChange={handleVideoChange}
								/>
							</>
						)}
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						Cancel
					</Button>
					<Button onClick={handleSubmit} color="primary" variant="contained" disabled={loading || !reply.trim()}>
						{loading ? <CircularProgress size={24} /> : 'Submit'}
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default DiscussionDetails;
