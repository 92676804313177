import React from 'react';
import { Avatar, Button, Grid, List, ListItem, ListItemText, Typography, Box, Divider } from '@mui/material';
import moment from 'moment';
import ReplyIcon from '@mui/icons-material/Reply';
import DeleteIcon from '@mui/icons-material/Delete';

interface ReplyProps {
	reply: any;
	user: any;
	discussion: any;
	handleReplyClick: (id: string) => void;
	handleDeleteReply: (id: string) => void;
}

const ReplyComponent: React.FC<ReplyProps> = ({ reply, user, discussion, handleReplyClick, handleDeleteReply }) => {
	return (
		<>
			<ListItem alignItems="flex-start" sx={{ padding: '20px 0' }}>
				<Grid container spacing={2}>
					<Grid item>
						<Avatar
							sx={{ width: 48, height: 48, mt: 1 }}
							alt={user?.id === reply?.user?.firebase_id ? 'You' : `${reply.user.first_name} ${reply.user.last_name}`}
							src="path/to/avatar"
						/>
					</Grid>
					<Grid item xs>
						<ListItemText
							primary={
								<Box>
									<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
										{user?.id === reply?.user?.firebase_id ? 'You' : `${reply.user.first_name} ${reply.user.last_name}`}
									</Typography>
									<Typography variant="body2" color="text.secondary">
										{moment(reply.createdAt).format('MMM D, h:mm A')}
									</Typography>
								</Box>
							}
							secondary={
								<Box>
									<Box
										sx={{
											display: 'flex',
											gap: '20px',
											flexDirection: 'row',
											overflow: 'hidden',
											borderRadius: '8px',
											marginTop: '20px',
											maxWidth: '450px',
											flexWrap: 'wrap',
											justifyContent: 'flex-start',
											alignItems: 'flex-start'
										}}
									>
										{reply.media?.length > 0 &&
											reply.media.map(
												(item: { type: string; url: string | undefined }, index: React.Key | null | undefined) => (
													<Box
														key={index}
														sx={{
															flexGrow: 1, // Allows previous items to expand if space is available
															minWidth: '150px', // Minimum width per media item
															maxWidth: '200px', // Prevents excessive stretching
															height: 'auto',
															borderRadius: '8px',
															overflow: 'hidden',
															display: 'flex',
															justifyContent: 'center',
															alignItems: 'center'
															// backgroundColor: '#fff'
														}}
													>
														{item.type === 'picture' ? (
															<img
																src={item.url}
																alt={`Media ${index}`}
																style={{
																	width: '100%',
																	height: 'auto',
																	aspectRatio: '4/3', // Ensures natural aspect ratio
																	objectFit: 'contain',
																	borderRadius: '4px'
																}}
															/>
														) : (
															<video
																src={item.url}
																controls
																style={{
																	width: '100%',
																	height: 'auto', // Adjusts height dynamically
																	aspectRatio: '3 / 4', // Maintains portrait aspect ratio
																	objectFit: 'cover', // Fills box while maintaining aspect ratio
																	borderRadius: '4px'
																}}
															/>
														)}
													</Box>
												)
											)}
									</Box>
									<Typography
										variant="body2"
										color="text.primary"
										sx={{ marginTop: 1 }}
										dangerouslySetInnerHTML={{ __html: reply.reply }}
									/>
								</Box>
							}
						/>
						{/* {reply.media && Array.isArray(reply.media) && reply.media.length > 0 && (
												<Box>

													<IconButton
														color="primary"
														onClick={handleReplyMediaOpen}
														sx={{
															textTransform: 'none', // Not applicable for icons but kept for consistency
															padding: '4px', // Minimal padding for the icon button
															borderRadius: '4px', // Slightly rounded corners
															fontWeight: '500', // Not directly applicable to icons
															fontSize: '0.875rem', // Font size applicable to any accompanying label
															'&:hover': {
																backgroundColor: 'rgba(0, 0, 255, 0.1)' // Subtle hover effect
															}
														}}
													>
														<AttachmentIcon fontSize="medium" />
													</IconButton>


													<MediaCarousel open={replyMediaOpen} media={reply.media} onClose={handleReplyMediaClose} />
												</Box>
											)} */}

						<Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
							<Button
								disabled={discussion?.isActive === false}
								size="small"
								color="primary"
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'flex-end',
									gap: 0.5,
									textTransform: 'none'
								}}
								onClick={() => handleReplyClick(reply.id)} // Pass parent reply ID when replying to a reply
							>
								<ReplyIcon />
								<div style={{ fontSize: '14px' }}>Reply</div>
							</Button>
						</Box>
					</Grid>
					{(user?.role === 'admin' || user?.role === 'coach' || user?.id === reply?.user?.firebase_id) && (
						<Grid item sx={{ mt: 1 }}>
							<DeleteIcon onClick={() => handleDeleteReply(reply.id)} sx={{ cursor: 'pointer' }} />
						</Grid>
					)}
				</Grid>
			</ListItem>
			{!reply?.parentReply && <Divider />}

			{reply?.children?.length > 0 && (
				<List sx={{ paddingLeft: 8 }}>
					{reply.children.map((childReply: any, childIndex: any) => (
						<ReplyComponent
							key={childReply.id}
							reply={childReply}
							user={user}
							discussion={discussion}
							handleReplyClick={handleReplyClick}
							handleDeleteReply={handleDeleteReply}
						/>
					))}
				</List>
			)}
		</>
	);
};

export default ReplyComponent;
