import React, { ChangeEvent, SyntheticEvent } from 'react';
import { Box, Button, Typography, Paper, Snackbar, Alert, CircularProgress } from '@mui/material';
import UploadIcon from '../../Assets/Images/uploadIcon.svg';

import './AnalyzeMeetings.css';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router-dom';

interface UploadUIProps {
	file: File | null;
	setFile: React.Dispatch<React.SetStateAction<File | null>>;
	handleUploadStart: () => void;
	setStep: React.Dispatch<React.SetStateAction<string>>;
	loading: boolean;
}

const MAX_UPLOAD_SIZE_IN_MB_VIDEO = 200; //200mb
const MAX_UPLOAD_SIZE_IN_MB_AUDIO = 24; // 24mb
const MAX_VIDEO_DURATION_SEC = Number(process.env.REACT_APP_MEETING_VIDEO_TIME_LIMIT) || 10; //default 10min
const styles = {
	MainBox: {
		marginTop: '32px'
	},
	Box: {
		padding: '32px',
		maxWidth: 550,
		margin: 'auto',
		borderRadius: '16px'
	},
	Header: {
		fontSize: '24px',
		fontWeight: '700',
		mb: '18px'
	},
	FileFooter: {
		mt: '4px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	Button: {
		height: '50px',
		borderRadius: '100px',
		p: '4px 20px',
		fontSize: '18px',
		fontWeight: 500,
		width: '100%',
		textTransform: 'capitalize',
		'@media (max-width: 1600px)': {
			fontSize: '15px'
		}
	}
};

const UploadUI: React.FC<UploadUIProps> = ({ file, setFile, handleUploadStart, setStep, loading }) => {
	const navigate = useNavigate();

	const [openAlert, setOpenAlert] = React.useState<{
		open: boolean;
		type?: 'error' | 'success' | 'warning' | undefined;
		message?: string;
	}>({
		open: false
	});

	// Helper function to check video duration
	const checkVideoDuration = (file: File, element: 'video' | 'audio'): Promise<boolean> => {
		return new Promise((resolve, reject) => {
			const url = URL.createObjectURL(file);
			const videoElement = document.createElement(element);
			videoElement.preload = 'metadata';
			videoElement.src = url;
			videoElement.onloadedmetadata = () => {
				URL.revokeObjectURL(url);
				// console.log(videoElement.duration / 60);

				// Check if duration is within the 10 minute limit
				if (videoElement.duration / 60 > MAX_VIDEO_DURATION_SEC) {
					resolve(false);
				} else {
					resolve(true);
				}
			};
			videoElement.onerror = () => {
				URL.revokeObjectURL(url);
				reject(new Error('Error loading video metadata'));
			};
		});
	};
	const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenAlert({ open: false, message: '', type: openAlert?.type });
	};

	const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		const files = e.dataTransfer.files;
		if (files?.length) {
			const file = files[0];
			if (!file?.type?.includes('audio') && !file?.type?.includes('text') && !file?.type?.includes('video')) {
				setOpenAlert({
					open: true,
					type: 'error',
					message: 'Invalid file format'
				});
			} else if (file?.type?.includes('video') && file.size > MAX_UPLOAD_SIZE_IN_MB_VIDEO * 1024 * 1024) {
				setOpenAlert({
					open: true,
					type: 'error',
					message: `File size exceeds the ${MAX_UPLOAD_SIZE_IN_MB_VIDEO}MB limit`
				});
			} else if (file?.type?.includes('audio') && file.size > MAX_UPLOAD_SIZE_IN_MB_AUDIO * 1024 * 1024) {
				setOpenAlert({
					open: true,
					type: 'error',
					message: `File size exceeds the ${MAX_UPLOAD_SIZE_IN_MB_AUDIO}MB limit`
				});
			} else if (file?.type?.includes('video')) {
				try {
					const isValidDuration = await checkVideoDuration(file, 'video');
					if (!isValidDuration) {
						setOpenAlert({
							open: true,
							type: 'error',
							message: `Video duration exceeds ${MAX_VIDEO_DURATION_SEC} minutes limit`
						});
						return;
					}
				} catch (err) {
					setOpenAlert({
						open: true,
						type: 'error',
						message: 'Error checking video duration'
					});
					return;
				}
				setFile(file);
			} else if (file?.type?.includes('audio')) {
				try {
					const isValidDuration = await checkVideoDuration(file, 'audio');
					if (!isValidDuration) {
						setOpenAlert({
							open: true,
							type: 'error',
							message: `Audio duration exceeds ${MAX_VIDEO_DURATION_SEC} minutes limit`
						});
						return;
					}
				} catch (err) {
					setOpenAlert({
						open: true,
						type: 'error',
						message: 'Error checking audio duration'
					});
					return;
				}
				setFile(file);
			} else {
				setFile(file);
			}
		}
	};

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
	};

	const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (files?.length) {
			const file = files[0];
			if (!file?.type?.includes('audio') && !file?.type?.includes('text') && !file?.type?.includes('video')) {
				setOpenAlert({
					open: true,
					type: 'error',
					message: 'Invalid file format'
				});
			} else if (file?.type?.includes('video') && file.size > MAX_UPLOAD_SIZE_IN_MB_VIDEO * 1024 * 1024) {
				setOpenAlert({
					open: true,
					type: 'error',
					message: `File size exceeds the ${MAX_UPLOAD_SIZE_IN_MB_VIDEO}MB limit`
				});
			} else if (file?.type?.includes('audio') && file.size > MAX_UPLOAD_SIZE_IN_MB_AUDIO * 1024 * 1024) {
				setOpenAlert({
					open: true,
					type: 'error',
					message: `File size exceeds the ${MAX_UPLOAD_SIZE_IN_MB_AUDIO}MB limit`
				});
			} else if (file?.type?.includes('video')) {
				try {
					const isValidDuration = await checkVideoDuration(file, 'video');
					if (!isValidDuration) {
						setOpenAlert({
							open: true,
							type: 'error',
							message: `Video duration exceeds ${MAX_VIDEO_DURATION_SEC} minutes limit`
						});
						return;
					}
				} catch (err) {
					setOpenAlert({
						open: true,
						type: 'error',
						message: 'Error checking video duration'
					});
					return;
				}
				setFile(file);
			} else if (file?.type?.includes('audio')) {
				try {
					const isValidDuration = await checkVideoDuration(file, 'audio');
					if (!isValidDuration) {
						setOpenAlert({
							open: true,
							type: 'error',
							message: `Audio duration exceeds ${MAX_VIDEO_DURATION_SEC} minutes limit`
						});
						return;
					}
				} catch (err) {
					setOpenAlert({
						open: true,
						type: 'error',
						message: 'Error checking audio duration'
					});
					return;
				}
				setFile(file);
			} else {
				setFile(file);
			}
		}
	};

	const handleBack = () => {
		setStep('landing');
		navigate('/analyze-meetings?tab=landing');
	};

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={openAlert?.open}
				autoHideDuration={6000}
				onClose={handleClose}
			>
				<Alert onClose={handleClose} severity={openAlert?.type} variant="filled" sx={{ width: '100%' }}>
					{openAlert?.message}
				</Alert>
			</Snackbar>
			<Box sx={{ opacity: loading ? 0.5 : 1 }}>
				<Box sx={{ ...styles.MainBox }}>
					<Paper elevation={3} style={{ ...styles.Box }}>
						<Typography sx={{ ...styles.Header }}>Upload new information</Typography>
						<Typography sx={{ fontWeight: 500, fontSize: '16px' }} variant="body2" gutterBottom>
							Files
						</Typography>
						<Typography variant="body2" color="#161616" gutterBottom>
							Supported file types: AUDIO, TEXT, VIDEO
						</Typography>

						<label htmlFor="raised-button-file" style={{ width: '100%' }}>
							<div className="upload-container" onDrop={handleDrop} onDragOver={handleDragOver}>
								<input
									disabled={loading}
									accept="audio/wav,audio/mpeg,audio/mp4,audio/ogg,audio/flac,text/plain,.vtt,video/mp4,video/ogg,video/webm"
									style={{ display: 'none' }}
									id="raised-button-file"
									multiple
									type="file"
									onChange={handleFileChange}
								/>
								<div>
									<Typography color="#161616">Drag and drop file here, or Browse</Typography>
									<Typography color="#7D89A1" sx={{ my: '10px' }} variant="body2">
										Maximum file size {MAX_UPLOAD_SIZE_IN_MB_VIDEO}MB for video.
									</Typography>
									<Typography color="#7D89A1" sx={{ my: '10px' }} variant="body2">
										Maximum file size {MAX_UPLOAD_SIZE_IN_MB_AUDIO}MB for audio.
									</Typography>
									<div className="icon-wrapper">
										<div className="icon-div">
											<img src={UploadIcon} alt="" />
										</div>
									</div>
								</div>
							</div>
						</label>

						{file?.name && (
							<div>
								<Typography variant="subtitle2" color={'gray'} sx={{ ...styles.FileFooter }} gutterBottom>
									{file?.name}
									<CancelIcon fontSize="small" sx={{ cursor: 'pointer' }} onClick={() => !loading && setFile(null)} />
								</Typography>
							</div>
						)}

						<div className="button-wrapper">
							<Button disabled={loading} variant="outlined" sx={{ ...styles.Button }} onClick={handleBack}>
								Back
							</Button>
							<Button
								disabled={!file || loading}
								variant="contained"
								sx={{ ...styles.Button }}
								onClick={handleUploadStart}
							>
								Start {loading ? <CircularProgress color="inherit" sx={{ marginLeft: '8px' }} size={'16px'} /> : ''}
							</Button>
						</div>
					</Paper>
				</Box>
			</Box>
		</>
	);
};

export default UploadUI;
