import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../config/firebase';
import getUser from '../../../Helper/getUser';
import FeedbackCard from '../../Feedback/FeedbackCard';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedCompletedSimulationMessages } from '../../../Helper/Redux/Action/userAction';
import { Rubrics } from '../../../types/rubrics';

interface ComponentProps {
	Line1: string;
	Line2: string;
}

const Component: React.FC<ComponentProps> = ({ Line1, Line2 }) => {
	return (
		<Box sx={styles.Para}>
			<Typography>{Line1}</Typography>
			<Typography>{Line2}</Typography>
		</Box>
	);
};

const DebriefHeader = ({ setActiveStep }: any) => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [simulation, setSimulation] = useState<any>();
	const [analysisFeedback, setAnalysisFeedback] = useState<any>();
	const [recommendations, setRecommendation] = useState<[]>([]);
	const [expanded, setExpanded] = useState(true);
	const user = getUser();
	const selectedUserId = useSelector((state: any) => state.selectedUser.userId);
	const { roleName } = useSelector((state: any) => state?.role);
	const tempUserId = selectedUserId ? selectedUserId : user?.id;

	const calculateNegativeScore = (score: number) => {
		const negativeScoreMap: Record<number, number> = { 0: 0, 1: 30, 2: 60, 3: 100 };
		return negativeScoreMap[score] ?? 0;
	};

	const getSimulation = async () => {
		const userDocRef = doc(db, 'simulation', tempUserId);
		const userDocSnap = await getDoc(userDocRef);
		const currentSimulation = userDocSnap
			?.data()
			?.conversations?.find((item: any) => item?.conversationId === searchParams?.get('simulationId'));

		// const recDocRef = doc(db, 'recommendation', tempUserId);
		// const recDocSnap = await getDoc(recDocRef);
		// const currentRecommendation = recDocSnap
		// 	?.data()
		// 	?.conversations?.find((item: any) => item?.conversationId === searchParams?.get('simulationId'));
		if (currentSimulation?.analyticsFeedback) {
			const array: any = [];
			const feedback = currentSimulation?.analyticsFeedback?.analytics;

			if (feedback) {
				Object.keys(feedback)?.map(item => {
					if (feedback[item]) {
						const isNegative = feedback[item]?.type === Rubrics.NEGATIVE;
						const scoreValue = isNegative
							? calculateNegativeScore(feedback[item]?.score)
							: (100 * (feedback[item]?.score + 1)) / 4;

						array?.push({
							name: item,
							description: feedback[item]?.reason,
							recommendation: feedback[item]?.recommendation,
							score: scoreValue,
							dimension: item,
							type: feedback[item]?.type || Rubrics.POSITIVE
						});
					}
				});

				setAnalysisFeedback(array.sort((a: any, b: any) => a.score - b.score));

				const positiveFeedback = array
					.filter((item: any) => item.type === Rubrics.POSITIVE)
					.sort((a: { score: number }, b: { score: number }) => a.score - b.score);

				const negativeFeedback = array
					.filter((item: any) => item.type === Rubrics.NEGATIVE)
					.sort((a: { score: number }, b: { score: number }) => a.score - b.score);

				setAnalysisFeedback({ positive: positiveFeedback, negative: negativeFeedback });

				// Get only the lowest two items based on score
				const lowestTwo = positiveFeedback.slice(0, 2);

				// Format the lowest two items as desired
				const sortedRecommendations = lowestTwo.map(
					(feedbackItem: { name: any; description: any; recommendation: any }) => ({
						name: feedbackItem.name,
						reason: feedbackItem.description,
						recommendation: feedbackItem.recommendation
					})
				);

				setRecommendation(sortedRecommendations);

				dispatch(setSelectedCompletedSimulationMessages(currentSimulation.messages, currentSimulation.conversationId));
				// setSelectedConversationId(currentSimulation.conversationId);
				setSimulation(currentSimulation);
			}
		} else {
			setSimulation(undefined);
		}
	};

	useEffect(() => {
		if (
			!searchParams?.get('simulationId') ||
			searchParams?.get('simulationId') === undefined ||
			searchParams?.get('simulationId') == 'undefined'
		) {
			navigate('/simulation', { state: { notDefault: false } });
			setActiveStep(2);
		} else {
			getSimulation();
		}
	}, []);

	const toggleExpand = () => {
		setExpanded(!expanded);
	};

	return (
		<>
			<Box sx={styles.Box}>
				<Typography sx={styles.Title}>Simulation Debrief</Typography>

				{simulation && roleName ? (
					<>
						<Typography sx={styles.Title}>Your simulation is with {roleName.role_name}</Typography>
						<Component Line1="Scenario:" Line2={roleName.description} />
						<Component Line1="Summary:" Line2={simulation?.analyticsFeedback?.description} />

						<Box sx={{ display: 'flex', flexDirection: 'column', margin: '20px 0' }}>
							{analysisFeedback?.positive?.length > 0 && (
								<>
									<Typography
										sx={{ fontFamily: 'IBM Plex Sans', fontSize: '15px', fontWeight: 600, lineHeight: '36px' }}>
										Things to Do More Of
									</Typography>
									<Box className="meeting-feedback-wrapper">
										{analysisFeedback.positive.map((analytics: any, key: number) => (
											<FeedbackCard key={`positive_${key}`} feedback={analytics} simulationId={simulation.scenarioId} />
										))}
									</Box>
								</>
							)}

							{analysisFeedback?.negative?.length > 0 && (
								<>
									<Typography
										sx={{ fontFamily: 'IBM Plex Sans', fontSize: '15px', fontWeight: 600, lineHeight: '36px' }}>
										Things to Do Less Of
									</Typography>
									<Box className="meeting-feedback-wrapper">
										{analysisFeedback.negative.map((analytics: any, key: number) => (
											<FeedbackCard key={`negative_${key}`} feedback={analytics} simulationId={simulation.scenarioId} />
										))}
									</Box>
								</>
							)}
						</Box>

						<div>
							<Accordion expanded={expanded} onChange={toggleExpand}>
								<AccordionSummary
									expandIcon={
										<IconButton>{expanded ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}</IconButton>
									}
									aria-controls="panel1-content"
									id="panel1-header">
									<Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Recommendations</Typography>
								</AccordionSummary>
								<AccordionDetails>
									{recommendations?.length > 0
										? recommendations.map((recommendation: any, key: number) => (
												<Accordion
													sx={{
														marginBottom: '10px',
														border: '1px solid #1C75BA',
														borderRadius: '8px'
													}}
													key={key}
													defaultExpanded={key === 0}>
													<AccordionSummary
														sx={{ margin: '0', textTransform: 'capitalize' }}
														expandIcon={<ExpandMoreIcon />}
														aria-controls="panel1-content"
														id="panel1-header">
														<Typography color={'#1C75BA'}>{recommendation?.name}</Typography>
													</AccordionSummary>
													<AccordionDetails>
														<Typography sx={{ fontSize: '14px' }}>{recommendation?.recommendation}</Typography>
													</AccordionDetails>
												</Accordion>
											))
										: 'There is no recommendations for this meeting.'}
								</AccordionDetails>
							</Accordion>
						</div>
					</>
				) : (
					''
				)}
			</Box>
		</>
	);
};

const styles = {
	Box: {},
	Title: {
		color: '#161616',
		fontFamily: 'IBM Plex Sans',
		fontSize: '24px',
		fontWeight: 600,
		lineHeight: '36px',
		pt: '15px',
		'@media (max-width: 1600px)': {
			fontSize: '22px',
			lineHeight: 'normal'
		}
	},
	Para: {
		color: '#161616',
		fontFamily: 'Outfit',
		fontSize: '16px',
		fontWeight: 400,
		lineHeight: '25px',
		pt: '15px  ',
		'@media (max-width: 1600px)': {
			fontSize: '15px',
			lineHeight: 'normal'
		}
	}
};
export default DebriefHeader;
