import { Grid } from '@mui/material';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import React, { useMemo, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { fireStorage } from '../../../config/firebase';
// import '../../assets/css/richTextEditor.css';

interface TemplateEditorProps {
	value: string;
	setValue: (value: string) => void;
	style?: any;
}

const TemplateEditor: React.FC<TemplateEditorProps> = ({
	value,
	setValue,
	style = { height: 'calc(100vh - 24rem)' }
}) => {
	const quillRef = useRef<ReactQuill | null>(null);

	const imageHandler = () => {
		const editor = quillRef.current?.getEditor();
		if (!editor) return;

		const input = document.createElement('input');
		input.setAttribute('type', 'file');
		input.setAttribute('accept', 'image/jpeg, image/jpg, image/png, image/gif');
		input.click();

		input.onchange = async () => {
			const file = input.files?.[0];

			if (file && /^image\/(jpeg|jpg|png|gif)$/.test(file.type)) {
				try {
					const fileName = `${new Date().getTime()}-${file?.name}`;
					const storageRef = await ref(fireStorage, `template_images/${fileName}`);
					const snapshot = await uploadBytes(storageRef, file);
					const downloadURL = await getDownloadURL(snapshot.ref);
					editor.insertEmbed(editor.getSelection()?.index || 0, 'image', downloadURL);

					// const reader = new FileReader();
					// reader.readAsDataURL(file);
					// reader.onloadend = () => {
					// 	const base64data = reader.result;

					// 	if (base64data) {
					//     console.log(base64data)
					// 		editor.insertEmbed(editor.getSelection()?.index || 0, 'image', base64data);
					// 	}
					// };
				} catch (error) {
					console.log('Failed to upload image.');
				}
			} else {
				console.log('You can only upload images.');
			}
		};
	};

	const modules = useMemo(
		() => ({
			toolbar: {
				container: [
					[{ header: [1, 2, 3, 4, 5, 6, false] }],
					['bold', 'italic', 'underline', 'strike'],
					[{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
					[{ align: [] }, 'link'],
					[{ color: [] }, { background: [] }],
					[{ script: 'sub' }, { script: 'super' }],
					[{ font: [] }],
					['clean'],
					['image']
				],
				handlers: {
					image: imageHandler
				}
			}
		}),
		[]
	);

	return <ReactQuill style={style} theme="snow" ref={quillRef} value={value} modules={modules} onChange={setValue} />;
};

export default TemplateEditor;
