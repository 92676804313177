import React, { useEffect, useState } from 'react';

import { IFeedbackProps } from '../../types';
import { Box, Button, Tooltip, Zoom, TooltipProps, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import QuestionSymbol from '../../Assets/Images/QuestionSymbol';
import axios from 'axios';
import { getToken, useHandleToken } from '../../Helper/GetToken';
import { Rubrics } from '../../types/rubrics';

// Renders a feedback card with progress bar and tooltip
const FeedbackCard: React.FC<IFeedbackProps> = ({ feedback, simulationId, selectedCategory }) => {
	// To handle the tooltip visibility on hovering the feedback card
	const [showTooltip, setShowTooltip] = useState<boolean>(false);
	const [hoverShow, setHoverShow] = useState<boolean>(false);
	const [sumilationRubrics, setSumilationRubrics] = useState<any>(null);
	const [tooltipText, setTooltipText] = useState('');
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const { unhandleToken } = useHandleToken();
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			getPrompts();
		}, 100);

		return () => clearTimeout(timeoutId);
	}, []);

	const getPrompts = async () => {
		if (simulationId)
			await axios
				.get(`${baseUrl}/api/scenario/getPrompt/${simulationId}`, {
					headers: {
						Authorization: token
					}
				})
				.then(res => {
					setSumilationRubrics(res?.data?.data);
				})
				.catch(e => {
					console.log(e);
					unhandleToken(e);
				});

		if (selectedCategory)
			await axios
				.get(`${baseUrl}/api/scenario/getPrompt/${selectedCategory}`, {
					headers: {
						Authorization: token
					}
				})
				.then(res => {
					setSumilationRubrics(res?.data?.data);
				})
				.catch(e => {
					console.log(e);
					unhandleToken(e);
				});
	};

	const handleMouseLeave = () => {
		setShowTooltip(false);
	};

	const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: '#133466',
			boxShadow: theme.shadows[1],
			maxWidth: 220,
			padding: '8px',
			color: '#FFF',
			fontSize: '12px',
			fontStyle: 'normal',
			fontWeight: '400',
			lineHeight: '130%',
			'& .tooltip-title': {
				backgroundColor: '#133466', // Black background for tooltip title
				padding: '8px',
				borderRadius: '4px',
				color: '#ffffff' // White text color for tooltip title
			},
			[`& .${tooltipClasses.arrow}`]: {
				color: '#133466'
			}
		}
	}));

	useEffect(() => {
		if (sumilationRubrics) {
			// Directly fetch tooltip text without waiting
			const fetchTooltipText = async () => {
				const text =
					(sumilationRubrics[feedback.dimension?.trim()]?.tooltip?.length
						? sumilationRubrics[feedback.dimension?.trim()]?.tooltip
						: sumilationRubrics[feedback.dimension?.trim()]?.description) || '';
				setTooltipText(text);
			};

			fetchTooltipText();
		}
	}, [feedback.dimension, sumilationRubrics]); // Re-fetch if feedback.dimension or sumilationRubrics changes

	// if (!sumilationRubrics) {
	// 	// Optionally, render a loader or return null to wait silently
	// 	return <div>Loading...</div>;
	// }

	return (
		<div className="feedback-card" onMouseLeave={handleMouseLeave}>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginBottom: '0.5rem'
				}}>
				<h2 style={{ marginBottom: 0 }}>{feedback.dimension}</h2>
				<Box>
					<CustomTooltip
						TransitionComponent={Zoom}
						arrow
						title={
							feedback?.dimension?.toLowerCase() === 'perspective'
								? 'If you have a long conversation, you will get comments on how you identify contextual or systemic factors that may be contributing to the situation.'
								: feedback?.dimension?.toLowerCase() === 'argumentation'
									? 'If you have a long conversation, you will get comments on how you present and structure arguments, such as framing, clarity, coherence, and persuasiveness'
									: tooltipText
						}
						placement="right">
						<Button
							component="span"
							onMouseEnter={() => setHoverShow(true)}
							onMouseLeave={() => setHoverShow(false)}
							sx={{ padding: '0px', minWidth: '0px' }}>
							<QuestionSymbol htmlColor={hoverShow ? '#1C75BA' : '#C0CCD5'} />
						</Button>
					</CustomTooltip>
				</Box>
			</Box>
			<div
				className="progress-chart"
				onClick={() => setShowTooltip(true)}
				onMouseEnter={()=> setShowTooltip(true)}
				onMouseLeave={()=> setShowTooltip(false)}
			>
				{/* {feedback.type === Rubrics.NEGATIVE ? (
					<span
						className={`progress-percent ${feedback.score <= 80 ? 'progressfull' : 'progressred'}`}
						style={{ width: `${feedback.score}%`, textAlign: 'right' }}>
						{feedback.score}%
					</span>
				) : (
					<span
						className={`progress-percent ${feedback.score >= 80 ? 'progressfull' : 'progressinfo'}`}
						style={{ width: `${feedback.score}%`, textAlign: 'right' }}>
						{feedback.score}%
					</span>
				)} */}
				<div className="progress">
					{feedback.type === Rubrics.NEGATIVE ? (
						<div
							className={`progress-bar ${feedback.score <= 80? 'bg-success' : 'bg-red'}`}
							role="progressbar"
							style={{ width: `${feedback.score}%` }}></div>
					) : (
						<div
							className={`progress-bar ${feedback.score >= 80 ? 'bg-success' : 'bg-info'}`}
							role="progressbar"
							style={{ width: `${feedback.score}%` }}></div>
					)}
				</div>
			</div>
			{showTooltip && feedback.description && <div className="tooltip">{feedback.description}</div>}
		</div>
	);
};

export default FeedbackCard;
