import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Box, Button } from '@mui/material';
import MessageList from './MessageList';
import { IChat } from '../../../../types';

interface ISelectedConversation {
	conversationId: string;
	messages: IChat[];
	summaryTitle: string;
	description: string;
}

interface ILastMeetingNotesWindowProps {
	open: boolean;
	closeWindow: () => void;
	selectedConversation: ISelectedConversation | null;
	moveToConversation: () => void;
}

const LastMeetingNotesWindow: React.FC<ILastMeetingNotesWindowProps> = ({
	open,
	closeWindow,
	selectedConversation,
	moveToConversation
}) => {
	const [selectedMessageId, setSelectedMessageId] = useState<string | null>(null);
	const [showMessages, setShowMessages] = useState<boolean>(false);

	const handleSelectMessage = (messageId?: string) => {
		if (messageId) {
			if (messageId === selectedMessageId) {
				setSelectedMessageId(null);
			} else {
				setSelectedMessageId(messageId);
			}
		}
	};

	const toggleMessages = () => {
		setShowMessages(prev => !prev);
	};

	return (
		<Dialog open={open} onClose={closeWindow} maxWidth="md" fullWidth aria-labelledby="dialog-title">
			<DialogTitle id="dialog-title" sx={{ display: 'flex', flexDirection: 'column' }}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '42px', mt: '24px' }}>
					<Typography
						sx={{
							fontFamily: 'IBM Plex Sans',
							fontSize: '24px',
							fontWeight: 500,
							lineHeight: '18px'
						}}
					>
						Last Meeting Notes
					</Typography>
					<Button variant="outlined" sx={buttonsStyle} onClick={toggleMessages}>
						{showMessages ? 'Hide messages' : 'Show messages'}
					</Button>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						gap: '15px'
					}}
				>
					<Typography sx={defaultTextStyle}>Your discussion from previous level</Typography>
					<Typography sx={{ maxHeight: showMessages ? '100px' : ' ', overflowY: 'auto' }}>
						<span style={{ fontWeight: '700' }}>Summary:</span> {selectedConversation?.description}
					</Typography>
				</Box>
			</DialogTitle>
			<DialogContent dividers>
				{showMessages && selectedConversation ? (
					<MessageList
						messages={selectedConversation.messages}
						selectedMessageId={selectedMessageId}
						onSelectMessage={handleSelectMessage}
					/>
				) : !showMessages ? (
					<Typography variant="body2">
						Click <span style={{ fontWeight: '700' }}>Show messages </span> button to see the conversation.
					</Typography>
				) : (
					<Typography variant="body2">No conversation selected.</Typography>
				)}
			</DialogContent>
			<DialogTitle>
				<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '26px', margin: '10px 0' }}>
					<Typography sx={defaultTextStyle}>Would you like to continue to the meeting?</Typography>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: '28px' }}>
						<Button variant="outlined" sx={buttonsStyle} onClick={closeWindow}>
							Cancel
						</Button>
						<Button variant="outlined" sx={buttonsStyle} onClick={() => moveToConversation()}>
							Continue To {selectedConversation?.summaryTitle}
						</Button>
					</Box>
				</Box>
			</DialogTitle>
		</Dialog>
	);
};

const defaultTextStyle = {
	fontFamily: 'IBM Plex Sans',
	fontSize: '20px',
	fontWeight: 400,
	lineHeight: '18px'
};

const buttonsStyle = {
	border: '1px solid rgba(28, 117, 186, 1)',
	padding: '16px',
	borderRadius: '8px',
	color: 'rgba(28, 117, 186, 1)',
	fontFamily: 'Outfit',
	fontSize: '18px',
	fontWeight: 400,
	lineHeight: '18px',
	whiteSpace: 'nowrap',
	flexShrink: 0,
	textTransform: 'none'
};

export default LastMeetingNotesWindow;
