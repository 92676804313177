import {
	Box,
	Card,
	LinearProgress,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import { db, fireStorage } from '../../../config/firebase';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';
import { getToken } from '../../../Helper/GetToken';
import { collection, getDocs } from 'firebase/firestore';
import {
	Bar,
	BarChart,
	CartesianGrid,
	Label,
	Legend,
	Rectangle,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from 'recharts';
import { ScenarioList } from './ScenarioList';
import { SimulationList } from './SimulationList';
import { IChat } from '../../../types';
import ChatSimulationMessage from '../../Chat/ChatSimulationMessage';

interface Scenario {
	category: string;
	description: string;
	parent_id: number | null;
	picture: {
		id: number;
		name: string;
		data: string;
	};
	role_name: string | null;
	scenario_id: number;
	scenario_name: string;
}

const ScenarioAnalytics = () => {
	const [folderData, setFolderData] = useState<any[]>([]);
	const [selectedScenario, setSelectedScenario] = useState<any | null>(null);
	const [search, setSearch] = useState('');
	const [total, setTotal] = useState(0);
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const [loading, setLoading] = useState(false);
	const [summaryData, setSummaryData] = useState<any[]>([]);
	const [selectedRange, setSelectedRange] = useState();
	const [selectedSimulation, setSelectedSimulation] = useState<any>();

	const getFolders = async () => {
		setLoading(true);
		await axios
			.get(`${baseUrl}/api/scenario/scenarios`, {
				headers: {
					Authorization: token
				}
			})
			.then(async res => {
				if (res.data) {
					const path = `jsonl-files/${process.env.REACT_APP_SENTRY_ENV || 'local'}/`;
					const storageRef = ref(fireStorage, path);
					try {
						const result = await listAll(storageRef);

						const folderNames = result.prefixes
							.map(folderRef => folderRef.name)
							.filter(folder => !res.data?.scenarios.map((item: Scenario) => item.scenario_name).includes(folder))
							.map(folder => {
								return {
									name: folder,
									id: undefined
								};
							});

						setFolderData(folderNames);
						setTotal(folderNames.length);
						setLoading(false);
					} catch (error) {
						setLoading(false);

						console.error('Error listing folders:', error);
						return [];
					}
				}
			});
	};

	const getFiles = async (folderName: string) => {
		setLoading(true);
		const path = `jsonl-files/${process.env.REACT_APP_SENTRY_ENV || 'local'}/${folderName}/`;
		const storageRef = ref(fireStorage, path);
		try {
			const result = await listAll(storageRef);
			const fileRef = result.items[0];
			const fileUrl = await getDownloadURL(fileRef);
			const response = await fetch(fileUrl);
			const fileContent = await response.json();
			const scenarioId = fileContent?.scenarioId;

			setSelectedScenario({
				...selectedScenario,
				id: scenarioId
			});
			setLoading(false);
		} catch (error) {
			// // due to CORS issue its came in catch
			// // for testing
			// setSelectedScenario({
			// 	...selectedScenario,
			// 	id: 4
			// });
			setLoading(false);
			console.error('Error listing files:', error);
		}
	};

	useEffect(() => {
		if (selectedScenario) {
			if (!selectedScenario?.id) getFiles(selectedScenario?.name);
			setSearch('');
		}
	}, [selectedScenario]);

	const getFilteredConversations = async (scenarioId: number) => {
		try {
			setLoading(true);
			const simulationCollectionRef = collection(db, 'simulation');

			const allSimulationsSnapshot = await getDocs(simulationCollectionRef);
			const allSimulations = allSimulationsSnapshot.docs.map(doc => doc.data());

			const filteredConversations: any = allSimulations.reduce((acc, userSimulations) => {
				userSimulations?.conversations?.forEach((conversation: any) => {
					if (conversation.scenarioId === scenarioId && conversation?.analyticsFeedback) {
						const feedback = conversation?.analyticsFeedback?.analytics;
						const avgScore =
							Object.keys(feedback)?.reduce((sum, item) => sum + (100 * (feedback[item]?.score + 1)) / 4, 0) /
							Object.keys(feedback)?.length;

						acc.push({ ...conversation, avgScore });
					}
				});

				return acc;
			}, []);

			setSummaryData(filteredConversations);
			setLoading(false);
			return filteredConversations;
		} catch (error) {
			setLoading(false);
			console.error('Error retrieving and filtering simulations:', error);
		}
	};

	useEffect(() => {
		if (selectedScenario?.id) {
			getFilteredConversations(selectedScenario?.id);
		}
	}, [selectedScenario]);

	useEffect(() => {
		if (!selectedScenario) {
			getFolders();
		}
	}, [selectedScenario]);

	const inputHandler = (e: { target: { value: string } }) => {
		setSearch(e.target.value);
	};

	const groupedByScore: any = {
		'0-10': [],
		'10-20': [],
		'20-30': [],
		'30-40': [],
		'40-50': [],
		'50-60': [],
		'60-70': [],
		'70-80': [],
		'80-90': [],
		'90-100': []
	};
	const rangeByScore: any = [
		{
			range: '0-10',
			count: 0
		},
		{
			range: '10-20',
			count: 0
		},
		{
			range: '20-30',
			count: 0
		},
		{
			range: '30-40',
			count: 0
		},
		{
			range: '40-50',
			count: 0
		},
		{
			range: '50-60',
			count: 0
		},
		{
			range: '60-70',
			count: 0
		},
		{
			range: '70-80',
			count: 0
		},
		{
			range: '80-90',
			count: 0
		},
		{
			range: '90-100',
			count: 0
		}
	];

	summaryData.forEach(conversation => {
		const score = conversation.avgScore;

		if (score >= 0 && score < 10) {
			groupedByScore['0-10'].push(conversation);
			rangeByScore[0].count++;
		} else if (score >= 10 && score < 20) {
			groupedByScore['10-20'].push(conversation);
			rangeByScore[1].count++;
		} else if (score >= 20 && score < 30) {
			groupedByScore['20-30'].push(conversation);
			rangeByScore[2].count++;
		} else if (score >= 30 && score < 40) {
			groupedByScore['30-40'].push(conversation);
			rangeByScore[3].count++;
		} else if (score >= 40 && score < 50) {
			groupedByScore['40-50'].push(conversation);
			rangeByScore[4].count++;
		} else if (score >= 50 && score < 60) {
			groupedByScore['50-60'].push(conversation);
			rangeByScore[5].count++;
		} else if (score >= 60 && score < 70) {
			groupedByScore['60-70'].push(conversation);
			rangeByScore[6].count++;
		} else if (score >= 70 && score < 80) {
			groupedByScore['70-80'].push(conversation);
			rangeByScore[7].count++;
		} else if (score >= 80 && score < 90) {
			groupedByScore['80-90'].push(conversation);
			rangeByScore[8].count++;
		} else if (score >= 90 && score <= 100) {
			groupedByScore['90-100'].push(conversation);
			rangeByScore[9].count++;
		}
	});

	return (
		<>
			{loading && <LinearProgress />}
			<Box sx={{ padding: '0 10px' }}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0', flexWrap: 'wrap' }}>
					<Typography variant="h6" sx={{ minWidth: '350px' }}>
						Scenario Analytics
					</Typography>
				</Box>

				<>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							margin: '10px 0',
							flexWrap: 'wrap'
						}}>
						<div
							style={{
								color: selectedScenario ? '#0078D4' : 'gray',
								cursor: selectedScenario ? 'pointer' : '',
								display: 'flex',
								alignItems: 'center'
							}}>
							<Typography
								variant="body1"
								onClick={() => {
									setSelectedScenario(null);
									setSummaryData([]);
									setSelectedRange(undefined);
									setSelectedSimulation(undefined);
									setSearch('');
								}}>
								Scenario List
							</Typography>
						</div>

						{selectedScenario && (
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									color: selectedRange ? '#0078D4' : 'gray',
									cursor: selectedRange ? 'pointer' : '',
									marginLeft: '10px',
									gap: '10px'
								}}
								onClick={() => {
									setSelectedRange(undefined);
									setSelectedSimulation(undefined);
									setSearch('');
								}}>
								<Typography variant="body1" color={'gray'}>
									/
								</Typography>
								<Typography variant="body1">Scenario Summary</Typography>
								<Typography variant="body2">({selectedScenario?.name})</Typography>
							</div>
						)}

						{selectedRange && (
							<>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										color: selectedSimulation ? '#0078D4' : 'gray',
										cursor: selectedSimulation ? 'pointer' : '',
										marginLeft: '10px',
										gap: '10px'
									}}
									onClick={() => {
										setSelectedSimulation(undefined);
										setSearch('');
									}}>
									<Typography variant="body1" color={'gray'}>
										/
									</Typography>
									<Typography variant="body1">Simulation List</Typography>
									<Typography variant="body2">({selectedRange})</Typography>
								</div>
							</>
						)}

						{selectedSimulation && (
							<>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										color: 'gray',
										marginLeft: '10px',
										gap: '10px'
									}}>
									<Typography variant="body1" color={'gray'}>
										/
									</Typography>
									<Typography variant="body1">Simulation Details</Typography>
									{selectedSimulation && (
										<Typography variant="body2">({selectedSimulation?.conversationName})</Typography>
									)}
								</div>
							</>
						)}
					</Box>
				</>

				{(!selectedScenario || (selectedRange && !selectedSimulation)) && (
					<Box
						sx={{
							display: 'flex',
							gap: '10px',
							width: '100%',
							minWidth: '300px',
							justifyContent: 'end',
							marginBottom: '10px'
						}}>
						<Box sx={{ width: '580px' }}>
							<TextField
								name="search"
								placeholder={
									!selectedScenario
										? 'Type here to search with scenario name'
										: selectedRange && !selectedSimulation
											? 'Type here to search with conversation name or any specific dimensions'
											: ''
								}
								required
								value={search}
								onChange={inputHandler}
								fullWidth
							/>
						</Box>
					</Box>
				)}

				{selectedSimulation ? (
					<div>
						<div
							style={{ padding: '10px', background: '#BCE0FC' }}
							dangerouslySetInnerHTML={{
								__html:
									Object.keys(selectedSimulation?.analyticsFeedback.analytics)
										?.map(i => {
											if (selectedSimulation?.analyticsFeedback.analytics[i]) {
												return `${i} - <b>${(100 * (selectedSimulation?.analyticsFeedback.analytics[i]?.score + 1)) / 4}</b>`;
											}
										})
										.join('<br/>') +
									'<br/><br/>' +
									`Average Score - <b>${selectedSimulation?.avgScore}</b>`
							}}
						/>
						<Box sx={{ padding: '25px', marginTop: '5px', overflow: 'auto', height: '65vh' }}>
							{selectedSimulation?.messages?.map((chat: IChat, index: number) => (
								<ChatSimulationMessage key={index} message={chat} index={index} />
							))}
						</Box>
					</div>
				) : selectedRange ? (
					<SimulationList
						data={groupedByScore[selectedRange]}
						search={search}
						setSelectedSimulation={setSelectedSimulation}
						total={groupedByScore[selectedRange]?.length}
					/>
				) : selectedScenario ? (
					<>
						<ResponsiveContainer width={'100%'} height={600}>
							<BarChart
								width={500}
								height={300}
								data={rangeByScore}
								margin={{
									top: 5,
									right: 30,
									left: 20,
									bottom: 5
								}}>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="range">
									<Label value="Range" offset={-2} position="insideBottom" />
								</XAxis>
								<YAxis dataKey="count">
									<Label value="Count" angle={-90} position="insideLeft" />
								</YAxis>
								<Tooltip />
								<Bar
									dataKey="count"
									fill="#0078D4"
									activeBar={<Rectangle cursor={'pointer'} fill="#BCE0FC" stroke="blue" />}
									onClick={data => setSelectedRange(data.range)}
								/>
							</BarChart>
						</ResponsiveContainer>
					</>
				) : (
					<ScenarioList
						folderData={folderData}
						search={search}
						setSelectedScenario={setSelectedScenario}
						total={total}
					/>
				)}
			</Box>
		</>
	);
};

export default ScenarioAnalytics;
