/* eslint-disable @typescript-eslint/ban-types */
import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as ShowCommentForm } from '../../Assets/Images/ShowCommentForm.svg';
import sendComment from '../../Assets/Images/sendComment.svg';
import { ChannelE, ChatContext } from '../../Contexts';

import { convertTimestampTo12Hour, getFeedbacksOfChat, getRecommendationsOfChat } from '../../Helper/';

import { IChat, IComment } from '../../types';

// import dots from '../../Assets/Images/doticon.png';
import getUser from '../../Helper/getUser';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import happyIcon from '../../Assets/Images/Emoji/emoji_1-cropped.svg';
import neutralIcon from '../../Assets/Images//Emoji/emoji_9-cropped.svg';
import sadIcon from '../../Assets/Images/Emoji/emoji_2-cropped.svg';
import angryIcon from '../../Assets/Images//Emoji/emoji_3-cropped.svg';
import { getToken, useHandleToken } from '../../Helper/GetToken';
import { getIcon } from '../../utils/svgIcons';
import { SvgIcon } from '@mui/material';
import { useChannel } from '../../hooks/useChannel';
import { Alert, Chip, Snackbar } from '@mui/material';
import { setSimulationValues } from '../../Helper/Redux/Action/userAction';

interface IChatProps {
	message: IChat;
	index: number;
}

const ChatSimulationMessage: React.FC<IChatProps> = ({ message, index }) => {
	const {
		selectedConversationId,
		setFeedbackContent,
		setRecommendations,
		setMessages,
		messages,
		displayTryAgain,
		setDisplayTryAgain,
		chatLoading,
		setChatLoading
	} = useContext(ChatContext);
	const [isVisibleCommentForm, setIsVisibleCommentForm] = useState(false);
	const [commentText, setCommentText] = useState('');
	const user = getUser();
	const token = getToken();
	let tempUserId = user?.id;

	const { sendMessage } = useChannel(ChannelE.simulation);

	useEffect(() => {
		if (messages[messages?.length - 1]?.messageType === 'client' && !chatLoading) {
			setDisplayTryAgain(true);
		} else {
			setDisplayTryAgain(false);
		}
	}, [messages]);

	const [openAlert, setOpenAlert] = React.useState<{
		open: boolean;
		type?: 'success' | 'error' | 'warning' | undefined;
		message?: string;
	}>();

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenAlert({ open: false, message: '', type: openAlert?.type });
	};

	const { roleName } = useSelector((state: any) => state?.role);
	const simulationStep = useSelector((state: any) => state.simulationStep);
	const selectedUserId = useSelector((state: any) => state.selectedUser.userId);
	const conversationId = useSelector((state: any) => state.conversationId.conversationId);
	const simulationValues = useSelector((state: any) => state?.simulation.simulationValues);
	const dispatch = useDispatch();

	const { unhandleToken } = useHandleToken();
	if (selectedUserId) tempUserId = selectedUserId;
	// Handling the message click to display the feedbacks and recommendations of that message

	const handleMsgClick = async () => {
		if (message.messageId && message.messageType === 'client') {
			try {
				if (selectedConversationId) {
					const listFeedbacks = await getFeedbacksOfChat(
						'feedback',
						tempUserId,
						selectedConversationId,
						message.messageId
					);
					const listRecommendations = await getRecommendationsOfChat(
						'recommendation',
						tempUserId,
						selectedConversationId,
						message.messageId
					);
					listRecommendations && setRecommendations(listRecommendations);
					listFeedbacks && setFeedbackContent(listFeedbacks);
				}
			} catch (error) {
				console.log(error);
			}
		}
		// } else if (message.messageId && message.messageType === 'coach') {
		// 	try {
		// 		if (selectedConversationId) {
		// 			const listRecommendations = await getRecommendationsOfChat(
		// 				'recommendation',
		// 				tempUserId,
		// 				selectedConversationId,
		// 				message.messageId
		// 			);
		// 			listRecommendations && setRecommendations(listRecommendations);
		// 		}
		// 	} catch (error) {
		// 		console.log(error);
		// 	}
		// }
	};

	const sendCommentHandler = async () => {
		if (commentText.trim().length > 0 && message && message.messageId) {
			const commentData = {
				userId: selectedUserId,
				conversationId: conversationId,
				coachId: user?.id,
				text: commentText
			};
			await axios
				.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/addComment/${message.messageId}`, commentData, {
					headers: {
						Authorization: token
					}
				})
				.then(res => {
					if (res.data.status === 'success') {
						setCommentText('');
						setMessages((prevMessages: IChat[]) => {
							const updatedMessages = [...prevMessages];
							if (Array.isArray(updatedMessages[index].comments)) {
								updatedMessages[index].comments = [
									...(updatedMessages[index].comments as IComment[]),
									res.data.comment
								];
							} else {
								updatedMessages[index].comments = [res.data.comment];
							}
							return updatedMessages;
						});
					}
				})
				.catch(e => {
					console.log(e);
					unhandleToken(e);
				});
		}
	};

	return (
		<div>
			{openAlert && openAlert.open && openAlert.type === 'error' && (
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					open={openAlert?.open}
					autoHideDuration={5000}
					onClose={handleClose}>
					<Alert
						onClose={handleClose}
						severity={openAlert?.type}
						variant="filled"
						sx={{
							width: '20%',
							position: 'fixed',
							top: 0,
							right: 0,
							margin: '10px',
							zIndex: 9999
						}}>
						{openAlert?.message}
					</Alert>
				</Snackbar>
			)}
			{message.messageType === 'coach' ? (
				<div style={{ display: 'flex' }}>
					{(() => {
						const icon = getIcon(roleName?.picture?.name);

						if (!message || message.totalScore === undefined || !message.isScoreGenerated) {
							return icon ? (
								<SvgIcon
									component={icon}
									viewBox="0 0 384 512"
									transform="translate(-8, -2)"
									sx={{ fontSize: '2rem', width: '1.1em' }}
								/>
							) : null;
						}
						if (index <= 6) {
							return (
								<img
									src={neutralIcon}
									alt="loading"
									style={{
										width: '24px',
										height: '24px',
										marginRight: '5px',
										marginBottom: '5px'
									}}
								/>
							);
						}

						const scoreToIconMap = [
							{ range: [10, 12], icon: happyIcon },
							{ range: [6, 9], icon: neutralIcon },
							{ range: [4, 5], icon: sadIcon },
							{ range: [0, 3], icon: angryIcon }
						];

						const matchedIcon = scoreToIconMap.find(
							({ range }) =>
								message.totalScore !== undefined && message.totalScore >= range[0] && message.totalScore <= range[1]
						);

						if (matchedIcon) {
							return (
								<img
									src={matchedIcon.icon}
									alt="loading"
									style={{
										width: '24px',
										height: '24px',
										marginRight: '5px',
										marginBottom: '5px'
									}}
								/>
							);
						}

						return icon ? (
							<SvgIcon
								component={icon}
								viewBox="0 0 384 512"
								transform="translate(-8, -2)"
								sx={{ fontSize: '2rem', width: '1.1em' }}
							/>
						) : null;
					})()}
					<div>
						{simulationStep?.simulationStep === 2
							? roleName?.role_name || roleName?.scenario_name
							: roleName?.scenario_name}
					</div>
				</div>
			) : (
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<div style={{ marginRight: '5px' }}>You</div>
					{/* Display the user's profile picture or a fallback avatar */}
					<div
						style={{
							width: '24px',
							height: '24px',
							borderRadius: '50%',
							backgroundColor: '#82D1FE',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginBottom: '5px',
							color: '#161616',
							textTransform: 'uppercase',
							fontSize: '14px'
						}}>
						{user?.name ? user.name[0].toUpperCase() : ''}
					</div>
				</div>
			)}

			<div className={message.messageType == 'coach' ? 'receive-box' : 'sender-box'}>
				<div onClick={handleMsgClick} className={`msg ${user?.role === 'coach' ? 'transparentMsg' : ''}`}>
					<span>{message.message}</span>

					{user?.role === 'coach' && message.messageType === 'client' && (
						<div className={`comment-wrapper ${isVisibleCommentForm ? 'active' : ''}`}>
							<ShowCommentForm className="commentIcon" onClick={() => setIsVisibleCommentForm(!isVisibleCommentForm)} />
						</div>
					)}
				</div>
				{message.messageType == 'client' &&
					message.comments &&
					message.comments.length > 0 &&
					message.comments.map(comment => (
						<div key={comment.commentId} className="comment-block">
							<span className="comment">{comment.text}</span>
						</div>
					))}
				{isVisibleCommentForm ? (
					<div className="send-comment">
						<input
							placeholder="Add a comment"
							onChange={event => setCommentText(event.target.value)}
							value={commentText}
							onKeyDown={event => {
								if (event.key === 'Enter') {
									sendCommentHandler();
								}
							}}
						/>
						<img src={sendComment} alt="sendComment" onClick={sendCommentHandler} />
					</div>
				) : (
					<p>{convertTimestampTo12Hour(message.time)}</p>
				)}
				{messages?.length == +index + 1 && displayTryAgain && (
					<div style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px 0' }}>
						Didn&apos;t get the response?
						<Chip
							style={{ marginLeft: '5px' }}
							size="small"
							color="secondary"
							label="Try again"
							variant="outlined"
							onClick={() => {
								setChatLoading(true);
								sendMessage({
									messages: messages,
									conversationId: conversationId,
									userId: tempUserId,
									message: message,
									roleName: roleName,
									simulationValues: simulationValues
								})
									.then((res: any) => {
										if (res) {
											setChatLoading(false);
											if (res.status === 'success' && res.values) {
												dispatch(setSimulationValues(res.values));
											} else {
												setDisplayTryAgain(true);
												setOpenAlert({
													open: true,
													type: res.status,
													message: res.message || 'Something went wrong!'
												});
											}
										}
									})
									.catch(e => {
										console.log('error while sending the message', e);
										setDisplayTryAgain(true);
									});
								setDisplayTryAgain(false);
							}}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default ChatSimulationMessage;
