import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
	Button,
	TextField,
	Box,
	Typography,
	Alert,
	MenuItem,
	Select,
	InputLabel,
	FormControl,
	Autocomplete,
	LinearProgress,
	Snackbar,
	RadioGroup,
	Radio
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getToken, useHandleToken } from '../../../Helper/GetToken';
import axios from 'axios';
import TemplateEditor from '../Cohort/TemplateEditor';
import { ICohort } from '../../../types/cohort';
import getUser from '../../../Helper/getUser';
import CancelIcon from '@mui/icons-material/Cancel';
import DescriptionIcon from '@mui/icons-material/Description';
import { getFileNameFromUrl } from '../../../Helper/encodeURI';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { fireStorage } from '../../../config/firebase';
import Loader from '../../Loader/Loader';

const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
const token = getToken();

const DiscussionForm = ({ id = undefined }: any) => {
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [currentAvailableFrom, setCurrentAvailableFrom] = useState<Moment | null>(moment());
	const [currentUntil, setCurrentUntil] = useState<Moment | null>(moment().add(5, 'day'));
	const [selectedCohort, setSelectedCohort] = useState<string>(''); // New state for selected cohort
	const [error, setError] = useState('');
	const { unhandleToken } = useHandleToken();
	const [cohorts, setCohorts] = useState<ICohort[]>([]);
	const [videos, setVideos] = useState<(File | string)[]>([]);
	const [pictures, setPictures] = useState<(File | string)[]>([]);
	const [loadingForMedia, setLoadingForMedia] = useState(false);
	const [pageLoading, setPageLoading] = useState(false);
	const navigate = useNavigate();
	const [openAlert, setOpenAlert] = React.useState<{
		open: boolean;
		type?: 'error' | 'success' | 'warning' | undefined;
		message?: string;
	}>({
		open: false
	});
	const [selectedOption, setSelectedOption] = useState<string>('all');
	const user = getUser();
	const [groups, setGroups] = useState<any>([]);
	const [selectedGroup, setSelectedGroup] = useState<string>('');

	useEffect(() => {
		if (selectedCohort) fetchSubgroups();
	}, [selectedCohort]);

	const fetchSubgroups = () => {
		axios
			.get(`${baseUrl}/cohort-subgroup/${selectedCohort}/users`, {
				headers: {
					Authorization: token
				}
			})
			.then(response => {
				const data = response?.data;
				setGroups(data);

				if (selectedGroup) {
					setSelectedOption('group');

					const selectedGroupExist = data.find((group: any) => group.id === selectedGroup);
					if (selectedGroupExist) {
						setSelectedGroup(selectedGroup);
					} else {
						setSelectedGroup(data[0]?.id);
					}
				} else {
					setSelectedOption('all');
					if (data?.length) {
						setSelectedGroup(data[0]?.id);
					} else {
						setSelectedGroup('');
					}
				}
			})
			.catch(error => {
				console.error(error);
			});
	};

	const handleAvailableFromChange = (newValue: Moment | null) => {
		setCurrentAvailableFrom(newValue);
		if (currentUntil && newValue && newValue > currentUntil) {
			setError('Until date must be after Available From date.');
		} else {
			setError('');
		}
	};

	const handleVideoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (!files) return;

		const allowedFormats = ['video/mp4', 'video/webm', 'video/ogg', 'video/mov', 'video/avi', 'video/mkv'];
		const newVideos = Array.from(files);

		// Filter valid video files
		const validVideos = newVideos.filter(file => allowedFormats.includes(file.type));

		if (validVideos.length === 0) {
			setOpenAlert({
				open: true,
				type: 'error',
				message: 'Invalid file format'
			});
			e.target.value = ''; // Reset input field
			return;
		}

		setVideos(prevVideos => [...prevVideos, ...validVideos]);
		e.target.value = '';
	};
	const handleVideoDelete = (index: number) => {
		setVideos(prevVideos => prevVideos.filter((_, i) => i !== index));
	};

	const handlePictureChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (!files) return;

		const allowedFormats = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/jpg'];
		const newPictures = Array.from(files);

		// Filter valid image files
		const validPictures = newPictures.filter(file => allowedFormats.includes(file.type));

		if (validPictures.length === 0) {
			setOpenAlert({
				open: true,
				type: 'error',
				message: 'Invalid file format'
			});
			e.target.value = ''; // Reset input field
			return;
		}

		setPictures(prevPictures => [...prevPictures, ...validPictures]);

		// Reset input field after setting the file
		e.target.value = '';
	};
	const handlePictureDelete = (index: number) => {
		setPictures(prevVideos => prevVideos.filter((_, i) => i !== index));
	};

	const handleUntilChange = (newValue: Moment | null) => {
		setCurrentUntil(newValue);
		if (currentAvailableFrom && newValue && newValue < currentAvailableFrom) {
			setError('Until date must be after Available From date.');
		} else {
			setError('');
		}
	};

	useEffect(() => {
		if (id) {
			setPageLoading(true);
			axios
				.get(`${baseUrl}/api/admin/get-discussion/${id}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(res => {
					if (res.data.success) {
						setTitle(res.data.discussion.title);
						setDescription(res.data.discussion.description);
						setCurrentAvailableFrom(moment(res.data.discussion.from));
						setCurrentUntil(moment(res.data.discussion.until));
						setSelectedCohort(res.data.discussion.selectedCohort?.id); // Set initial selected cohort if editing
						if (res.data.discussion.selectedSubGroup?.id) {
							setSelectedGroup(res.data.discussion.selectedSubGroup?.id);
							setSelectedOption('group');
						}
						if (
							res.data.discussion.media &&
							Array.isArray(res.data.discussion.media) &&
							res.data.discussion.media.length > 0
						) {
							const mediaVideos: any[] | ((prevState: (string | File)[]) => (string | File)[]) = [];
							const mediaPictures: any[] | ((prevState: (string | File)[]) => (string | File)[]) = [];
							res.data.discussion.media.forEach((item: { type: string; url: any }) => {
								if (item.type === 'video') {
									mediaVideos.push(item.url);
								} else if (item.type === 'picture') {
									mediaPictures.push(item.url);
								}
							});

							// Update state
							setVideos(mediaVideos);
							setPictures(mediaPictures);
						}
					}
				})
				.catch(e => {
					console.log(e);
					unhandleToken(e);
				});
			setPageLoading(false);
		}
	}, [id]);

	useEffect(() => {
		getAllCohorts();
	}, []);

	const getAllCohorts = async () => {
		const token = getToken();
		try {
			const response = await axios.get(`${baseUrl}/cohortByUser`, {
				headers: {
					Authorization: token
				}
			});
			setCohorts(response.data?.cohorts);
		} catch (e) {
			console.log(e);
		}
	};
	const handleSave = async (isPublished = false) => {
		setPageLoading(true);

		const uploadFiles = async (files: any[], folder: string) => {
			return Promise.all(
				files.map(async file => {
					if (typeof file !== 'string') {
						const fileName = `${Date.now()}-${file?.name}`;
						const storageRef = ref(fireStorage, `${folder}/${fileName}`);
						const snapshot = await uploadBytes(storageRef, file);
						return getDownloadURL(snapshot.ref);
					}
					return file; // If already a URL, return as is
				})
			);
		};

		try {
			setLoadingForMedia(true);

			// Upload media files
			const [uploadedVideos, uploadedPictures] = await Promise.all([
				uploadFiles(videos, 'discussion_videos'),
				uploadFiles(pictures, 'discussion_picture')
			]);

			setLoadingForMedia(false);

			// Prepare data payload
			const dataToSave = {
				title,
				description,
				from: currentAvailableFrom,
				until: currentUntil,
				cohortId: selectedCohort,
				isPublished,
				media: [
					...uploadedVideos.map(url => ({ type: 'video', url })),
					...uploadedPictures.map(url => ({ type: 'picture', url }))
				],
				...(id && { id }) // Include ID for updates if available
			};

			if (id) {
				dataToSave['id'] = id;
			}

			if (selectedOption == 'group') {
				dataToSave['groupId'] = selectedGroup;
			}

			// Save discussion
			const response = await axios.post(`${baseUrl}/api/admin/save-discussion`, dataToSave, {
				headers: { Authorization: `Bearer ${token}` }
			});

			if (response.data.success) {
				navigate('/discussions');
			}
		} catch (error) {
			console.error('Error during save process:', error);
			unhandleToken(error);
			setLoadingForMedia(false);
		} finally {
			setPageLoading(false);
		}
	};

	const handleAlertClose = (event?: SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenAlert({ open: false, message: '', type: openAlert?.type });
	};
	return (
		<Box sx={{ padding: 2 }}>
			{pageLoading ? (
				<Loader />
			) : (
				<Box>
					<Snackbar
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						open={openAlert?.open}
						autoHideDuration={6000}
						onClose={handleAlertClose}
					>
						<Alert onClose={handleAlertClose} severity={openAlert?.type} variant="filled" sx={{ width: '100%' }}>
							{openAlert?.message}
						</Alert>
					</Snackbar>
					{/* Form Header */}
					<Link
						style={{
							color: '#1F72C5',
							textDecoration: 'none'
						}}
						to="/discussions"
					>
						<Typography
							sx={{
								fontSize: '16pt',
								color: '#1F72C5',
								textAlign: 'left'
							}}
						>
							<ArrowBackIcon sx={{ mr: 2 }} />
							Back to discussion dashboard
						</Typography>
					</Link>

					{/* Cohort Selector */}
					<Typography variant="body1" sx={{ fontWeight: 600, marginY: 1, marginTop: 2 }}>
						Discussion is about
					</Typography>
					<FormControl fullWidth sx={{ marginBottom: 2 }}>
						<Autocomplete
							id="cohort-autocomplete"
							options={cohorts}
							getOptionLabel={option => option.name || ''}
							value={cohorts.find((cohort: any) => cohort.id === selectedCohort) || null}
							onChange={(event, value: any) => setSelectedCohort(value ? value.id : '')}
							isOptionEqualToValue={(option, value: any) => option.id === value}
							renderInput={params => (
								<TextField {...params} label="Select Cohort" placeholder="Select Cohort" fullWidth required />
							)}
						/>
					</FormControl>

					{selectedCohort && user.role !== 'client' && (
						<>
							<Typography variant="body1" sx={{ fontWeight: 600 }}>
								Options
							</Typography>
							<RadioGroup value={selectedOption} onChange={e => setSelectedOption(e.target.value)}>
								<div
									style={{
										display: 'flex',
										gap: '8px',
										alignItems: 'center'
									}}
								>
									<Radio value={'all'} />
									<Typography>This discussion is for all users of selected cohort</Typography>
								</div>
								<div
									style={{
										color: groups?.length == 0 ? 'lightgray' : 'black',
										display: 'flex',
										gap: '8px',
										alignItems: 'center'
									}}
								>
									<Radio disabled={groups?.length == 0} value={'group'} />
									<Typography>
										{groups?.length == 0
											? 'This is group Discussion (There is no group in selected cohort)'
											: 'This is group Discussion'}
									</Typography>
								</div>
							</RadioGroup>

							{selectedOption === 'group' && (
								<>
									<FormControl fullWidth sx={{ marginTop: 1 }}>
										<Autocomplete
											id="cohort-autocomplete"
											options={groups}
											getOptionLabel={option => option.name || ''}
											value={groups.find((group: any) => group.id === selectedGroup) || null}
											onChange={(event, value: any) => setSelectedGroup(value ? value.id : '')}
											isOptionEqualToValue={(option, value: any) => option.id === value}
											renderInput={params => (
												<TextField {...params} label="Select Group" placeholder="Select Group" fullWidth required />
											)}
										/>
									</FormControl>
								</>
							)}
						</>
					)}

					{/* Form Fields */}
					<Typography variant="body1" sx={{ fontWeight: 600, marginY: 1 }}>
						Discussion Title
					</Typography>
					<TextField
						fullWidth
						variant="outlined"
						placeholder="Discussion Title"
						value={title}
						onChange={e => setTitle(e.target.value)}
						sx={{ marginBottom: 2 }}
					/>

					<Typography variant="body1" sx={{ fontWeight: 600, marginY: 1, marginTop: 2 }}>
						Description
					</Typography>

					<TemplateEditor style={{ height: '300px' }} value={description} setValue={setDescription} />
					<Box sx={{ marginTop: 8 }}>
						{/* Media Picture */}
						<Box>
							<Typography variant="body1" sx={{ fontWeight: 600, marginY: 1, marginTop: 2 }}>
								Picture
							</Typography>
							{loadingForMedia ? (
								<LinearProgress />
							) : (
								<>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'row',
											gap: '10px',
											flexWrap: 'wrap',
											width: '100%'
										}}
									>
										{pictures.map((picture, index) => (
											<Box key={index} display={'flex'} gap={1}>
												{(typeof picture === 'string' && picture?.includes('discussion_picture')) ||
												(typeof picture !== 'string' && picture?.type?.includes('image')) ? (
													<img
														alt="Uploaded Preview"
														style={{ maxWidth: '300px', maxHeight: '300px', objectFit: 'cover' }}
														src={typeof picture === 'string' ? picture : URL.createObjectURL(picture)}
													/>
												) : (
													<div
														onClick={() => {
															if (typeof picture === 'string') {
																window.open(picture, '_blank');
															}
														}}
														style={{
															display: 'flex',
															alignItems: 'center',
															gap: 1,
															cursor: typeof picture === 'string' ? 'pointer' : 'auto'
														}}
													>
														<DescriptionIcon color="primary" fontSize="large" />
														<p>
															{typeof picture !== 'string'
																? picture?.name && picture?.name
																: getFileNameFromUrl(picture)}
														</p>
													</div>
												)}
												<CancelIcon
													onClick={() => handlePictureDelete(index)}
													sx={{ color: 'red', cursor: 'pointer' }}
												/>
											</Box>
										))}
									</Box>
									<input
										style={{
											margin: '16px 0'
										}}
										accept="image/*"
										id="contained-button-files"
										type="file"
										// value={pictureFile}
										multiple
										aria-label="mediaPicture"
										onChange={handlePictureChange}
									/>
								</>
							)}
						</Box>
						{/* Media Video */}
						<Box>
							<Typography variant="body1" sx={{ fontWeight: 600, marginY: 1, marginTop: 2 }}>
								Video
							</Typography>
							{loadingForMedia ? (
								<LinearProgress />
							) : (
								<>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'row',
											gap: '10px',
											flexWrap: 'wrap',
											width: '100%'
										}}
									>
										{videos.map((video, index) => (
											<Box key={index} display={'flex'} gap={1}>
												{(typeof video === 'string' && video?.includes('discussion_videos')) ||
												(typeof video !== 'string' && video?.type?.includes('video')) ? (
													<video
														controls
														style={{ maxWidth: '300px' }}
														src={typeof video === 'string' ? video : URL.createObjectURL(video)}
													>
														Your browser does not support to load the video.
													</video>
												) : (
													<div
														onClick={() => {
															if (typeof video === 'string') {
																window.open(video, '_blank');
															}
														}}
														style={{
															display: 'flex',
															alignItems: 'center',
															gap: 1,
															cursor: typeof video === 'string' ? 'pointer' : 'auto'
														}}
													>
														<DescriptionIcon color="primary" fontSize="large" />
														<p>{typeof video !== 'string' ? video?.name && video?.name : getFileNameFromUrl(video)}</p>
													</div>
												)}
												<CancelIcon onClick={() => handleVideoDelete(index)} sx={{ color: 'red', cursor: 'pointer' }} />
											</Box>
										))}
									</Box>
									<input
										style={{
											margin: '16px 0'
										}}
										accept="video/*"
										id="contained-button-file"
										type="file"
										// value={videoFile}
										multiple
										aria-label="mediaVideo"
										onChange={handleVideoChange}
									/>
								</>
							)}
						</Box>

						{/* DateTime Pickers */}
						<div style={{ width: '100%' }}>
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<Box sx={{ display: 'flex', width: '100%', gap: 2 }}>
									<div style={{ width: '100%' }}>
										<Typography variant="body1" sx={{ fontWeight: 600, marginY: 1, marginTop: 2 }}>
											Available From
										</Typography>
										<DateTimePicker
											label="Available From"
											value={currentAvailableFrom}
											onChange={handleAvailableFromChange}
											sx={{ marginBottom: 2, marginRight: 2, width: '100%' }}
										/>
									</div>
									<div style={{ width: '100%' }}>
										<Typography variant="body1" sx={{ fontWeight: 600, marginY: 1, marginTop: 2 }}>
											Until
										</Typography>
										<DateTimePicker
											label="Until"
											value={currentUntil}
											onChange={handleUntilChange}
											sx={{ marginBottom: 2, width: '100%' }}
										/>
										{error && (
											<Alert severity="error" sx={{ marginTop: 1 }}>
												{error}
											</Alert>
										)}
									</div>
								</Box>
							</LocalizationProvider>
						</div>

						<Box sx={{ marginTop: 5, marginBottom: 2, border: '1px solid Gray' }}></Box>

						{/* Buttons */}
						<Box sx={{ marginTop: 2, marginBottom: 2 }}>
							<Button
								disabled={
									!title ||
									title?.length === 0 ||
									!description ||
									description?.length === 0 ||
									!selectedCohort ||
									error?.length > 0
								}
								variant="contained"
								onClick={() => handleSave(true)}
							>
								{id ? 'Update and publish' : 'Save and publish'}
							</Button>
							<Button
								disabled={
									!title ||
									title?.length === 0 ||
									!description ||
									description?.length === 0 ||
									!selectedCohort ||
									error?.length > 0
								}
								variant="outlined"
								sx={{ marginLeft: 2 }}
								onClick={() => handleSave(false)}
							>
								{id ? 'Update as draft' : 'Save'}
							</Button>
						</Box>
					</Box>
				</Box>
			)}
		</Box>
	);
};

export default DiscussionForm;
