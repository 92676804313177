import React, { useState, useEffect } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import {
	RadarChart,
	Radar,
	PolarGrid,
	PolarAngleAxis,
	PolarRadiusAxis,
	Tooltip,
	Legend,
	ResponsiveContainer
} from 'recharts';
import { getToken } from '../../../Helper/GetToken';
import { ICohort } from '../../../types/cohort';
import axios from 'axios';

const SpiderGraph = () => {
	const [cohorts, setCohorts] = useState<ICohort[]>([]);
	const [selectedCohort, setSelectedCohort] = useState<string>('');
	const [chartData, setChartData] = useState<any[]>([]);
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const [errorMessage, setErrorMessage] = useState('Select the Cohort');
	useEffect(() => {
		getAllCohorts();
	}, []);
	useEffect(() => {
		if (selectedCohort) {
			setChartData([]);
			fetchdata();
		}
	}, [selectedCohort]);

	const getAllCohorts = async () => {
		try {
			const response = await axios.get(`${baseUrl}/cohortByUser`, {
				headers: {
					Authorization: token
				}
			});
			setCohorts(response.data?.cohorts);
		} catch (e) {
			console.log(e);
		}
	};
	const fetchdata = async () => {
		try {
			setErrorMessage('Fetching the data.');

			// Fetch data from the API
			const res = await axios.get(`${baseUrl}/passedcohort?cohortId=${selectedCohort}`, {
				headers: { Authorization: token }
			});

			const cohorts = res.data || [];

			if (!cohorts.length) {
				setChartData([]);
				setErrorMessage('No data available for the selected cohort.');
				return;
			}

			// Transform data
			// const formattedData = cohorts.reduce(
			// 	(
			// 		acc: {
			// 			dimension: string;
			// 			first: number; // Scaling 0 → 25, 1 → 50, etc.
			// 			best: number;
			// 		}[],
			// 		cohort: { subScenarios: any }
			// 	) => {
			// 		(cohort.subScenarios || []).forEach((subScenario: { level: any; scores: any; subscenarioName: any }) => {
			// 			(subScenario.scores || []).forEach(
			// 				(score: { dimension: any; firstScore: number; highestScore: number }) => {
			// 					acc.push({
			// 						dimension: `Level ${subScenario?.level[0] || 0} - ${score.dimension}`,
			// 						first: (score.firstScore + 1) * 25, // Scaling 0 → 25, 1 → 50, etc.
			// 						best: (score.highestScore + 1) * 25
			// 					});
			// 				}
			// 			);
			// 		});
			// 		return acc;
			// 	},
			// 	[]
			// );
			const formattedData = cohorts.reduce(
				(
					acc: {
						dimension: string;
						first: number; // Scaling 0 → 25, 1 → 50, etc.
						best: number;
					}[],
					cohort: {
						levels: { level: string; scores: { dimension: string; firstScore: number; highestScore: number }[] }[];
					}
				) => {
					(cohort.levels || []).forEach(level => {
						const { level: levelName, scores } = level;

						(scores || []).forEach((score: { dimension: string; firstScore: number; highestScore: number }) => {
							acc.push({
								dimension: `Level ${levelName} - ${score.dimension}`,
								first: (score.firstScore + 1) * 25, // Scaling 0 → 25, 1 → 50, etc.
								best: (score.highestScore + 1) * 25
							});
						});
					});

					return acc;
				},
				[]
			);
			// Update chart data and error message

			setChartData(formattedData);
			if (!formattedData.length) {
				setErrorMessage('No data available for the selected cohort.');
			}
		} catch (error) {
			console.error('Error fetching data:', error);
			setChartData([]);
			setErrorMessage('No data available for the selected cohort.');
		}
	};

	return (
		<>
			<Box
				sx={{
					boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10);',
					borderRadius: '16px',
					border: '1px solid #DDE3ED',
					margin: '10px',
					padding: '10px 15px 10px 15px',
					// width: '100%',
					alignItems: 'center'
				}}>
				<Typography sx={{ paddingLeft: '15px', fontSize: '20px', fontWeight: '700' }}>Progress Overview</Typography>{' '}
				<Box sx={{ marginTop: '20px' }}>
					<FormControl
						fullWidth={false}
						sx={{ mb: 3, width: '200px' }} // Set the desired width
					>
						<InputLabel id="cohort-select-label">Cohort Name</InputLabel>
						<Select
							id="outlined-required"
							labelId="cohort-select-label"
							label="Cohort Name"
							value={selectedCohort}
							onChange={e => setSelectedCohort(e.target.value)}>
							{cohorts.map(cohort => (
								<MenuItem key={cohort.name} value={cohort.id}>
									{cohort.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Box>
				<Box>
					{chartData.length > 0 ? (
						<ResponsiveContainer width="100%" height={500}>
							<RadarChart data={chartData}>
								<PolarGrid gridType="polygon" />
								<PolarAngleAxis dataKey="dimension" tick={{ fontSize: 12 }} />
								<PolarRadiusAxis angle={30} domain={[0, 100]} tickFormatter={value => `${value}%`} />
								<Tooltip formatter={(value: number) => `${value}%`} />

								{/* Lines for scores */}
								<Radar name="First Session" dataKey="first" stroke="#dc2626" fill="#dc26264d" strokeWidth={2} />
								<Radar name="Best Session" dataKey="best" stroke="#2563eb" fill="#2564eb12" strokeWidth={2} />
								<Legend />
							</RadarChart>
						</ResponsiveContainer>
					) : (
						<Typography>{errorMessage}</Typography>
					)}
				</Box>
			</Box>
		</>
	);
};

export default SpiderGraph;
