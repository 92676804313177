import React, { useEffect, useState } from 'react';
import { getToken, useHandleToken } from '../../../Helper/GetToken';
import axios from 'axios';
import { IPostgresUser } from '../../../types/user';
import {
	Alert,
	Autocomplete,
	Box,
	Button,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Modal,
	Select,
	SelectChangeEvent,
	Snackbar,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
	Typography
} from '@mui/material';
import { ICohort } from '../../../types/cohort';
import SettingIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import Subgroups from '../../Admin/Cohort/Subgroups';
interface Role {
	id: string; // or `number` depending on your data
	name: string;
}
const CohortUser = () => {
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const [users, setUsers] = useState<IPostgresUser[]>([]);
	const [search, setSearch] = useState('');
	const [cohorts, setCohorts] = useState<ICohort[]>([]);
	const [role, setRole] = useState('');
	const [cohort, setCohort] = useState('');
	const [email, setEmail] = useState('');
	const [roles, setRoles] = useState([]);
	const [organization, setOrganization] = useState('');
	const [selectedCohort, setSelectedCohort] = useState();
	const [subgroupsOpen, setSubgroupsOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const [openAlert, setOpenAlert] = React.useState<{
		open: boolean;
		type?: 'success' | 'error' | 'info' | 'warning' | undefined;
		message?: string;
	}>();
	const [errors, setErrors] = useState({ email: '', role: '', cohort: '' });
	const token = getToken();
	const { unhandleToken } = useHandleToken();
	useEffect(() => {
		getAllUsers();
		getRoles();
		getAllCohorts();
	}, []);
	const getRoles = async () => {
		await axios
			.get(`${baseUrl}/api/admin/getall/role`, {
				headers: {
					Authorization: token
				}
			})
			.then(res => {
				if (res.data.success) {
					setRoles(res.data.roles);
				}
			})
			.catch(e => {
				console.log(e);
				unhandleToken(e);
			});
	};
	const getAllCohorts = async () => {
		const token = getToken();
		try {
			const response = await axios.get(`${baseUrl}/coachCohort`, {
				headers: {
					Authorization: token
				}
			});

			setCohorts(response.data);
			setLoading(false);
		} catch (e) {
			console.log(e);
			unhandleToken(e);
		}
	};
	const getAllUsers = async () => {
		try {
			const response = await axios.get(`${baseUrl}/getUserByCoach`, {
				headers: {
					Authorization: token
				}
			});
			setUsers(response.data);
		} catch (e) {
			console.log(e);
			unhandleToken(e);
		}
	};
	const inputHandler = (e: { target: { value: string } }) => {
		setSearch(e.target.value);
	};
	const changeUserStatus = async (userId: string, curretStatus: string) => {
		const status = curretStatus === 'active' ? 'inactive' : 'active';
		try {
			const response = await axios.patch(
				`${baseUrl}/api/admin/updateUserAccountStatus/${userId}`,
				{
					status: status
				},
				{
					headers: {
						Authorization: getToken()
					}
				}
			);
			if (response.status === 200) {
				getAllUsers();
			}
		} catch (e) {
			console.log(e);
			unhandleToken(e);
		}
	};

	const subgroupHandler = (data: any) => {
		setSelectedCohort(data);
		setSubgroupsOpen(true);
	};

	const handleNotificationSetting = async ({ id }: any, check: boolean) => {
		const token = getToken();
		setLoading(true);
		try {
			await axios.put(
				`${baseUrl}/api/admin/edit-cohort/${id}`,
				{ notification_settings: check },
				{
					headers: {
						Authorization: token
					}
				}
			);
			await getAllCohorts();
			setLoading(false);
		} catch (e) {
			console.log(e);
			unhandleToken(e);
		}
	};

	const handleRoleChange = (event: SelectChangeEvent) => {
		setRole(event.target.value as string);
	};

	const handleCohortChange = (selectedCohort: string | '') => {
		setCohort(selectedCohort);
	};

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	};

	const handleOrganizationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setOrganization(event.target.value);
	};
	const validateInputs = () => {
		const newErrors = { email: '', role: '', cohort: '' };
		let isValid = true;

		if (!email) {
			newErrors.email = 'Email is required';
			isValid = false;
		} else if (!/\S+@\S+\.\S+/.test(email)) {
			newErrors.email = 'Email is invalid';
			isValid = false;
		}

		if (!role) {
			newErrors.role = 'Role is required';
			isValid = false;
		}

		if (!cohort) {
			newErrors.cohort = 'Cohort is required';
			isValid = false;
		}

		setErrors(newErrors);
		return isValid;
	};

	const handleSubmit = async () => {
		if (validateInputs()) {
			const token = getToken();
			try {
				await axios.post(
					`${baseUrl}/api/admin/inviteUser`,
					{
						userEmail: email,
						roleId: role,
						cohortId: cohort,
						organizationId: organization
					},
					{
						headers: {
							Authorization: token
						}
					}
				);
				setEmail('');
				setRole('');
				setCohort('');
				setOrganization('');
				setOpenAlert({
					open: true,
					type: 'success',
					message: 'User invited successfully!'
				});
			} catch (e: any) {
				console.log(e);
				setOpenAlert({
					open: true,
					type: 'error',
					message: e?.response?.data?.message || 'Something went wrong!'
				});
				unhandleToken(e);
			}
		}
	};
	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenAlert({ open: false, message: '', type: openAlert?.type });
	};
	const deleteHandler = async (userId: string, cohortId: string) => {
		await axios
			.delete(`${baseUrl}/api/admin/removeCohortUser/${userId}`, {
				headers: {
					Authorization: token
				},
				params: {
					cohortId: cohortId
				}
			})
			.then(res => {
				if (res.data.success) {
					getAllUsers();
					getAllCohorts();
				}
			})
			.catch(e => {
				console.log(e);
				unhandleToken(e);
			});
	};

	return (
		<Box sx={{ padding: '0 10px' }}>
			<Box sx={{ margin: '10px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<Typography sx={{ fontWeight: 'semibold', lineHeight: '36px', fontSize: '20px' }}>Cohorts</Typography>
			</Box>
			<Box>
				<TableContainer sx={{ borderRadius: '8px' }}>
					<Table sx={{ minWidth: 650 }}>
						<TableHead
							sx={{
								background: '#CFE9FD',
								border: '1px solid rgba(224, 224, 224, 1)'
							}}>
							<TableRow>
								<TableCell align="left">Cohort Name</TableCell>
								<TableCell align="left">Actions</TableCell>
								<TableCell align="left">Allow User to change notification preference for discussion</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{Array.isArray(cohorts) &&
								cohorts.length > 0 &&
								cohorts.map(item => {
									return (
										<>
											<TableRow sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
												<TableCell align="left">{item?.name}</TableCell>
												<TableCell align="left">
													<Button
														variant="outlined"
														color="secondary"
														size="small"
														startIcon={<SettingIcon />}
														onClick={() => subgroupHandler(item)}>
														Sub groups
													</Button>
												</TableCell>
												<TableCell align="left">
													<Switch
														disabled={loading}
														checked={item?.discussion_notification_settings_enabled}
														onChange={e => {
															handleNotificationSetting(item, e.target.checked);
														}}
													/>
												</TableCell>
											</TableRow>
										</>
									);
								})}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>

			<Box sx={{ margin: '10px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<Typography sx={{ fontWeight: 'semibold', lineHeight: '36px', fontSize: '20px' }}>Users</Typography>
				<TextField
					name="search"
					placeholder="Type here to search"
					required
					value={search}
					onChange={inputHandler}
					fullWidth
					sx={{ width: '180px' }}
				/>
			</Box>
			<Box>
				<TableContainer sx={{ borderRadius: '8px' }}>
					<Table sx={{ minWidth: 650 }}>
						<TableHead
							sx={{
								background: '#CFE9FD',
								border: '1px solid rgba(224, 224, 224, 1)'
							}}>
							<TableRow>
								<TableCell>Email</TableCell>
								<TableCell align="left">First name</TableCell>
								<TableCell align="left">Last name</TableCell>
								<TableCell align="left">Role</TableCell>
								<TableCell align="left">Cohort</TableCell>
								<TableCell align="left">Status</TableCell>
								<TableCell align="left">Remove</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{users
								?.filter(
									items =>
										items.first_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
										items?.last_name?.toLowerCase()?.includes(search?.toLowerCase())
								)
								.map((user: IPostgresUser) => {
									return (
										<TableRow key={user.id} sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
											<TableCell component="th" scope="row">
												{user.email}
											</TableCell>
											<TableCell align="left">{user.first_name}</TableCell>
											<TableCell align="left">{user.last_name}</TableCell>
											<TableCell align="left">{user.role.name}</TableCell>
											<TableCell align="left">
												{Array.isArray(user.cohort) && user.cohort.length > 0
													? user.cohort.map(items => items.name).join(', ')
													: 'N/A'}
											</TableCell>
											<TableCell
												align="left"
												sx={{ color: user.account_status === 'active' ? 'green' : 'red', gap: '5px' }}>
												<Typography>{user.account_status}</Typography>
												<Tooltip
													title={
														user.account_status === 'active'
															? 'Deactivating this user will remove their system access, but they will remain part of their cohort.'
															: 'Activating this user will restore their system access while keeping them in the cohort.'
													}>
													<Button
														variant="contained"
														onClick={() => changeUserStatus(user.firebase_id, user.account_status)}>
														{user.account_status === 'active' ? 'Deactivate' : 'Activate'}
													</Button>
												</Tooltip>
											</TableCell>

											<TableCell align="left">
												<Box sx={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
													{Array.isArray(user.cohort) && user.cohort.length > 0
														? user.cohort.map(items => (
																<Tooltip key={items.id} title={`User will be removed from the ${items?.name} cohort`}>
																	<Button
																		variant="outlined"
																		size="small"
																		startIcon={<DeleteIcon />}
																		onClick={() => deleteHandler(user.firebase_id, items.id)}>
																		{items?.name}
																	</Button>
																</Tooltip>
															))
														: 'N/A'}
												</Box>
											</TableCell>
										</TableRow>
									);
								})}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
			<Box sx={{ padding: 2 }}>
				<Typography
					sx={{
						fontWeight: 'bold',
						lineHeight: '36px',
						fontSize: '20px',
						marginBottom: 2
					}}>
					Invitation
				</Typography>
				<Grid container spacing={2}>
					{/* User Email */}
					<Grid item xs={12} sm={6}>
						<TextField
							fullWidth
							id="outlined-basic"
							label="User mail"
							variant="outlined"
							value={email}
							onChange={handleEmailChange}
							error={!!errors.email}
							helperText={errors.email}
						/>
					</Grid>

					{/* Role */}
					<Grid item xs={12} sm={6}>
						<FormControl fullWidth error={!!errors.role}>
							<InputLabel id="user-role-label">Role</InputLabel>
							<Select labelId="user-role-label" id="user-role" value={role} label="Role" onChange={handleRoleChange}>
								{roles
									?.filter((item: { name: string }) => item.name?.toLowerCase() === 'client')
									.map((role: Role) => (
										<MenuItem key={role?.id} value={role?.id}>
											{role?.name}
										</MenuItem>
									))}
							</Select>
							{errors.role && <Typography color="error">{errors.role}</Typography>}
						</FormControl>
					</Grid>

					{/* Cohort */}
					<Grid item xs={12} sm={6}>
						<FormControl fullWidth error={!!errors.cohort}>
							<Autocomplete
								id="cohort-autocomplete"
								options={cohorts || []}
								getOptionLabel={option => option.name || ''}
								value={cohorts.find((item: any) => item.id === cohort) || null}
								onChange={(event, value: any) => handleCohortChange(value ? value.id : '')}
								isOptionEqualToValue={(option, value) => option.id === value?.id}
								renderInput={params => (
									<TextField {...params} label="Cohort" placeholder="Select Cohort" fullWidth required />
								)}
							/>

							{errors.cohort && <Typography color="error">{errors.cohort}</Typography>}
						</FormControl>
					</Grid>

					{/* Organization (Optional) */}
					<Grid item xs={12} sm={6}>
						<TextField
							fullWidth
							id="outlined-basic"
							label="Organization (optional)"
							variant="outlined"
							value={organization}
							onChange={handleOrganizationChange}
						/>
					</Grid>
				</Grid>

				{/* Send Button */}
				<Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
					<Button variant="contained" onClick={handleSubmit}>
						Send
					</Button>
				</Box>
			</Box>

			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={openAlert?.open}
				autoHideDuration={6000}
				onClose={handleClose}>
				<Alert onClose={handleClose} severity={openAlert?.type} variant="filled" sx={{ width: '100%' }}>
					{openAlert?.message}
				</Alert>
			</Snackbar>

			<Modal open={subgroupsOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Subgroups setSubgroupsOpen={setSubgroupsOpen} selectedCohort={selectedCohort} />
			</Modal>
		</Box>
	);
};

export default CohortUser;
