import React, { useState } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ChatContext } from '../../../Contexts';
import axios from 'axios';
import { getToken } from '../../../Helper/GetToken';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentTimeStamp } from '../../../Helper';
import { clearPreviousConversations, setSimulationStep } from '../../../Helper/Redux/Action/userAction';
import SelectStartingPointWindow from './ModalWindows/SelectStartingPointWindow';
import { IChat } from '../../../types';
import { ReactComponent as Dots } from '../../../Assets/Images/Dots.svg';
import LastMeetingNotesWindow from './ModalWindows/LastMeetingNotesWindow';

interface ISelectedConversation {
	description: string;
	conversationId: string;
	messages: IChat[];
	summaryTitle: string;
}

const PreviousConversations = () => {
	const {
		setChatLoading,
		setLastMeetingNotesWindowAvailable,
		islastMeetingNotesWindowVisible,
		setIsLastMeetingNotesWindowVisible
	} = React.useContext(ChatContext);

	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const { simulationId } = useSelector((state: any) => state?.selectedCompletedSimulation);
	const { roleName } = useSelector((state: any) => state?.role);
	const { previousConversations } = useSelector((state: any) => state.previousConversations);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState<boolean>(false);
	const [selectedConversation, setSelectedConversation] = useState<ISelectedConversation | null>(null);
	const [isStaringPointWindowVisible, setIsStaringPointWindowVisible] = useState<boolean>(false);
	const hideStaringPointWindow = () => {
		setIsStaringPointWindowVisible(false);
		setLastMeetingNotesWindowAvailable(true);
	};

	const hideLastMeetingNotesWindow = () => {
		setIsLastMeetingNotesWindowVisible(false);
	};

	const firstInteractionFromCoach = async () => {
		setChatLoading(true);

		const res = await axios.post(
			`${baseUrl}/msg-from-coach`,
			{
				simulationId,
				meetingTopic: roleName?.description
			},
			{
				headers: {
					Authorization: token
				}
			}
		);

		const messageFromCoach = {
			messageType: 'coach',
			message: res?.data?.response,
			time: getCurrentTimeStamp()
		};

		localStorage?.setItem('summaryForPreparation', JSON.stringify(messageFromCoach));
		setChatLoading(false);
	};

	const moveToConversation = async () => {
		try {
			hideLastMeetingNotesWindow();
			dispatch(setSimulationStep(2));
		} catch (error) {
			console.error('Error creating new conversation', error);
			setLoading(false);
		}
	};

	const selectStartingPointHandler = (summary: ISelectedConversation) => {
		setIsStaringPointWindowVisible(true);
		setSelectedConversation(summary);
	};

	const closeWindowHandler = () => {
		dispatch(clearPreviousConversations());
		firstInteractionFromCoach();
	};

	return (
		<>
			<SelectStartingPointWindow
				open={isStaringPointWindowVisible}
				closeWindow={hideStaringPointWindow}
				selectedConversation={selectedConversation}
			/>
			<LastMeetingNotesWindow
				open={islastMeetingNotesWindowVisible}
				closeWindow={hideLastMeetingNotesWindow}
				selectedConversation={selectedConversation}
				moveToConversation={moveToConversation}
			/>
			<Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: '8px',
						maxWidth: '80%',
						marginTop: '30px'
					}}
				>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							border: '1px solid rgba(217, 226, 233, 1)',
							borderRadius: '16px',
							padding: '16px'
						}}
					>
						<Typography
							sx={{
								color: 'rgba(19, 52, 102, 1)',
								margin: '0',
								fontFamily: 'Outfit',
								fontSize: '16px',
								fontWeight: 400,
								lineHeight: '22.4px'
							}}
							gutterBottom
						>
							Hey! Which story from previous level do you want to continue?
						</Typography>

						<CloseIcon sx={{ color: 'rgba(28, 117, 186, 1)', cursor: 'pointer' }} onClick={closeWindowHandler} />
					</Box>
					<Box
						sx={{
							border: '1px solid rgba(217, 226, 233, 1)',
							borderRadius: '16px',
							padding: '4px 8px',
							display: 'flex',
							gap: 2,
							overflowX: 'auto',
							scrollbarWidth: 'none',
							'&::-webkit-scrollbar': {
								display: 'none'
							}
						}}
					>
						{previousConversations.map((summary: ISelectedConversation, index: React.Key | null | undefined) => (
							<Button
								key={index}
								variant="outlined"
								sx={{
									border: '1px solid rgba(28, 117, 186, 1)',
									borderRadius: '8px',
									color: 'rgba(28, 117, 186, 1)',
									fontFamily: 'Outfit',
									fontSize: '14px',
									fontWeight: 400,
									lineHeight: '17.64px',
									whiteSpace: 'nowrap',
									flexShrink: 0,
									textTransform: 'none',
									backgroundColor:
										summary.conversationId === selectedConversation?.conversationId ? 'rgba(188, 224, 252, 1)' : ''
								}}
								onClick={() => selectStartingPointHandler(summary)}
							>
								{summary.summaryTitle ? summary.summaryTitle : 'Previous conversation title'}
								{loading && summary.conversationId === selectedConversation?.conversationId ? (
									<CircularProgress sx={{ marginLeft: '8px' }} size={'14px'} />
								) : (
									''
								)}
							</Button>
						))}
					</Box>
				</Box>
				{selectedConversation && (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'flex-start',
							border: '1px solid rgba(217, 226, 233, 1)',
							borderRadius: '16px',
							padding: '16px',
							maxWidth: '80%'
						}}
					>
						<Typography
							sx={{
								color: 'rgba(19, 52, 102, 1)',
								margin: '0',
								fontFamily: 'Outfit',
								fontSize: '16px',
								fontWeight: 400,
								lineHeight: '22.4px'
							}}
							gutterBottom
						>
							Summary of previous meeting: <br /> {selectedConversation?.description}
						</Typography>
						<Box>
							<Dots className="commentIcon" color="rgba(19, 52, 102, 1)" />
						</Box>
					</Box>
				)}
			</Box>
		</>
	);
};

export default PreviousConversations;
