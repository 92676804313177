import React, { useEffect, useState } from 'react';
import './Progress.css';
import { Box, Typography } from '@mui/material';
import AreaGraph, { timeCode } from './Graph/AreaGraph';
import Tab, { tabClasses } from '@mui/material/Tab';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';
import SessionCard from './SessionCard/SessionCard';
import axios from 'axios';
import { getToken, useHandleToken } from '../../Helper/GetToken';
import { useDispatch, useSelector } from 'react-redux';
import { clearBadgeSimulation } from '../../Helper/Redux/Action/userAction';
import SpiderGraph from './Graph/SpiderGraph';
import getUser from '../../Helper/getUser';
import MeetingCardForProgress from './MeetingCardForProgress/MeetingCardForProgress';
import AreaGraphForMeeting from './Graph/AreaGraphForMeeting';

// import TabPanel from '@mui/lab/TabPanel';
const TabItem = styled(Tab)(({ theme }) => ({
	position: 'relative',
	borderRadius: '8px',
	textAlign: 'center',
	transition: 'all .5s',
	padding: '10px 15px',
	color: '#000',
	height: 'auto',
	margin: '10px 0',
	float: 'none',
	fontSize: '12px',
	fontWeight: '700',
	[theme.breakpoints.up('md')]: {
		minWidth: 120
	},
	[`&.${tabClasses.selected}, &:hover`]: {
		color: '#000000',
		borderColor: '#1C75BA',
		backgroundColor: '#E0F1FA',
		boxShadow: '0 7px 10px -5px rgba(76, 175, 80, 0.4)'
	}
}));
interface Scenario {
	category: string;
	description: string;
	parent_id: number | null;
	picture: {
		id: number;
		name: string;
		data: string;
	};
	role_name: string | null;
	scenario_id: number;
	scenario_name: string;
}
const Progress = () => {
	const [graphCode, setGraphCode] = useState(1);
	const [conversationCategoryCode, setConversationCategory] = useState(
		useSelector((state: any) => state.badgeSimulation.badgeId) || 0
	);

	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const [categoryConv, setCategoryConv] = useState<Scenario[]>([]);
	const dispatch = useDispatch();
	const { unhandleToken } = useHandleToken();
	const user = getUser();
	const selectedUserId = useSelector((state: any) => state.selectedUser.userId);
	const [selectedTab, setSelectedTab] = useState('simulation');

	useEffect(() => {
		getScenarios();
	}, []);

	const getScenarios = async () => {
		let userId;
		selectedUserId ? (userId = selectedUserId) : (userId = user?.id);

		await axios
			.get(`${baseUrl}/api/scenario/cohortcatgeory`, {
				headers: {
					Authorization: token
				},
				params: { userId }
			})
			.then(res => {
				if (res.data) {
					setCategoryConv(res.data);
				}
			})
			.catch(e => {
				console.log(e);
				unhandleToken(e);
			});
	};

	return (
		<Box>
			<Box sx={{ margin: '10px' }}>
				<Typography sx={{ paddingLeft: '15px', fontSize: '28px', fontWeight: '900' }}>Progress</Typography>
				<Tabs
					value={selectedTab}
					onChange={(e, newValue) => {
						setSelectedTab(newValue);
					}}
					sx={{
						[`& .${tabsClasses.indicator}`]: {
							display: 'none'
						}
					}}
				>
					<TabItem
						sx={{
							ml: '8px',
							height: '32px',
							border: '1px solid var(--Stroke, #DDE3ED)',
							textTransform: 'capitalize',
							color: '#000',
							fontSize: '18px',
							fontWeight: '500',
							minHeight: '45px',
							padding: '5px 14px',
							minWidth: 'auto !important'
						}}
						disableRipple
						label={'Simulation Progress'}
						value={'simulation'}
					/>
					<TabItem
						sx={{
							ml: '8px',
							height: '32px',
							border: '1px solid var(--Stroke, #DDE3ED)',
							textTransform: 'capitalize',
							color: '#000',
							fontSize: '18px',
							fontWeight: '500',
							minHeight: '45px',
							padding: '5px 14px',
							minWidth: 'auto !important'
						}}
						disableRipple
						label={'Meeting Progress'}
						value={'meeting'}
					/>
				</Tabs>
				{/* <Box sx={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
					<select
						name="parentID"
						id="parentID"
						title="Select Parent"
						value={parentId}
						className="progressTab-select"
						onChange={e => {
							setParentId(e.target.value);
							setConversationCategory(e.target.value);
							setMembers([]);
							setSubSenarioId('');
						}}>
						<option value="">Select Scenario</option>
						{categoryConv.map((items: any, index: number) => {
							return (
								<option key={index} value={items.scenario_id}>
									{items.scenario_name}
								</option>
							);
						})}
					</select>
					<select
						name="subScenarioId"
						id="subScenarioId"
						title="Select subScenario"
						value={subSenarioId}
						className="progressTab-select"
						onChange={e => {
							setSubSenarioId(e.target.value);
							setConversationCategory(e.target.value);
						}}>
						<option value="">Select Sub Scenario</option>
						{members.map((items: any, index: number) => {
							return (
								<option key={index} value={items.scenario_id}>
									{items.scenario_name}
								</option>
							);
						})}
					</select>
				</Box> */}
				<Box>
					<Tabs
						value={conversationCategoryCode}
						variant="scrollable"
						scrollButtons="auto"
						allowScrollButtonsMobile
						onChange={(e, newValue) => {
							setConversationCategory(newValue);
							dispatch(clearBadgeSimulation());
						}}
						sx={{
							[`& .${tabsClasses.indicator}`]: {
								display: 'none'
							}
						}}
					>
						{categoryConv.map((item, index) => (
							<TabItem
								sx={{
									ml: '8px',
									height: '32px',
									border: '1px solid var(--Stroke, #DDE3ED)',
									textTransform: 'capitalize',
									color: '#000',
									fontSize: '12px',
									fontWeight: '500',
									minHeight: '35px',
									padding: '5px 14px',
									minWidth: 'auto !important'
								}}
								key={index}
								disableRipple
								label={item.scenario_name}
								value={item.scenario_id}
							/>
						))}
						{/* <TabItem disableRipple label={"All"} value={1} />
            <TabItem disableRipple label={"Month"} value={3} /> */}

						{/* <TabItem disableRipple label={"Indexes"} />
            <TabItem disableRipple label={"Usage"} /> */}
					</Tabs>
				</Box>
			</Box>
			<Box
				sx={{
					boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10);',
					borderRadius: '16px',
					border: '1px solid #DDE3ED',
					margin: '10px'
				}}
			>
				<Box
					sx={{
						display: 'flex',
						padding: '10px 15px 10px 15px',
						justifyContent: 'space-between',
						// width: '100%',
						alignItems: 'center'
					}}
				>
					<Typography sx={{ paddingLeft: '15px', fontSize: '20px', fontWeight: '700' }}>Progress Chart</Typography>

					<Tabs
						value={graphCode}
						onChange={(e, newValue) => {
							setGraphCode(newValue);
						}}
						sx={{
							[`& .${tabsClasses.indicator}`]: {
								display: 'none'
							}
						}}
					>
						{timeCode.map((item, index) => (
							<TabItem
								sx={{
									border: '1px solid var(--Stroke, #DDE3ED)',
									ml: '8px',
									padding: '5px 4px',
									minHeight: '35px',
									textTransform: 'capitalize',
									color: '#000',
									fontSize: '12px',
									fontWeight: '500',
									minWidth: '70px !important'
								}}
								key={index}
								disableRipple
								label={item.name}
								value={item.code}
							/>
						))}
						{/* <TabItem disableRipple label={"All"} value={1} />
            <TabItem disableRipple label={"Month"} value={3} /> */}

						{/* <TabItem disableRipple label={"Indexes"} />
            <TabItem disableRipple label={"Usage"} /> */}
					</Tabs>
				</Box>
				<Box
					sx={{
						paddingBottom: '25px'
					}}
				>
					{selectedTab === 'meeting' ? (
						<AreaGraphForMeeting code={graphCode} conversationCategoryCode={conversationCategoryCode} />
					) : (
						<AreaGraph code={graphCode} conversationCategoryCode={conversationCategoryCode} />
					)}
				</Box>
			</Box>
			{user?.role === 'client' && selectedTab === 'simulation' ? <SpiderGraph /> : ''}
			<Box>
				{selectedTab === 'meeting' ? (
					<MeetingCardForProgress conversationCategoryCode={conversationCategoryCode} />
				) : (
					<SessionCard conversationCategoryCode={conversationCategoryCode} />
				)}
			</Box>
		</Box>
	);
};

export default Progress;
