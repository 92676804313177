import React, { useState } from 'react';
import { Modal, Box, IconButton, Typography, TextField, Divider, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface Rubric {
	id: string;
	title: string;
	description: string;
	score: Record<string, string>;
}

interface RubricsWindowProps {
	open: boolean;
	closeWindow: () => void;
	data: any;
	onSave: (updatedRubrics: any) => void;
}

const RubricsWindow: React.FC<RubricsWindowProps> = ({ open, closeWindow, data, onSave }) => {
	const initialRubrics: Rubric[] = data
		? Object.entries(JSON.parse(data).rubrics).map(([key, rubric]: any) => ({
				id: key,
				title: key,
				description: rubric.description,
				score: rubric.score
			}))
		: [];

	const [rubrics, setRubrics] = useState<Rubric[]>(initialRubrics);

	const handleRubricTitleChange = (id: string, newTitle: string) => {
		setRubrics(prev => prev.map(rubric => (rubric.id === id ? { ...rubric, title: newTitle } : rubric)));
	};

	const handleRubricDescriptionChange = (id: string, newDescription: string) => {
		setRubrics(prev => prev.map(rubric => (rubric.id === id ? { ...rubric, description: newDescription } : rubric)));
	};

	const handleScoreChange = (id: string, scoreKey: string, newValue: string) => {
		setRubrics(prev =>
			prev.map(rubric => (rubric.id === id ? { ...rubric, score: { ...rubric.score, [scoreKey]: newValue } } : rubric))
		);
	};

	const isSaveDisabled = rubrics.some(
		rubric =>
			!rubric.title.trim() || !rubric.description.trim() || Object.values(rubric.score).some(value => !value.trim())
	);

	const handleSave = () => {
		const updatedRubrics = rubrics.reduce(
			(acc, rubric) => {
				acc[String(rubric.title).trim()] = {
					description: rubric.description,
					score: rubric.score
				};
				return acc;
			},
			{} as Record<string, any>
		);

		onSave(updatedRubrics);
		closeWindow();
	};

	return (
		<Modal open={open} onClose={closeWindow}>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: '60%',
					maxHeight: '80%',
					overflowY: 'auto',
					bgcolor: 'background.paper',
					border: '2px solid #000',
					boxShadow: 24,
					p: 4
				}}>
				<Typography variant="h6" gutterBottom>
					Rubrics
				</Typography>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<IconButton onClick={closeWindow} sx={{ position: 'absolute', top: 10, right: 10 }}>
						<CloseIcon />
					</IconButton>
				</Box>

				{rubrics.map((rubric, index) => (
					<Box key={rubric.id} sx={{ mb: 3 }}>
						<Box sx={{ p: 2, border: '1px solid #ddd', borderRadius: 2 }}>
							<TextField
								fullWidth
								label="Rubric Title"
								variant="outlined"
								value={rubric.title}
								onChange={e => handleRubricTitleChange(rubric.id, e.target.value)}
								error={!rubric.title.trim()}
								helperText={!rubric.title.trim() ? 'Field must be filled' : ''}
								sx={{ mb: 2 }}
								required
							/>

							<TextField
								fullWidth
								label="Rubric Description"
								variant="outlined"
								multiline
								value={rubric.description}
								onChange={e => handleRubricDescriptionChange(rubric.id, e.target.value)}
								error={!rubric.description.trim()}
								helperText={!rubric.description.trim() ? 'Field must be filled' : ''}
								sx={{ mb: 2 }}
								required
							/>

							<Typography variant="subtitle2" fontWeight="bold" sx={{ mb: 1 }}>
								Scores:
							</Typography>

							{Object.entries(rubric.score)
								.filter(([scoreKey]) => !isNaN(Number(scoreKey)))
								.map(([scoreKey, scoreValue]) => (
									<TextField
										key={scoreKey}
										fullWidth
										label={`Score ${scoreKey}`}
										variant="outlined"
										multiline
										value={scoreValue as string}
										onChange={e => handleScoreChange(rubric.id, scoreKey, e.target.value)}
										error={!scoreValue.trim()}
										helperText={!scoreValue.trim() ? 'Field must be filled' : ''}
										sx={{ mb: 1 }}
										required
									/>
								))}
						</Box>

						{index < rubrics.length - 1 && <Divider sx={{ my: 3, bgcolor: '#1976D2', height: 2 }} />}
					</Box>
				))}

				<Button
					variant="contained"
					color="primary"
					fullWidth
					onClick={handleSave}
					sx={{ mt: 3 }}
					disabled={isSaveDisabled}>
					Save Changes
				</Button>
			</Box>
		</Modal>
	);
};

export default RubricsWindow;
