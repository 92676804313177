import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
	Box,
	Button,
	SvgIcon,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { getToken, useHandleToken } from '../../../Helper/GetToken';
import { getIcon } from '../../../utils/svgIcons';
interface MemberProps {
	parent_id: number;
	setShowMember: (showMember: boolean) => void;
}
export const AssignedSubCategory: React.FC<MemberProps> = ({ parent_id, setShowMember }) => {
	const [tableData, setTableData] = useState([]);
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const { unhandleToken } = useHandleToken();
	useEffect(() => {
		getScenarios();
	}, []);

	const getScenarios = async () => {
		await axios
			.get(`${baseUrl}/api/scenario/scenarios`, {
				headers: {
					Authorization: token
				},
				params: { id: parent_id }
			})
			.then(res => {
				if (res.data) {
					setTableData(res.data?.scenarios);
				}
			})
			.catch(e => {
				console.log(e);
				unhandleToken(e);
			});
	};
	return (
		<>
			<Box sx={{ padding: '0 10px' }}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
					<Button startIcon={<ArrowBackIosIcon />} onClick={() => setShowMember(false)} color="secondary">
						Back
					</Button>
					<Box>
						<Typography variant="h6">Scenario Sub Category</Typography>
					</Box>
					<Box></Box>
				</Box>
				<Box>
					<TableContainer sx={{ borderRadius: '8px' }}>
						<Table>
							<TableHead
								sx={{
									background: '#CFE9FD',
									border: '1px solid rgba(224, 224, 224, 1)'
								}}
							>
								<TableRow sx={{ textWrap: 'nowrap' }}>
									<TableCell align="left">Scenario Id</TableCell>
									<TableCell align="left">Scenario Name</TableCell>
									<TableCell align="left">Category</TableCell>
									<TableCell align="left">Description</TableCell>
									<TableCell align="left">Role</TableCell>
									<TableCell align="left">Profile</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{Array.isArray(tableData) &&
									tableData.length > 0 &&
									tableData.map((items: any, index: number) => {
										const icon = getIcon(items?.picture?.name);
										return (
											<>
												<TableRow sx={{ border: '1px solid rgba(224, 224, 224, 1)' }} key={index}>
													<TableCell align="left">{items.scenario_id}</TableCell>
													<TableCell align="left">{items.scenario_name}</TableCell>
													<TableCell align="left">{items.category}</TableCell>
													<TableCell align="left">{items.description}</TableCell>
													<TableCell align="left">{items?.role_name || '-'}</TableCell>
													<TableCell align="left">
														{icon ? (
															<SvgIcon
																component={icon}
																viewBox="0 0 384 512"
																transform="translate(-8, -2)"
																sx={{ fontSize: '2rem', width: '1.1em' }}
															/>
														) : null}
													</TableCell>
												</TableRow>
											</>
										);
									})}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Box>
		</>
	);
};
