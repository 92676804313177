import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
	clearAuthStatus,
	clearConversationId,
	clearSelectedCompletedSimulationMessages,
	clearSelectedUser,
	clearSimulationStep,
	clearUserDetails,
	setRole,
	setRoleCategory
} from './Redux/Action/userAction';
import { getAuth, signInWithCustomToken } from 'firebase/auth';

async function authenticateWithCustomToken(customToken: string) {
	const auth = getAuth();
	await signInWithCustomToken(auth, customToken);
}

const Redirect = () => {
	const { token } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	const tokenHandler = async () => {
		const params = new URLSearchParams(location.search);
		const customToken = params.get('customToken');
		const roleParam = params.get('role');

		if (customToken) {
			try {
				await authenticateWithCustomToken(customToken);
				localStorage.setItem('active', JSON.stringify(token));
			} catch (error) {
				console.error('Error during authentication:', error);
			}
		}

		if (token) {
			if (roleParam === 'coach') {
				navigate('/Dashboard');
			} else if (roleParam === 'client') {
				navigate('/simulations');
			} else if (roleParam === 'admin') {
				navigate('/cohort-user');
			} else if (roleParam === 'editor') {
				navigate('/scenario');
			}
			window.location.reload();
		} else {
			localStorage.removeItem('active');
			navigate('/');
		}
	};

	useEffect(() => {
		dispatch(clearUserDetails());
		dispatch(clearSelectedUser());
		dispatch(clearConversationId());
		dispatch(clearSimulationStep());
		dispatch(setRole(null));
		dispatch(setRoleCategory(null));
		dispatch(clearSelectedCompletedSimulationMessages());
		dispatch(clearAuthStatus());
		setTimeout(() => {
			tokenHandler();
		}, 100);
	}, [token]);

	return <></>;
};

export default Redirect;
